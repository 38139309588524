import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { isMobileOrTablet } from '@umai/common';

function AppHelmet({ title = 'Loyalty', description = '' }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="viewport"
        content={`width=device-width${
          isMobileOrTablet ? ', initial-scale=1.0, maximum-scale=1.0, user-scalable=no' : ''
        }`}
      />
    </Helmet>
  );
}

AppHelmet.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default AppHelmet;
