import { UnauthorizedError } from 'util/errors';
import { deductEndpoints } from './endpoints';
import request from './request';
import { getDefaultAuthHeaders } from './api-util';

export const deductAmount = async ({
  token,
  currentVenueId,
  loyaltyAccountId,
  amount,
  customerId,
  idempotencyKey,
} = {}) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');

  try {
    const loyaltyTransaction = {
      amount: Number(amount),
      transaction_type: 'deduction',
      note: '',
    };
    if (loyaltyAccountId) loyaltyTransaction.loyalty_account_id = loyaltyAccountId;

    const url = deductEndpoints.deductAmountPOST(currentVenueId);
    const defaultAuthHeaders = getDefaultAuthHeaders(token);
    const response = await request(url, {
      method: 'POST',
      headers: {
        ...defaultAuthHeaders,
        'Idempotency-Key': idempotencyKey,
      },
      body: JSON.stringify({
        loyalty_transaction: loyaltyTransaction,
        guest_id: customerId,
      }),
    });

    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (resData.error && resData.error === 'Authorization token is invalid') {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    } else if (e?.response?.status === 404) return {};
    throw e;
  }
};
