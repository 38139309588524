import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { STEPS as REDEEM_STEPS } from 'pages/CapturePage/components/Redeem/Redeem.useWrapper';
import { STEPS as CLAIM_STEPS } from 'pages/CapturePage/components/Claim/Claim.useWrapper';
import { STEPS as DEDUCT_STEPS } from 'pages/CapturePage/components/Deduct/Deduct.useWrapper';
import { STEPS as ADD_STEPS } from 'pages/CapturePage/components/Add/Add.useWrapper';
import Popover from 'components/Popover';
import { HorizontalDots } from 'components/Icons';
import Amount from 'components/Amount';
import { formatNumber } from 'util/number-helper';
import { assignEventListener } from 'util/event-helper';
import { getDateFormatter } from 'util/date-helper';
import FIELD_OPTIONS from 'constants/data-field-options';
import DATA_CAPTURE_FLOWS from 'constants/data-capture-flows';

const CustomerDetails = ({
  Styles,
  customer: {
    firstName,
    lastName,
    phone,
    balance,
    gender,
    email,
    birthday,
    currentTier,
    memberSince,
    renewalDate,
  } = {},
  dataCaptureFlow,
  secondaryFieldsToDisplay = {},
  gotoEditDetailsHandler = null,
  redeemCampaignsRedirectHandler = null,
  locale,
  amountLabel,
  popOverLinks = [],
}) => {
  const [t] = useTranslation();
  const formatDateType = getDateFormatter('ll');

  const renderData = (data) => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!data) return data;
    return <span css={Styles.placeholderData}>{t('none')}</span>;
  };

  const renderLastName = () => {
    if (dataCaptureFlow !== DATA_CAPTURE_FLOWS.OFF) {
      const lastNameField = secondaryFieldsToDisplay.lastName;
      if (lastNameField && lastNameField !== FIELD_OPTIONS.DISABLED) {
        return (
          <div css={Styles.entryWrapper}>
            <div css={Styles.entryLabel}>{t('last-name')}</div>
            <div css={Styles.entryContent}>{renderData(lastName)}</div>
          </div>
        );
      }
    }
    return <span />;
  };

  const renderEmail = () => {
    if (dataCaptureFlow !== DATA_CAPTURE_FLOWS.OFF) {
      const emailField = secondaryFieldsToDisplay.email;
      if (emailField && emailField !== FIELD_OPTIONS.DISABLED) {
        return (
          <div css={Styles.entryWrapper}>
            <div css={Styles.entryLabel}>{t('email')}</div>
            <div css={Styles.entryContent}>{renderData(email)}</div>
          </div>
        );
      }
    }
    return <span />;
  };

  const renderBirthday = () => {
    if (dataCaptureFlow !== DATA_CAPTURE_FLOWS.OFF) {
      const birthdayField = secondaryFieldsToDisplay.birthday;
      if (birthdayField && birthdayField !== FIELD_OPTIONS.DISABLED) {
        return (
          <div css={Styles.entryWrapper}>
            <div css={Styles.entryLabel}>{t('birthday')}</div>
            <div css={Styles.entryContent}>
              {birthday ? (
                formatDateType(birthday)
              ) : (
                <span css={Styles.placeholderData}>{t('none')}</span>
              )}
            </div>
          </div>
        );
      }
    }
    return <span />;
  };

  const renderGender = () => {
    if (dataCaptureFlow !== DATA_CAPTURE_FLOWS.OFF) {
      const genderField = secondaryFieldsToDisplay.gender;
      if (genderField && genderField !== FIELD_OPTIONS.DISABLED) {
        return (
          <div css={Styles.entryWrapper}>
            <div css={Styles.entryLabel}>{t('gender')}</div>
            <div css={Styles.entryContent}>
              <span css={Styles.capitalize}>{renderData(gender)}</span>
            </div>
          </div>
        );
      }
    }
    return <span />;
  };

  const renderFirstName = () => {
    if (dataCaptureFlow === DATA_CAPTURE_FLOWS.OFF && !firstName && lastName) {
      return (
        <div css={Styles.entryWrapper}>
          <div css={Styles.entryLabel}>{t('name')}</div>
          <div css={Styles.entryContent}>{lastName}</div>
        </div>
      );
    }
    if (dataCaptureFlow !== DATA_CAPTURE_FLOWS.OFF && !firstName) {
      return (
        <div css={Styles.entryWrapper}>
          <div css={Styles.entryLabel}>{t('first-name')}</div>
          <div css={Styles.entryContent}>
            <span css={Styles.placeholderData}>{t('none')}</span>
          </div>
        </div>
      );
    }
    return (
      <div css={Styles.entryWrapper}>
        <div css={Styles.entryLabel}>{t('first-name')}</div>
        <div css={Styles.entryContent}>{firstName}</div>
      </div>
    );
  };

  const renderTier = () => (
    <div css={Styles.entryWrapper}>
      <div css={Styles.entryLabel}>{t('tier')}</div>
      <div css={Styles.entryContent}>
        <span css={Styles.capitalize}>
          {renderData(
            currentTier && currentTier.name
              ? `${currentTier.name} (${currentTier.loyaltyPercentage}%)`
              : '-'
          )}
        </span>
      </div>
    </div>
  );

  const renderFormattedDateField = (label, value) => (
    <div css={Styles.entryWrapper}>
      <div css={Styles.entryLabel}>{label}</div>
      <div css={Styles.entryContent}>
        <span css={Styles.capitalize}>{renderData(value)}</span>
      </div>
    </div>
  );

  const renderPopOverLinks = (link) => {
    switch (link) {
      case 'edit': {
        return <a {...assignEventListener(gotoEditDetailsHandler)}> {t('edit-details')} </a>;
      }
      case 'claim': {
        return (
          <Link to={`/capture/claim?step=${CLAIM_STEPS.CLAIM_LOYALTY}`}>{t('claim-cashback')}</Link>
        );
      }
      case 'redeemRedirectHandler': {
        return (
          <a {...assignEventListener(redeemCampaignsRedirectHandler)}>{t('redeem-campaign')}</a>
        );
      }
      case 'redeemAmount': {
        return (
          <Link to={`/capture/redeem?step=${REDEEM_STEPS.REDEEM_AMOUNT}`}>
            {t('redeem-credit')}
          </Link>
        );
      }
      case 'redeemCampaign': {
        return (
          <Link to={`/capture/redeem?step=${REDEEM_STEPS.REDEEM_CAMPAIGN}`}>
            {t('redeem-campaign')}
          </Link>
        );
      }
      case 'add': {
        return <Link to={`/capture/add?step=${ADD_STEPS.ADD_AMOUNT}`}>{t('add-credit')}</Link>;
      }
      case 'deduct': {
        return (
          <Link to={`/capture/deduct?step=${DEDUCT_STEPS.DEDUCT_AMOUNT}`}>
            {t('deduct-credit')}
          </Link>
        );
      }
      default: {
        return t('not-available');
      }
    }
  };

  return (
    <div css={Styles.cusContent}>
      <div css={Styles.detailsTitleSection}>
        <div css={Styles.detailsTitle}>{t('customer-details')}</div>
        <Popover
          position={'bottom'}
          content={
            <ul css={Styles.dropdownMenu}>
              {popOverLinks.map((link) => (
                <li key={link}>{renderPopOverLinks(link)}</li>
              ))}
            </ul>
          }
        >
          <button id="dropdown-menu-button" type="button" css={Styles.dropdownMenuTriggerBtn}>
            <HorizontalDots />
          </button>
        </Popover>
      </div>
      {renderFirstName()}
      {renderLastName()}
      <div css={Styles.entryWrapper}>
        <div css={Styles.entryLabel}>{t('phone-number')}</div>
        <div css={Styles.entryContent}>{formatNumber(phone)}</div>
      </div>
      {renderEmail()}
      <div css={Styles.rowWrapper}>
        {renderBirthday()}
        {renderGender()}
      </div>
      <div css={Styles.rowWrapper}>
        {memberSince ? renderFormattedDateField(t('member-since'), memberSince) : null}
        {renewalDate ? renderFormattedDateField(t('renewal-date'), renewalDate) : null}
      </div>
      <div css={Styles.rowWrapper}>
        <div css={Styles.entryWrapper}>
          <div css={Styles.entryLabel}>{amountLabel || t('available-credit')}</div>
          <div css={Styles.entryContent}>
            <Amount currency={locale.currencyDenomination} amount={balance} />
          </div>
        </div>
        {renderTier()}
      </div>
    </div>
  );
};

CustomerDetails.propTypes = {
  Styles: PropTypes.object,
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    gender: PropTypes.string,
    email: PropTypes.string,
    birthday: PropTypes.any,
    balance: PropTypes.string,
    currentTier: PropTypes.object,
    memberSince: PropTypes.string,
    renewalDate: PropTypes.string,
  }),
  dataCaptureFlow: PropTypes.string.isRequired,
  secondaryFieldsToDisplay: PropTypes.object,
  gotoEditDetailsHandler: PropTypes.func,
  redeemCampaignsRedirectHandler: PropTypes.func,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
  amountLabel: PropTypes.string.isRequired,
  popOverLinks: PropTypes.array.isRequired,
};

export default CustomerDetails;
