import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Styles from './Input.styles';

const Input = ({
  type = 'text',
  name = '',
  label = '',
  css = '',
  errMsg = '',
  invalid = false,
  fieldState = 'NONE',
  ...rest
}) => {
  const [t] = useTranslation();
  let inputTypeStyles;
  switch (type) {
    case 'text':
    case 'email': {
      inputTypeStyles = Styles.text;
      break;
    }
    case 'password': {
      inputTypeStyles = Styles.password;
      break;
    }
    default: {
      inputTypeStyles = Styles.text;
    }
  }

  return (
    <div css={Styles.inputWrapper}>
      <label css={Styles.label} htmlFor={name}>
        {label} {fieldState === 'OPTIONAL' ? ` (${t('optional')})` : ''}
      </label>
      <input css={[inputTypeStyles, css]} type={type} name={name} {...rest} />
      {invalid && errMsg ? <div css={Styles.errorMsg}> {errMsg}</div> : null}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  fieldState: PropTypes.oneOf(['REQUIRED', 'OPTIONAL', 'NONE']),
  name: PropTypes.string,
  label: PropTypes.string,
  css: PropTypes.string,
  invalid: PropTypes.bool,
  errMsg: PropTypes.string,
};

export default Input;
