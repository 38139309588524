import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledInput = styled.input`
  width: 100%;
  border: 0;
  appearance: none;
  background: #fff;
  border-bottom: 2px solid
    ${(props) => {
      switch (props.invalid) {
        case true: {
          return props.theme.border.error;
        }
        default:
          return props.theme.border.alternate;
      }
    }};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding: 1rem 1rem;
  color: ${(props) => props.theme.text.primary};

  transition: border 300ms ease;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.text.grey};
  }
  ${(props) => props.css};
  z-index: 2;
`;

export const slideDownAnimation = css`
  @keyframes slide-down {
    from {
      margin-top: -1rem;
    }

    to {
      margin-top: 0;
    }
  }
`;

export const inputWrapper = css`
  margin: 0 0 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  // width: 100%;
`;

export const errorMsg = css`
  ${slideDownAnimation};
  min-height: 1rem;

  text-align: left;
  font-weight: 400;
  color: var(--color-error);

  animation-name: slide-down;
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
`;

export const phoneInputWrapper = css`
  position: relative;

  &::after {
    content: '+';
    font-size: 20px;
    position: absolute;
    top: 48%;
    left: 23px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 2;
  }
`;
