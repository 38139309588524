import { css } from '@emotion/react';

export const svg = css`
  margin-right: 0.75rem;

  .a {
    fill: none;
  }
  .b {
    clip-path: url(#a);
  }
  .c,
  .d {
    fill: var(--color-primary);
    stroke: var(--color-primary);
    stroke-width: 0.5px;
  }
  .c {
    stroke-linecap: round;
  }
`;
