import React from 'react';
import PropTypes from 'prop-types';
import { MINIMUM_PHONE_NUMBER_LENGTH } from 'constants/numbers';
import { formatNumber } from 'util/number-helper';
import { assignEventListener } from 'util/event-helper';
import { getObjectValues, stringToElementId } from 'util/helpers';
import useNumpad from 'hooks/useNumpad';
import { UserHead } from 'components/Icons';
import Numpad from 'pages/CapturePage/components/Numpad';
import { useTranslation } from 'react-i18next';
import * as Styles from './PhoneNumberLook.styles';

const PhoneNumberLookup = (props) => {
  const {
    locale,
    customer,
    phoneNumberSearchHandler,
    resetNumberHandler,
    gotoNextHandler,
    isCustomerRequiredBeforeNextStep,
    buttonText = '',
    isPhoneLoading,
  } = props;

  const [t] = useTranslation();

  const { num, addDigit, removeDigit, resetNumpad } = useNumpad(locale.phonePrefix);
  const isPhoneSearchLoaded = getObjectValues(isPhoneLoading).every((b) => !b);
  const isNextStepAllowed =
    isPhoneSearchLoaded &&
    num.length >= MINIMUM_PHONE_NUMBER_LENGTH &&
    (!isCustomerRequiredBeforeNextStep || (isCustomerRequiredBeforeNextStep && customer.isFound));

  React.useEffect(() => {
    if (num.length >= MINIMUM_PHONE_NUMBER_LENGTH) {
      phoneNumberSearchHandler(num);
    }
    // eslint-disable-next-line
  }, [num]);

  React.useEffect(() => {
    if (!customer.phone) {
      resetNumpad();
    }
    // eslint-disable-next-line
  }, [customer.phone]);

  const phonePrefixLength = (locale.phonePrefix || '').length;
  const renderName = () => {
    if (num.length >= MINIMUM_PHONE_NUMBER_LENGTH) {
      if (customer.isFound) {
        let name = `${customer.firstName} ${customer.lastName}` || '';
        if (name.length > 20) name = `${name.substring(0, 20)}..`;

        return (
          <div css={Styles.nameWrapper}>
            <div css={Styles.nameIcon}>
              <UserHead />
            </div>
            <div css={Styles.name}>{name}</div>
          </div>
        );
      }
    }

    if (num.length > phonePrefixLength) {
      return (
        <div css={Styles.nameWrapper}>
          <div css={Styles.nameIcon}>
            <UserHead />
          </div>
          <div css={Styles.name}>{t('unknown-phone-num')}</div>
        </div>
      );
    }

    return <div css={Styles.nameWrapper}>&nbsp;</div>;
  };

  const renderClearButton = () => {
    if (num.length > phonePrefixLength) {
      return (
        <div css={Styles.clearWrapper}>
          <button
            type="button"
            id="clear-button"
            className="lbtn--clear"
            css={Styles.clearButton}
            {...assignEventListener(() => {
              resetNumpad();
              resetNumberHandler();
            })}
          >
            <span css={Styles.clearIcon}>&times;</span>
            <span css={Styles.clearText}>{t('clear-all')}</span>
          </button>
        </div>
      );
    }
    return <div />;
  };

  const renderErrorMessage = () => {
    // Only show this error when they can't proceed without guest details like in redeem, add and deduct credit
    if (num.length >= MINIMUM_PHONE_NUMBER_LENGTH && isCustomerRequiredBeforeNextStep) {
      if (!customer.isFound) {
        return <div css={Styles.errorText}>{t('this-guest-is-not-found')}</div>;
      }
    }
    return null;
  };

  return (
    <div css={[Styles.inputContainer, Styles.inputContainerIPad]}>
      <div css={Styles.inputContent}>
        <div css={Styles.mobDesc}>{t('enter-your-phone-number')}</div>
        <div css={Styles.mobileNum}>{formatNumber(num)}</div>
        <div css={Styles.nameClearWrapper}>
          {renderName()}
          {renderClearButton()}
        </div>

        <Numpad addDigitHandler={addDigit} removeDigitHandler={removeDigit} />
      </div>
      <div css={[Styles.inputFooter, Styles.inputFooterIPad]}>
        {renderErrorMessage()}
        <button
          className="lbtn--submit"
          id={stringToElementId(buttonText, 'submit')}
          css={[isNextStepAllowed ? Styles.CTA : Styles.disabledCTA, Styles.CTAIPad]}
          type="button"
          {...assignEventListener(isNextStepAllowed ? gotoNextHandler : null)}
        >
          {isPhoneSearchLoaded ? buttonText : t('loading')}
        </button>
      </div>
    </div>
  );
};

PhoneNumberLookup.propTypes = {
  locale: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  resetNumberHandler: PropTypes.func.isRequired,
  gotoNextHandler: PropTypes.func.isRequired,
  phoneNumberSearchHandler: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  isCustomerRequiredBeforeNextStep: PropTypes.bool,
  isPhoneLoading: PropTypes.object,
};

PhoneNumberLookup.defaultProps = {
  isCustomerRequiredBeforeNextStep: true,
};

export default PhoneNumberLookup;
