import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ className }) => (
  <svg
    width="100%"
    viewBox="0 0 353 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>UMAI logo</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M277.365.333h-4.639L224.607 102.14h5.073l13.913-29.43 31.312-66.568 29.304 62.09 2.173 4.477 13.767 29.455h5.366L277.365.334zM.685 58.536V1.063H5.61v57.17c0 26.859 14.493 41.011 36.526 41.011 21.886 0 36.092-13.575 36.092-40.75V1.02h4.927v57.037c0 30.185-16.667 45.615-41.166 45.615C17.644 103.732.684 88.423.684 58.536zm108.111-57.48h4.64l42.905 61.514 42.735-61.513h4.64V102.14h-4.927V9.578l-42.472 60.65h-.293l-42.472-60.65v92.562h-4.756V1.057zm243.52 0h-4.927V102.14h4.927V1.057zM256.78 68.209h34.614v4.47H256.78v-4.47z"
      fill="#fff"
    />
  </svg>
);

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
