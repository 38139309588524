import { DEFAULT_LANGUAGE } from 'constants/app-env';
import { setMomentLocale } from 'util/locale-helper';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import moment from 'moment-timezone';
import { supportedLanguages } from './language';
import './date-fs-locale';

let i18nInstance = null;
export const initI18next = ({ languages, defaultLanguage }) => {
  const supportedLngs = supportedLanguages(languages).map((l) => l?.language);
  const fallbackLng =
    supportedLngs.length === 1 ? supportedLngs[0] : defaultLanguage?.language || DEFAULT_LANGUAGE;
  i18nInstance = i18n.use(HttpApi).use(LanguageDetector).use(initReactI18next);
  i18nInstance
    .init({
      load: 'currentOnly',
      fallbackLng,
      supportedLngs,
      cleanCode: true,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
      backend: {
        loadPath: '/locales/{{lng}}/translation.json',
        crossDomain: true,
      },
    })
    .then(() => {
      setMomentLocale(supportedLngs).then(() => {
        moment.locale(i18nInstance?.language);
      });
    });
  return i18nInstance;
};

export default i18n;
