import { ROUTES_THAT_REQUIRE_PERMISSION } from 'constants/routes';
import { useContext } from 'react';
import { PartnerContext } from 'contexts/PartnerProvider';

const useRouteAuthorization = (allowedPermission, path) => {
  const {
    partner: { currentUser },
  } = useContext(PartnerContext);

  if (!currentUser) {
    return false;
  }

  if (currentUser.isOwner) {
    return true;
  }

  const isAuthorizationNeeded = ROUTES_THAT_REQUIRE_PERMISSION.includes(path);

  if (isAuthorizationNeeded) {
    return currentUser.permissions.includes(allowedPermission);
  }

  return true;
};

export default useRouteAuthorization;
