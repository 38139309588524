import React from 'react';
import humps from 'humps';
import SocketConnection from 'util/socket-connection';

const useSocket = () => {
  const [cable, setCableConnection] = React.useState(null);
  const [channelSubscription, setChannelSubscription] = React.useState(null);

  const initializeSocket = (socketURL) => {
    const cableConnection = new SocketConnection(socketURL);
    return cableConnection;
  };

  const subscribeChannel = ({ token, channel, data, onReceived }) => {
    if (!channel || !onReceived || !data)
      throw new Error('venueId, channel & onReceived & data required for subscribing to channel');

    if (!cable && !token) throw new Error('token is required for creating new socket connection');

    const handleReceivedData = (receivedData) => {
      try {
        const parsedData = humps.camelizeKeys(JSON.parse(receivedData.params));
        onReceived(parsedData);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };

    if (cable) {
      const subscription = cable.subscribe(
        { channel, data },
        {
          onReceivedData: handleReceivedData,
        }
      );
      setChannelSubscription(subscription);
    } else {
      const socketURL = `${process.env.REACT_APP_ACTION_CABLE_SOCKET_URL}?token=${token}`;
      const cableConnection = initializeSocket(socketURL);
      const subscription = cableConnection.subscribe(
        { channel, data },
        {
          onReceivedData: handleReceivedData,
        }
      );
      setCableConnection(cableConnection);
      setChannelSubscription(subscription);
    }
  };

  const unsubscribeChannel = () => {
    cable?.unsubscribe(channelSubscription);
  };

  React.useEffect(
    () => () => {
      cable?.disconnect();
    },
    // eslint-disable-next-line
    []
  );

  return {
    subscribeChannel,
    unsubscribeChannel,
  };
};

export default useSocket;
