import { formatIncompletePhoneNumber } from 'libphonenumber-js/min';

export const formatNumber = (phoneNumber) => {
  if (typeof phoneNumber === 'number') phoneNumber = phoneNumber.toString();
  if (typeof phoneNumber !== 'string') return '';
  // add + if it doesn't exist
  if (phoneNumber.indexOf('+') === -1) phoneNumber = `+${phoneNumber}`;

  try {
    return formatIncompletePhoneNumber(phoneNumber);
  } catch (err) {
    console.error(err);
    return phoneNumber;
  }
};

export const formatAmountWithCommas = (num) => {
  if (num[num.length - 1] === '.') return num;
  num = num
    ?.toString()
    ?.replace(/[^0-9.]/g, '') // remove chars except number and point.
    ?.split('.'); // split it so you can add decimal places on the digits before the .
  num[0] = num?.[0]?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return num?.join('.');
};

export const cleanPhoneNumber = (phoneNumber) => phoneNumber.replace(/\D/g, '');

export const formatNumberPrecision = (num = 0, precision = 0) =>
  Number(num).toFixed(precision) || 0;
