import React from 'react';
import * as Styles from './CheckIcon.styles';

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    css={Styles.svg}
  >
    <g className="b">
      <g className="c">
        <path
          className="e"
          d="M 8 15.25 C 4.002339839935303 15.25 0.75 11.99765968322754 0.75 8 C 0.75 4.002339839935303 4.002339839935303 0.75 8 0.75 C 11.99765968322754 0.75 15.25 4.002339839935303 15.25 8 C 15.25 11.99765968322754 11.99765968322754 15.25 8 15.25 Z"
        />
        <path
          className="f"
          d="M 8 1.5 C 4.415889739990234 1.5 1.5 4.415889739990234 1.5 8 C 1.5 11.58411026000977 4.415889739990234 14.5 8 14.5 C 11.58411026000977 14.5 14.5 11.58411026000977 14.5 8 C 14.5 4.415889739990234 11.58411026000977 1.5 8 1.5 M 8 0 C 12.41827964782715 0 16 3.581720352172852 16 8 C 16 12.41827964782715 12.41827964782715 16 8 16 C 3.581720352172852 16 0 12.41827964782715 0 8 C 0 3.581720352172852 3.581720352172852 0 8 0 Z"
        />
      </g>
      <path
        className="d"
        d="M6.064.092a.3.3,0,0,0-.435,0L1.942,3.857.525,2.41a.3.3,0,0,0-.435,0,.319.319,0,0,0,0,.444L1.725,4.523a.3.3,0,0,0,.435,0L6.064.536A.319.319,0,0,0,6.064.092Z"
        transform="translate(4.923 5.846)"
      />
    </g>
  </svg>
);

CheckIcon.propTypes = {};
export default CheckIcon;
