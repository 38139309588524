import { useState } from 'react';

function useError(initErrVal = false, initErrMsg = '') {
  const [isErr, setErr] = useState(initErrVal);
  const [errorMsg, setErrorMsg] = useState(initErrMsg);

  const setError = (isE = false, eMsg = '') => {
    if (isE) {
      setErr(true);
      setErrorMsg(eMsg);
    }
  };

  return [isErr, errorMsg, setError];
}

export default useError;
