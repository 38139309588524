import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { useTranslation } from 'react-i18next';
import ModalWrapper from 'components/ModalWrapper';
import Amount from 'components/Amount';
import { formatNumber } from 'util/number-helper';
import { humanizeCampaignName } from 'util/campaign-helper';
import { assignEventListener } from 'util/event-helper';
import * as Styles from './RedeemCampaignConfirmStep.styles';

const RedeemCampaignConfirmStep = (props) => {
  const {
    customer: { firstName, lastName, phone, balance } = {},
    redeemCampaignHandler,
    selectedRedeemableCampaign,
    resetModalHandler,
    locale,
    isLoading,
  } = props;
  const [t] = useTranslation();
  const internalRedeemCampaignHandler = (campaignId) => () => redeemCampaignHandler({ campaignId });

  const getCusNameWithApostrophy = () => `${firstName || lastName}'s`;

  const title = `${getCusNameWithApostrophy()} ${
    humanizeCampaignName(selectedRedeemableCampaign.loyaltyCampaignName) || ''
  }`;

  return (
    <div className="redeem-campaign-step">
      <ModalWrapper wrapperSelector=".redeem-campaign-step" resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <h2 css={Styles.title}>{title}</h2>
          <div css={Styles.campaignNameAlias}>
            {t('loyalty-campaign-name', {
              loyaltyCampaignName: selectedRedeemableCampaign?.loyaltyCampaignName || '',
            })}
          </div>
          <div css={Styles.customerDetails}>
            <div css={Styles.entryWrapper}>
              <div css={Styles.entryLabel}>{t('name')}</div>
              <div css={Styles.entryContent}>
                {firstName} {lastName}
              </div>
            </div>
            <div css={Styles.entryWrapper}>
              <div css={Styles.entryLabel}>{t('phone-number')}</div>
              <div css={Styles.entryContent}>{formatNumber(phone)}</div>
            </div>
            <div css={Styles.entryWrapper}>
              <div css={Styles.entryLabel}>{t('balance')}</div>
              <div css={Styles.entryContent}>
                <Amount currency={locale.currencyDenomination} amount={balance} />
              </div>
            </div>
          </div>
          <div css={Styles.smsTextWrapper}>
            <div css={Styles.smsTextTitle}>
              {t('sms-text-sent-to-name', { name: firstName || lastName })}
            </div>
            <div css={Styles.smsText}>{selectedRedeemableCampaign.content}</div>
          </div>

          <button
            id="redeem-campaign-button"
            css={Styles.CTA}
            type="button"
            {...assignEventListener(internalRedeemCampaignHandler(selectedRedeemableCampaign.id))}
          >
            {t('redeem-campaign')}
          </button>

          <div css={isLoading ? Styles.loadingOverlay : Styles.loadingOverlayHidden}>
            <ReactLoading type={'spin'} color={'#169A89'} width={50} />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

RedeemCampaignConfirmStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    lastName: PropTypes.string,
  }).isRequired,
  selectedRedeemableCampaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
    loyaltyCampaignName: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    expiredAt: PropTypes.string.isRequired,
    redeemedAt: PropTypes.string,
  }).isRequired,
  selectedRedeemableCampaignRedeemed: PropTypes.bool.isRequired,
  gotoRedeemCashbackStep: PropTypes.func.isRequired,
  redeemCampaignHandler: PropTypes.func.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RedeemCampaignConfirmStep;
