import { formatNumber } from 'util/number-helper';
import { humanizeCampaignName } from 'util/campaign-helper';
import { getDateFormatter, getNumberOfDaysFromTodayFromServerDate } from 'util/date-helper';
import React, { useEffect } from 'react';
import { Toggle } from '@umai/common';
import PropTypes from 'prop-types';
import Amount from 'components/Amount';
import ProgressBar from 'components/ProgressBar';
import moment from 'moment-timezone';
import imageHeader from 'images/loyalty-inteface-header.jpeg';
import tierGoal from 'images/tier-goal.svg';
import { Trans, useTranslation } from 'react-i18next';
import CampaignPopup from './CampaignPopup';
import * as Styles from './MemberAccount.styles';

const MemberAccount = (props) => {
  const {
    customer: {
      phone,
      firstName,
      lastName,
      email,
      gender,
      birthday,
      availableCampaigns = [],
      balance,
      showSubscriptionToggle = false,
      isSubscribedToLoyaltySMS = false,
      currentTier = {},
      nextTier = {},
      tierPercentage = {},
    },
    venueName = '',
    isUsingSecureLink = false,
    locale: { currencyDenomination },
    getAccountHistoryHandler = () => {},
    toggleSmsSubscriptionHandler,
  } = props;
  const [t] = useTranslation();
  const [isCampaignPopupVisible, setCampaignPopupVisibility] = React.useState(false);
  const [selectedCampaign, setSelectedCampaign] = React.useState();
  const formatBirthday = getDateFormatter('ll');

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--bg-image', `url(${imageHeader})`);
  }, []);

  const handleCampaignSelection = (campaign) => {
    setSelectedCampaign(campaign);
    setCampaignPopupVisibility(true);
  };

  const renderAmount = (amount) => (
    <Amount currency={currencyDenomination} amount={Number(amount).toFixed(2)} />
  );

  const tierType = (type) => {
    const tierPercentageEndDate = tierPercentage.endDateOfTier
      ? `by ${moment(tierPercentage.endDateOfTier).format('ll')}`
      : '';
    switch (type) {
      case 'total_visit': {
        return {
          text: (
            <Trans
              i18nKey="tier-type.total_visit"
              values={{
                totalVisit: nextTier.numberOfVisit - tierPercentage.totalVisit,
                tierPercentageEndDate,
                name: nextTier.name,
                loyaltyPercentage: nextTier.loyaltyPercentage,
              }}
            />
          ),
          percentage: tierPercentage.visitPercentage,
        };
      }
      case 'total_spend': {
        return {
          text: (
            <Trans
              i18nKey="tier-type.total_spend"
              values={{
                tierPercentageEndDate,
                name: nextTier.name,
                loyaltyPercentage: nextTier.loyaltyPercentage,
              }}
              components={{
                TotalSpend: renderAmount(nextTier.totalSpendAmount - tierPercentage.totalSpend),
              }}
            />
          ),
          percentage: tierPercentage.amountPercentage,
        };
      }
      case 'both': {
        return {
          text: (
            <Trans
              i18nKey="tier-type.total_spend"
              values={{
                totalVisit: nextTier.numberOfVisit - tierPercentage.totalVisit,
                tierPercentageEndDate,
                name: nextTier.name,
                loyaltyPercentage: nextTier.loyaltyPercentage,
              }}
              components={{
                TotalSpend: renderAmount(nextTier.totalSpendAmount - tierPercentage.totalSpend),
              }}
            />
          ),
          percentage: Math.max(tierPercentage.amountPercentage, tierPercentage.visitPercentage),
        };
      }
      default: {
        return {
          text: t('tier-type.no-tier'),
          percentage: 0,
        };
      }
    }
  };

  const renderGuestDetails = () => {
    if (isUsingSecureLink) {
      return (
        <div css={Styles.accountDetailsWrapper}>
          <div css={Styles.accountDetailsRow}>
            <div css={Styles.accountDetailsColumn}>
              <span>{t('first-name')}</span>
              <span>{!firstName ? '-' : firstName}</span>
            </div>
            <div css={Styles.accountDetailsColumn} className="text-right">
              <span>{t('last-name')}</span>
              <span>{!lastName ? '-' : lastName}</span>
            </div>
          </div>

          <div css={Styles.accountDetailsRow}>
            <div css={Styles.accountDetailsColumn}>
              <span>{t('birthday')}</span>
              <span>{birthday ? formatBirthday(birthday) : '-'}</span>
            </div>
            <div css={Styles.accountDetailsColumn} className="text-right gender">
              <span>{t('gender')}</span>
              <span>{!gender ? '-' : gender}</span>
            </div>
          </div>

          <div css={Styles.accountDetailsRow}>
            <div css={Styles.accountDetailsColumn}>
              <span>{t('email')}</span>
              <span>{!email ? '-' : email}</span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderCampaigns = () => {
    if (availableCampaigns.length === 0) {
      return (
        <p css={Styles.noAvailableCampaigns}>
          {t(`you-dont-currently-have-any-rewards-available`)}
        </p>
      );
    }
    return (
      <ul css={Styles.campaignsWrapper}>
        {availableCampaigns.map((campaign = {}) => {
          const expiresIn = getNumberOfDaysFromTodayFromServerDate(campaign.expiredAt);
          return (
            // eslint-disable-next-line
            <li
              key={campaign.loyaltyCampaignName}
              css={Styles.campaign}
              onClick={() => handleCampaignSelection(campaign)}
            >
              <div css={Styles.campaignDetailsWrapper}>
                <div css={Styles.campaignName}>
                  {humanizeCampaignName(campaign.loyaltyCampaignName)}
                </div>
                <div css={Styles.campaignExpiryDate}>{t('expiring-in-days', { expiresIn })}</div>
              </div>
              <div css={Styles.campaignDetailsToggleWrapper}>
                {/* eslint-disable-next-line */}
                <a
                  css={Styles.campaignDetailsToggle}
                  onClick={() => handleCampaignSelection(campaign)}
                >
                  {t('see-details')}
                </a>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderHistoryButton = () => {
    if (isUsingSecureLink) {
      return (
        <div>
          {/* eslint-disable-next-line */}
          <a css={Styles.historyButton} onClick={() => getAccountHistoryHandler(1)}>
            {t('click-to-see-history')}
          </a>
        </div>
      );
    }
    return null;
  };

  const renderCampaignsSection = () => {
    if (isUsingSecureLink) {
      return (
        <div css={Styles.availableRewards}>
          <div css={Styles.availableRewardsLabel}>{t('available-rewards')}</div>
          {renderCampaigns()}
        </div>
      );
    }
    return null;
  };

  const renderSmsSubscriptionToggle = () => {
    if (showSubscriptionToggle) {
      return (
        <div css={Styles.smsSubscriptionToggleWrapper}>
          <div css={Styles.smsSubscriptionToggleLabel}>{t('receive-sms-rewards')}</div>
          <Toggle
            name="sms-subscription-toggle"
            onChange={toggleSmsSubscriptionHandler}
            checked={isSubscribedToLoyaltySMS}
            ariaLabel="sms-subscription-toggle"
          />
        </div>
      );
    }
    return null;
  };

  const renderTierProgress = () => {
    if (nextTier && nextTier.name) {
      const progressvalue = tierType(currentTier && currentTier.tierType);
      return (
        <div css={Styles.tierProgress}>
          <div className="label">{t('tier-progress')}</div>
          <div className="progress">
            <ProgressBar percentage={progressvalue.percentage} />
            <img className="icon" src={tierGoal} alt=""></img>
          </div>
          <div className="progressText">{progressvalue.text}</div>
        </div>
      );
    }
    return null;
  };

  const renderTier = () => {
    if (currentTier && currentTier.name && isUsingSecureLink) {
      return (
        <>
          <div css={Styles.currentTier}>
            <div>{t('current-tier')} </div>
            <div className="name">{(currentTier && currentTier.name) || t('none')}</div>
            <div>
              {(currentTier && currentTier.loyaltyPercentage) || 0}% {t('cashback')}
            </div>
          </div>
          {renderTierProgress()}
        </>
      );
    }
    return null;
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <div css={Styles.pageHeader} styles={{ '--bg-image': `url(${imageHeader})` }}>
        {venueName} {t('loyalty')}
      </div>
      <div css={Styles.phoneNumber}>{formatNumber(phone)}</div>
      <div css={Styles.accountBalanceWrapper}>
        <div css={Styles.accountBalanceLabel}>{t('balance')}</div>
        <div css={Styles.accountBalance}>{renderAmount(balance)}</div>
      </div>
      {/* START: Guest Details */}
      {renderGuestDetails()}

      {renderTier()}
      {/* END: Guest Details */}
      {renderHistoryButton()}
      {/* START: Available rewards */}
      {renderCampaignsSection()}
      {/* END: Available rewards */}

      {/* START: SMS subscriptions toggle */}
      {renderSmsSubscriptionToggle()}
      {/* END: SMS subscriptions toggle */}

      {/* START: Footer Popup for Campaign Details */}
      {isCampaignPopupVisible ? (
        <CampaignPopup
          campaign={{ ...selectedCampaign }}
          hideCampaignPopup={() => setCampaignPopupVisibility(false)}
        />
      ) : null}

      {/* END: Footer Popup for Campaign Details */}
    </>
  );
};

MemberAccount.propTypes = {
  isUsingSecureLink: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  venueName: PropTypes.string.isRequired,
  getAccountHistoryHandler: PropTypes.func.isRequired,
  toggleSmsSubscriptionHandler: PropTypes.func.isRequired,
};

export default MemberAccount;
