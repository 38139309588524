import i18n from 'services/i18n';

export const hasError = (errorData, errorMessage) => {
  const { errors = [], error = {} } = errorData;
  if (errors.length > 0) {
    return errors.indexOf(errorMessage) !== -1;
  }
  if (error) return error === errorMessage;
  return false;
};

export async function getErrorMessage(error) {
  const errorStatus = (error.response || {}).status;
  let errorResponseData = null;

  if (error.response && typeof error.response === 'object') {
    try {
      await error.response.json().then((data) => {
        errorResponseData = data;
      });
    } catch (e) {
      console.error(e);
    }
  }

  const SERVER_IS_DOWN = i18n.t('server-is-down');

  const INTERNAL_SERVER_ERROR = i18n.t('internal-server-error');

  let errorMessage = INTERNAL_SERVER_ERROR;
  if (errorResponseData && errorResponseData?.error) {
    errorMessage = errorResponseData.error;
  } else if (errorResponseData && errorResponseData?.errors) {
    errorMessage = errorResponseData.errors[0] || '';
  } else if (errorStatus === 500) {
    errorMessage = INTERNAL_SERVER_ERROR;
  } else if (errorStatus === 502) {
    errorMessage = SERVER_IS_DOWN;
  } else if (typeof errorResponseData === 'string') {
    errorMessage = errorResponseData;
  }

  if (errorMessage === 'Network Error') errorMessage = i18n.t('network-error');

  return errorMessage;
}
