import { css } from '@emotion/react';
import { breakpoints } from '@styles/media-queries';

export const pageWrapper = css`
  text-align: center;
`;

export const container = css`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const logoWrapper = css`
  padding: 0 0 2.5rem;
  font-size: 1.5rem;
  justify-self: flex-end;
`;
export const footerWrapper = css``;

export const inputWrapper = css`
  padding: 0 0 4rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 20rem;

  ${breakpoints.smallOnly} {
    max-width: 15rem;
  }
`;

export const loginBtn = css`
  margin: 1rem 0;
`;

export const slideDownAnimation = css`
  @keyframes slide-down {
    from {
      margin-top: -1rem;
    }

    to {
      margin-top: 0;
    }
  }
`;

export const errorMsg = css`
  ${slideDownAnimation};
  font-weight: 500;
  color: var(--color-error);
  border-radius: 4px;
  background-color: #00000033;

  animation-name: slide-down;
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
`;

export const forgotPassword = css`
  color: var(--color-primary);
  text-shadow: rgba(255, 255, 255, 0.5) 0 0 1px;
`;
