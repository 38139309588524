/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { AppContext } from 'contexts/AppProvider';
import { PartnerContext } from 'contexts/PartnerProvider';
import APP_ACCESS from 'constants/app-access';
import ROUTES from 'constants/routes';
import { savePartnerSession } from 'util/session-helper';
import { getErrorMessage } from 'util/error-helper';
import { authPartner as authPartnerPOST } from 'api/partner';
import useError from 'hooks/useError';

function useWrapper() {
  const { app } = React.useContext(AppContext);
  const { partner, authPartner } = React.useContext(PartnerContext);
  const [loading, setLoading] = React.useState(false);

  const [authFailed, authFailedMsg, setAuthFailed] = useError(false, '');

  const loginHandler = async ({ username, password }) => {
    try {
      setAuthFailed(false);
      setLoading(true);

      const loginRes = await authPartnerPOST({ username, password });
      const { accessToken: token } = loginRes;

      savePartnerSession({ username, token });
      authPartner({ username, token });
    } catch (e) {
      setAuthFailed(true, await getErrorMessage(e));
      // do something when auth failed
      // temporarily re-using validation failed flow
      console.error("Wooo something blew up while auth'ing partner", e);
    } finally {
      setLoading(false);
    }
  };

  let shouldRedirect = false;
  let redirectURL = '';
  if (partner.isAuthenticated) {
    shouldRedirect = true;
    redirectURL = app.access === APP_ACCESS.MANAGER ? ROUTES.REPORTS : ROUTES.CAPTURE;
  }

  return {
    partner,
    authFailed,
    authFailedMsg,
    loginHandler,
    shouldRedirect,
    redirectURL,
    loading,
  };
}

export default useWrapper;
