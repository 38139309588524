import { OPERATIONAL_PERMISSIONS } from 'constants/operational-permission';
import { CAPTURE_PAGE_VIEW } from 'constants/routes';

export const getAllowedPermission = (currentPath) => {
  if ([CAPTURE_PAGE_VIEW.CLAIM, CAPTURE_PAGE_VIEW.REDEEM].includes(currentPath)) {
    return OPERATIONAL_PERMISSIONS.LOYALTY_TRANSACTIONS;
  }
  if ([CAPTURE_PAGE_VIEW.DEDUCT, CAPTURE_PAGE_VIEW.ADD].includes(currentPath)) {
    return OPERATIONAL_PERMISSIONS.LOYALTY_ADDITIONS_DEDUCTIONS;
  }
  return currentPath;
};
