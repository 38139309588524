import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { useTranslation } from 'react-i18next';
import ModalWrapper from 'components/ModalWrapper';
import { Resend } from 'components/Icons';
import { assignEventListener } from 'util/event-helper';
import useNumpad from 'hooks/useNumpad';
import useInterval from 'hooks/useInterval';
import Numpad from '../../../Numpad';
import * as Styles from './OTPStep.styles';

const TIME_IN_SECONDS = 60 * 2 - 1; // 2 minutes equivalent in milleseconds - 1 second delay in interval
// const TIME_IN_SECONDS = 5; // 2 minutes equivalent in milleseconds - 1 second delay in interval
const TIME_IN_MINUTES = '02:00'; // 2 minutes equivalent in milleseconds

const OTPStep = (props) => {
  const {
    customer: { firstName, resendRequested, hasOTPError = false } = {},
    errorMessage = '',
    isLoading,
    submitOTPHandler,
    resendOTPHandler,
    resetModalHandler,
    removeErrorIndicator,
    showPasswordValidationOption = false,
    usePasswordValidation,
  } = props;
  const [t] = useTranslation();
  const { num: otp, addDigit, removeDigit } = useNumpad('');
  const [showResend, setResend] = React.useState(false);
  const [timer, setTimer] = React.useState(TIME_IN_SECONDS);
  const [countdown, setCountdown] = React.useState(TIME_IN_MINUTES);

  const isNextStepEnabled = otp.length === 4 && !hasOTPError;
  const isResendEnabled = !resendRequested;
  const showError = hasOTPError && otp.length === 4;

  useInterval(() => {
    let minutes = parseInt(timer / 60, 10);
    let seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    setCountdown(`${minutes}:${seconds}`);
    setTimer(timer - 1);

    if (timer < 0) {
      setResend(true);
    }
  }, 1000);

  const renderOTP = () => {
    const otpWithPlaceholder = otp.padEnd(4, 'X');

    return (
      <div css={Styles.otpWrapper}>
        {otpWithPlaceholder.split('').map((digit, i) => {
          const secondaryDigitStyle =
            digit !== 'X' ? Styles.otpDigitNumber : Styles.otpDigitPlaceholder;
          return (
            <div
              css={[Styles.otpDigit, secondaryDigitStyle, showError ? Styles.otpDigitError : '']}
              // eslint-disable-next-line react/no-array-index-key
              key={digit + i}
            >
              {digit}
            </div>
          );
        })}
      </div>
    );
  };

  const addDigitHandler = (digit) => {
    // limit otp input length to 4
    if (otp.length >= 4) return;

    addDigit(digit);
  };

  const removeDigitHandler = () => {
    removeErrorIndicator();
    removeDigit();
  };

  const resendBtn = () => {
    if (isResendEnabled && showResend) {
      return (
        <div css={Styles.timer}>
          <a css={Styles.resendBtn} {...assignEventListener(resendOTPHandler)}>
            <Resend />
            {t('resend-otp')}
          </a>
        </div>
      );
    }
    if (!resendRequested) {
      return (
        <div css={Styles.timer}>
          <Resend />
          {countdown}
        </div>
      );
    }
    return '';
  };

  return (
    <div className="hand-over-step">
      <ModalWrapper wrapperSelector={'.hand-over-step'} resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <div css={Styles.contentContainer}>
            <div css={Styles.title}>{t('enter-the-code-that-has-received', { firstName })}</div>
            {renderOTP()}

            {showError ? (
              <div css={Styles.errMsg}>{errorMessage}</div>
            ) : (
              <div css={Styles.errMsgPlaceholder}>&nbsp;</div>
            )}
            <Numpad
              addDigitHandler={addDigitHandler}
              removeDigitHandler={removeDigitHandler}
              type="otp"
              isPopup
            />

            <button
              id="next-button"
              css={isNextStepEnabled ? Styles.CTA : Styles.disabledCTA}
              type="button"
              {...assignEventListener(isNextStepEnabled ? () => submitOTPHandler(otp) : null)}
            >
              {t('next')}
            </button>

            {resendBtn()}

            {showPasswordValidationOption ? (
              <a css={Styles.usePasswordLink} {...assignEventListener(usePasswordValidation)}>
                {t('use-password')}
              </a>
            ) : null}
          </div>
          <div css={isLoading ? Styles.loadingOverlay : Styles.loadingOverlayHidden}>
            <ReactLoading type={'spin'} color={'#169A89'} width={50} />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

OTPStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    hasOTPError: PropTypes.bool.isRequired,
  }),
  errorMessage: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  submitOTPHandler: PropTypes.func.isRequired,
  resendOTPHandler: PropTypes.func.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  showPasswordValidationOption: PropTypes.bool,
  usePasswordValidation: PropTypes.func,
  removeErrorIndicator: PropTypes.func,
};

export default OTPStep;
