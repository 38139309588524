import { css } from '@emotion/react';

export const wrapper = css`
  @media (min-width: 768px) {
    max-width: 768px;
  }
  margin: 0 auto;
  // TODO: remove later
  // border: 1px solid #ddd;
`;

export const pageWrapper = css`
  text-align: center;
`;

export const container = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const bottomRow = css`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 2rem 0;
  width: 100%;
  position: relative;
`;

export const fixedToBottom = css`
  margin-top: auto;
`;

export const poweredByUMAIWrapper = css`
  position: absolute;
  bottom: 0rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const errorMessageWrapper = css`
  background: var(--color-error);
  padding: 1rem 2rem;
  color: #fff;
  border-radius: 8px;
  margin: 2.5rem 0;
  word-break: break-word;
`;
