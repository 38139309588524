/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import DATA_CAPTURE_FLOWS from 'constants/data-capture-flows';
import PhoneNumberStep from 'components/PhoneNumberStep';
import useWrapper, { STEPS } from './Claim.useWrapper';
import HandOverStep from './components/HandOverStep';
import AddAndClaim from './components/AddAndClaim';
import ClaimSuccessStep from './components/ClaimSuccessStep';
import DataAcquisitionStep from 'components/DataAcquisitionStep';
import * as Styles from './Claim.styles';

const Claim = (props) => {
  const {
    step,
    partner,
    customer,
    locale,
    editDetailsMode,
    error,
    resetDataHandler,
    phoneNumberSearchHandler,
    gotoNextStepFromPhoneNumStep,
    addClaimHandler,
    addCustomerHandler,
    updateCustomerHandler,
    handOverRedirectHandler,
    gotoEditDetailsHandler,
    claimSuccessRedirectHandler,
    addNoteToTransactionHandler,
    isLoading,
    isPhoneLoading,
    venueSettings: { secondaryFieldsToCapture, dataCaptureFlow = DATA_CAPTURE_FLOWS.OFF },
  } = props;

  const { hasDuplicateEmail } = customer;

  const [t] = useTranslation();

  // eslint-disable-next-line no-shadow
  const renderStep = (step) => {
    switch (step) {
      case STEPS.MOBILE_NUMBER: {
        return null;
      }
      case STEPS.HAND_OVER: {
        return (
          <HandOverStep
            customer={customer}
            handOverRedirectHandler={handOverRedirectHandler}
            resetModalHandler={resetDataHandler}
            locale={locale}
          />
        );
      }
      case STEPS.ADD_CUSTOMER: {
        return (
          <AddAndClaim
            customer={customer}
            step={STEPS.ADD_CUSTOMER}
            addCustomerHandler={addCustomerHandler}
            resetModalHandler={resetDataHandler}
            locale={locale}
            isLoading={isLoading}
            dataCaptureFlow={dataCaptureFlow}
          />
        );
      }
      case STEPS.CLAIM_LOYALTY: {
        return (
          <AddAndClaim
            secondaryFieldsToDisplay={secondaryFieldsToCapture}
            customer={customer}
            step={STEPS.CLAIM_LOYALTY}
            addClaimHandler={addClaimHandler}
            dataCaptureFlow={dataCaptureFlow}
            resetModalHandler={resetDataHandler}
            locale={locale}
            isLoading={isLoading}
            gotoEditDetailsHandler={gotoEditDetailsHandler}
          />
        );
      }
      case STEPS.CLAIM_SUCCESS: {
        return (
          <ClaimSuccessStep
            customer={customer}
            claimSuccessRedirectHandler={claimSuccessRedirectHandler}
            resetModalHandler={resetDataHandler}
            addNoteToTransactionHandler={addNoteToTransactionHandler}
            locale={locale}
          />
        );
      }
      case STEPS.HAND_OVER_NEW_CUSTOMER: {
        return (
          <HandOverStep
            customer={customer}
            handOverRedirectHandler={handOverRedirectHandler}
            resetModalHandler={resetDataHandler}
            locale={locale}
            isNewCustomer
          />
        );
      }
      case STEPS.DATA_ACQUISITION: {
        return (
          <DataAcquisitionStep
            customer={customer}
            editDetailsMode={editDetailsMode}
            secondaryFieldsToCapture={secondaryFieldsToCapture}
            addCustomerHandler={addCustomerHandler}
            updateCustomerHandler={updateCustomerHandler}
            resetModalHandler={resetDataHandler}
            isLoading={isLoading}
            hasDuplicateEmail={hasDuplicateEmail}
            error={error}
          />
        );
      }
      default:
        throw new Error("STEP didn't match");
    }
  };

  return (
    <div css={Styles.container}>
      <PhoneNumberStep
        customer={customer}
        phoneNumberSearchHandler={phoneNumberSearchHandler}
        gotoNextHandler={gotoNextStepFromPhoneNumStep}
        resetDataHandler={resetDataHandler}
        venue={{ name: partner.currentVenueName }}
        locale={locale}
        isPhoneLoading={isPhoneLoading}
        phoneNumberButtonText={t('claim-cashback')}
        isCustomerRequiredBeforeNextStep={false}
        transactionText={{
          title: t('get-your-cashback-here'),
          desc: t('receive-instant-cashback-when-you-spend'),
          steps: [
            {
              index: 'a',
              desc: t('enter-your-phone-number'),
            },
            {
              index: 'b',
              desc: t('you-will-receive-cashback-based-on-your-spendings'),
            },
            {
              index: 'c',
              desc: t('claim-your-cashback-and-enjoy-savings'),
            },
          ],
        }}
      />
      {renderStep(step)}
    </div>
  );
};

Claim.propTypes = {};

export default () => {
  const props = useWrapper();
  return <Claim {...props} />;
};
