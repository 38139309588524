import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'antd/lib/popover';

const Popup = ({ children, triggerElement, placement = 'bottomRight', ...restOfProps }) => (
  <Popover placement={placement} content={children} trigger="click" {...restOfProps}>
    {triggerElement}
  </Popover>
);

Popup.propTypes = {
  triggerElement: PropTypes.node.isRequired,
  placement: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Popup;
