import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalWrapper from 'components/ModalWrapper';
import Amount from 'components/Amount';
import { formatNumber } from 'util/number-helper';
import { assignEventListener } from 'util/event-helper';
import { getNumberOfDaysFromTodayFromServerDate } from 'util/date-helper';
import { humanizeCampaignName } from 'util/campaign-helper';
import * as Styles from './RedeemCampaignStep.styles';

const RedeemCampaignStep = (props) => {
  const {
    customer: { firstName, lastName, phone, balance, redeemableCampaigns = [] } = {},
    gotoRedeemCashbackStep,
    redeemCampaignGotoConfirmHandler,
    resetModalHandler,
    locale,
  } = props;
  const [t] = useTranslation();
  const internalRedeemCampaignConfirmHandler = (campaignId) => () =>
    redeemCampaignGotoConfirmHandler({ campaignId });

  return (
    <div className="redeem-campaign-step">
      <ModalWrapper wrapperSelector=".redeem-campaign-step" resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <h2 css={Styles.title}>{t('eligible-campaigns')}</h2>
          <div css={Styles.eligibleCampaignsContainer}>
            {redeemableCampaigns.length ? (
              redeemableCampaigns.map((c) => {
                const expiresIn = getNumberOfDaysFromTodayFromServerDate(c.expiredAt);
                return (
                  // eslint-disable-next-line
                  <div
                    css={Styles.campaignWrapper}
                    key={c.id}
                    onClick={internalRedeemCampaignConfirmHandler(c.id)}
                  >
                    <div css={Styles.nameWrapper}>
                      <div css={Styles.name}>
                        <span>{humanizeCampaignName(c.loyaltyCampaignName)}</span>
                      </div>
                      <div css={Styles.expiry}>
                        {t('loyalty-campaign-name-expiring-in-days', {
                          loyaltyCampaignName: c?.loyaltyCampaignName || '',
                          expiresIn,
                        })}
                      </div>
                    </div>
                    <button
                      id="redeem-campaign-button"
                      type="button"
                      css={Styles.redeemBtn}
                      {...assignEventListener(internalRedeemCampaignConfirmHandler(c.id))}
                    >
                      {t('redeem-campaign')}
                    </button>
                  </div>
                );
              })
            ) : (
              <div css={Styles.noCampaignsFound}>{t('no-redeemable-campaigns-found')}</div>
            )}
          </div>

          <div css={Styles.customerDetails}>
            <div css={Styles.entryWrapper}>
              <div css={Styles.entryLabel}>{t('name')}</div>
              <div css={Styles.entryContent}>
                {firstName} {lastName}
              </div>
            </div>
            <div css={Styles.entryWrapper}>
              <div css={Styles.entryLabel}>{t('phone-number')}</div>
              <div css={Styles.entryContent}>{formatNumber(phone)}</div>
            </div>
            <div css={Styles.entryWrapper}>
              <div css={Styles.entryLabel}>{t('balance')}</div>
              <div css={Styles.entryContent}>
                <Amount currency={locale.currencyDenomination} amount={balance} />
              </div>
            </div>
          </div>

          <button
            id="redeem-cashback-button"
            css={Styles.CTA}
            type="button"
            {...assignEventListener(gotoRedeemCashbackStep)}
          >
            {t('redeem-cashback')}
          </button>
        </div>
      </ModalWrapper>
    </div>
  );
};

RedeemCampaignStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    lastName: PropTypes.string,
  }).isRequired,
  gotoRedeemCashbackStep: PropTypes.func.isRequired,
  redeemCampaignGotoConfirmHandler: PropTypes.func.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
};

export default RedeemCampaignStep;
