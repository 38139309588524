import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useBlockDevTools } from '@umai/common';
import { assignEventListener } from 'util/event-helper';
import history from 'util/router-history';
import { isProductionEnv } from 'constants/app-env';
import ROUTES from 'constants/routes';
import ModalPopup from 'components/ModalWrapper';
import LogoIcon from 'components/LogoUMAI';
import VenueDropdown from 'components/VenueDropdown';
import LanguagePopover from 'components/LanguagePopover';
import { ReactComponent as BackArrow } from 'images/left-arrow.svg';
import styles from './PaymentProfile.module.scss';

// NOTE: this class name should be the same as in the @mixin is-venue-access-blocked
const venueAccessBlockedClass = 'venue-access-blocked';

function BlockedAccess({ handleBillingPage, isVenueAccessBlocked, showBackButton = false }) {
  const { t } = useTranslation();
  useBlockDevTools({
    isEnabled: isProductionEnv(),
    recordQuerySelector: '.blocked-access-modal, #blocked-access-overlay, #blocked-access-content',
    removeNodeCheck: (node) =>
      // modal of react-modal ( is parent-node of overlay )
      node?.firstChild?.id === 'blocked-access-overlay' ||
      // overlay of react-modal
      node.id === 'blocked-access-overlay' ||
      // content of react-modal
      node.id === 'blocked-access-content',
  });

  useEffect(() => {
    const bodyClassList = document.body.classList;

    if (isVenueAccessBlocked) {
      bodyClassList.add(venueAccessBlockedClass);
    } else {
      bodyClassList.remove(venueAccessBlockedClass);
    }

    return () => {
      bodyClassList.remove(venueAccessBlockedClass);
    };
  }, [isVenueAccessBlocked]);

  return (
    <ModalPopup
      id="blocked-access-content"
      hideClose
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      portalClassName={classnames('blocked-access-modal', styles.blockedAccessModal)}
      className={styles.blockedReactModal__Content}
      overlayRef={(node) => {
        if (node) {
          node.id = 'blocked-access-overlay';
          node.testingId = 'blocked-access-overlay';
        }
      }}
    >
      {showBackButton ? (
        <div
          className={styles.blockedBackButton}
          {...assignEventListener(() => history.push(ROUTES.NAV))}
        >
          <BackArrow />
          {t('back')}
        </div>
      ) : (
        <div className={styles.blockedTopRow}>
          <VenueDropdown type="dark" />
          <div className={styles.logoutWrapper}>
            <Link to={ROUTES.LOGOUT}>{t('log-out')}</Link>
          </div>
        </div>
      )}
      <section className={styles.blockedAccess}>
        <figure>
          <LogoIcon />
        </figure>
        <h3 className={styles.title}>{t('payment-profile.blocked.header')}</h3>
        <p className={styles.description}>
          <Trans i18nKey="payment-profile.blocked.body">
            After numerous attempts to collect payment we’ve had to pause your UMAI services due to
            an outstanding payment on an invoice.
            <br />
            <br />
            To avoid this in the future we recommend setting up an auto payment method, that way
            everything is automatically paid as you go and we can avoid having to pause any
            services.
            <br />
            <br />
            You can see a detailed invoice, pay your outstanding amount, and set up auto payment in
            your billing settings.
          </Trans>
        </p>
        <button type="button" className={styles.goToBillingButton} onClick={handleBillingPage}>
          {t('payment-profile.go-to-billing-settings')}
        </button>
      </section>
      <div className={styles.blockedBottomRow}>
        <LanguagePopover bordered type="light" />
      </div>
    </ModalPopup>
  );
}

BlockedAccess.propTypes = {
  handleBillingPage: PropTypes.func.isRequired,
  isVenueAccessBlocked: PropTypes.bool.isRequired,
  showBackButton: PropTypes.bool,
};

export default BlockedAccess;
