import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppHelmet from 'components/AppHelmet';
import PoweredByUMAI from 'components/PoweredByUMAI';
import LanguagePopover from 'components/LanguagePopover';
import SuspensionScreen from 'components/SuspensionScreen';
import LoadingPage from 'pages/LoadingPage';
import useWrapper, { STEPS } from './GuestSignup.useWrapper';
import PhoneNumberStep from './components/PhoneNumberStep';
import RegistrationStep from './components/RegistrationStep';
import SendProfileLinkStep from './components/SendProfileLinkStep';
import RegistrationSuccessStep from './components/RegistrationSuccessStep';
import * as Styles from './GuestSignup.styles';

const GuestSignup = (props) => {
  const {
    hasLoaded,
    step,
    phoneNumber,
    venueSetSettings = {},
    hasSentProfileLink,
    isSendProfileButtonEnabled,
    hasErrorMessage,
    errorMessage,
    phoneNumberSearchHandler,
    sendProfileLinkHandler,
    setResendProfileButtonFlag,
    signupToVenueLoyaltyHandler,
    goBackToPhoneNumberStep,
    isBlocked,
  } = props;
  const [t] = useTranslation();

  const renderComponent = () => {
    switch (step) {
      case STEPS.ENTER_PHONE_NUMBER:
        return (
          <PhoneNumberStep
            phoneNumber={phoneNumber}
            venueSetSettings={venueSetSettings}
            phoneNumberSearchHandler={phoneNumberSearchHandler}
            hasErrorMessage={hasErrorMessage}
            errorMessage={errorMessage}
          />
        );
      case STEPS.REGISTER_AS_LOYALTY_MEMBER:
        return (
          <RegistrationStep
            phoneNumber={phoneNumber}
            venueSetSettings={venueSetSettings}
            hasErrorMessage={hasErrorMessage}
            errorMessage={errorMessage}
            signupToVenueLoyaltyHandler={signupToVenueLoyaltyHandler}
            goBackToPhoneNumberStep={goBackToPhoneNumberStep}
          />
        );
      case STEPS.SEND_PROFILE_LINK:
        return (
          <SendProfileLinkStep
            phoneNumber={phoneNumber}
            venueSetSettings={venueSetSettings}
            hasSentProfileLink={hasSentProfileLink}
            sendProfileLinkHandler={sendProfileLinkHandler}
            hasErrorMessage={hasErrorMessage}
            errorMessage={errorMessage}
            isSendProfileButtonEnabled={isSendProfileButtonEnabled}
            setResendProfileButtonFlag={setResendProfileButtonFlag}
            goBackToPhoneNumberStep={goBackToPhoneNumberStep}
          />
        );
      case STEPS.REGISTRATION_SUCCESSFUL:
        return <RegistrationSuccessStep venueSetSettings={venueSetSettings} />;
      case STEPS.VENUE_NOT_FOUND:
        return <PhoneNumberStep hasErrorMessage={hasErrorMessage} errorMessage={errorMessage} />;
      default:
        return null;
    }
  };

  // Wait until venue data has been loaded
  if (!hasLoaded) return <LoadingPage />;
  // Venue is blocked due to payment or has no active subscriptions
  if (isBlocked) {
    return (
      <SuspensionScreen
        venueName={venueSetSettings.venueName}
        languages={venueSetSettings.languages}
      />
    );
  }

  return (
    <>
      <AppHelmet
        title={`${t('loyalty')} | Guest Sign Up`}
        description={`${t('loyalty')} | Guest Sign Up`}
      />
      <div css={Styles.wrapper}>
        <div css={Styles.pageWrapper}>
          <div css={Styles.container}>
            {renderComponent()}
            <div
              css={Styles.fixedToBottom({
                hasLanguagePopover: venueSetSettings?.languages?.length > 1,
              })}
            >
              <LanguagePopover bordered guestLanguages={venueSetSettings?.languages} />
              <div css={Styles.poweredByUMAIWrapper}>
                <PoweredByUMAI />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GuestSignup.propTypes = {
  hasLoaded: PropTypes.bool,
  step: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  venueSetSettings: PropTypes.object.isRequired,
  hasSentProfileLink: PropTypes.bool.isRequired,
  isSendProfileButtonEnabled: PropTypes.bool.isRequired,
  hasErrorMessage: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  phoneNumberSearchHandler: PropTypes.func.isRequired,
  sendProfileLinkHandler: PropTypes.func.isRequired,
  setResendProfileButtonFlag: PropTypes.func.isRequired,
  signupToVenueLoyaltyHandler: PropTypes.func.isRequired,
  goBackToPhoneNumberStep: PropTypes.func.isRequired,
  isBlocked: PropTypes.bool,
};

export default () => {
  const props = useWrapper();
  return <GuestSignup {...props} />;
};
