import { useContext } from 'react';
import { PartnerContext } from 'contexts/PartnerProvider';

const useElementAuthorization = (allowedPermission) => {
  const {
    partner: { currentUser },
  } = useContext(PartnerContext);

  if (!currentUser) {
    return false;
  }

  if (currentUser.isOwner) {
    return true;
  }

  return currentUser.permissions.includes(allowedPermission);
};

export default useElementAuthorization;
