import { css } from '@emotion/react';

// input container styles go below
export const inputContainer = css`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: -6px 0px 10px #00000014;
  padding-top: 2rem;
  body.venue-in-payment-warning & {
    padding-top: 1rem;
  }
  position: relative;
`;

export const inputContent = css`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const mobDesc = css`
  font-weight: 400;
  font-size: 1.65rem;
  padding-bottom: 0rem;
  text-align: center;
`;

export const mobileNum = css`
  font-size: 2.65rem;
  font-weight: 300;
  padding: 0;
  text-align: center;
`;

export const nameClearWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1rem;
  min-height: 3rem;
  padding: 0.5rem;
  text-align: center;
`;

export const nameWrapper = css`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  line-height: 1.2;
`;

export const nameIcon = css`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 0.875rem;
    height: 0.875rem;
    stroke: black;
  }

  padding: 0 0.25rem;
`;

export const name = css``;

export const clearWrapper = css`
  display: flex;
  align-items: center;
`;

export const clearButton = css`
  display: flex;
  align-items: center;

  appearance: none;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 0.5rem;
  line-height: 1.2;
`;

export const clearIcon = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-grey);
  border-radius: 50%;
  font-size: 1.9rem;
  font-weight: lighter;
  width: 1.5rem;
  height: 1.5rem;
  padding-bottom: 3.5px;
`;

export const clearText = css`
  padding: 0 0.5rem;
`;

export const inputFooter = css`
  min-height: 5rem;
  position: relative;
`;

export const CTA = css`
  border: none;
  outline: none;
  appearance: none;
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
  min-width: 16rem;
  min-height: 3.25rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.25rem;
`;

export const disabledCTA = css`
  ${CTA}
  background-color: var(--color-grey);
  cursor: auto;
`;

export const errorText = css`
  position: absolute;
  top: -35px;
  width: 100%;
  text-align: center;
  color: var(--color-error);
`;

const iPadBreakpoint = `
  @media (max-width: 1024px)
`;

// iPAD specific styles
export const inputContainerIPad = css`
  ${iPadBreakpoint} {
    padding-bottom: 0;
  }
`;

export const inputFooterIPad = css`
  ${iPadBreakpoint} {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }
`;

export const CTAIPad = css`
  ${iPadBreakpoint} {
    width: 100%;
    border-radius: 0;
    min-height: 5rem;
  }
`;
