import { isEmailValid } from 'util/data-gathering-helper';
import { useState } from 'react';

function useFormField(initialVal = '') {
  const [val, setVal] = useState(initialVal);
  const [isValid, setValid] = useState(true);

  function onChange(e) {
    if (e.target.name === 'email') {
      setVal(e.target.value?.toLowerCase());
      setValid(isEmailValid(e.target.value?.toLowerCase()));
    } else {
      setVal(e.target.value);
    }

    if (!e.target.value) {
      setValid(false);
    } else if (!isValid) setValid(true);
  }

  function setValidManually(bool = false) {
    setValid(bool);
  }

  return [val, onChange, isValid, setValidManually];
}

export default useFormField;
