const SUSPENSION_BACKGROUNDS = ['background-1', 'background-2', 'background-3', 'background-4'];

/**
 * Returns a random background image from the `SUSPENSION_BACKGROUNDS` array,
 * ensuring it's different from the last used background stored in localStorage.
 * Updates localStorage with the new background image.
 *
 * @returns {string} The new random background image filename.
 */
export const getRandomBackgroundImageURL = () => {
  const lastBackground = window.localStorage?.getItem('suspensionBackground');
  const availableBackgrounds = SUSPENSION_BACKGROUNDS.filter(
    (background) => background !== lastBackground
  );
  const newBackground =
    availableBackgrounds[Math.floor(Math.random() * availableBackgrounds.length)];
  const backgroundPath = `/backgrounds/${newBackground}`;

  if (window.localStorage?.setItem) {
    window.localStorage?.setItem('suspensionBackground', newBackground);
  }

  return {
    desktop: `${backgroundPath}.jpg`,
    mobile: `${backgroundPath}-mobile.jpg`,
  };
};
