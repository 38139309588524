import React from 'react';
import PropTypes from 'prop-types';
import history from 'util/router-history';
import { assignEventListener } from 'util/event-helper';
import ROUTES from 'constants/routes';
import PhoneNumberLookup from 'components/PhoneNumberLookup';
import PoweredByUMAI from 'components/PoweredByUMAI';
import LanguagePopover from 'components/LanguagePopover';
import { ReactComponent as BackArrow } from 'images/left-arrow.svg';
import { useTranslation } from 'react-i18next';
import * as Styles from './PhoneNumberStep.styles';
import HelpPopover from '../HelpPopover';

const PhoneNumberStep = (props) => {
  const {
    customer,
    gotoNextHandler,
    phoneNumberSearchHandler,
    resetDataHandler,
    venue,
    locale,
    isPhoneLoading,
    phoneNumberButtonText = '',
    transactionText = {
      title: '',
      desc: '',
      steps: [],
    },
    isCustomerRequiredBeforeNextStep = true,
  } = props;

  const [t] = useTranslation();

  const redirectTo = (path) => {
    history.push(path);
  };

  return (
    <div css={Styles.wrapper}>
      <div css={Styles.insContainer}>
        <div css={Styles.insContent}>
          <div
            id="back-button"
            css={Styles.backButton}
            {...assignEventListener(() => redirectTo(ROUTES.NAV))}
          >
            <BackArrow />
            {t('back')}
          </div>
          <div css={Styles.restoName}>{venue.name}</div>
          <div css={Styles.title}>{transactionText.title}</div>
          <div css={Styles.desc}>{transactionText.desc}</div>
          {transactionText.steps.map((step = { index: '', desc: '' }) => (
            <div key={step?.index} css={Styles.stepDescWrapper}>
              <div css={Styles.stepIndex}>{step.index}</div>
              <div css={Styles.stepDesc}>{step.desc}</div>
            </div>
          ))}
        </div>
        <div css={Styles.insFooter}>
          <div css={Styles.bottomRow}>
            <div css={Styles.poweredWrapper}>
              <PoweredByUMAI type="dark" />
            </div>
            <HelpPopover colored />
            <LanguagePopover colored bordered />
          </div>
        </div>
      </div>

      <PhoneNumberLookup
        locale={locale}
        customer={customer}
        resetNumberHandler={resetDataHandler}
        gotoNextHandler={gotoNextHandler}
        phoneNumberSearchHandler={phoneNumberSearchHandler}
        buttonText={phoneNumberButtonText}
        isPhoneLoading={isPhoneLoading}
        isCustomerRequiredBeforeNextStep={isCustomerRequiredBeforeNextStep}
      />
    </div>
  );
};

PhoneNumberStep.propTypes = {
  gotoNextHandler: PropTypes.func.isRequired,
  phoneNumberSearchHandler: PropTypes.func.isRequired,
  resetDataHandler: PropTypes.func.isRequired,
  customer: PropTypes.object,
  venue: PropTypes.shape({
    name: PropTypes.string,
  }),
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
  // history: PropTypes.object,
  isPhoneLoading: PropTypes.object,
  phoneNumberButtonText: PropTypes.string,
  isCustomerRequiredBeforeNextStep: PropTypes.bool,
  transactionText: PropTypes.object,
};

export default PhoneNumberStep;
