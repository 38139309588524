/* eslint-disable max-classes-per-file */
export const ERROR_TYPES = {
  DuplicateError: 'DuplicateError',
  UnauthorizedError: 'UnauthorizedError',
  InvalidOTPError: 'InvalidOTPError',
  ExpiredOTPError: 'ExpiredOTPError',
  InvalidTransactionError: 'InvalidTransactionError',
  EmailAlreadyExistsError: 'EmailAlreadyExistsError',
  PhoneAlreadyExistsError: 'PhoneAlreadyExistsError',
  GuestNotFoundError: 'GuestNotFoundError',
  TooMuchSmsLinkRequestError: 'TooMuchSmsLinkRequestError',
  VenueNotFoundError: 'VenueNotFoundError',
  GenericError: 'GenericError',
};

export class DuplicateError extends Error {
  constructor(message) {
    super(message);
    this.name = ERROR_TYPES.DuplicateError;
  }
}

export class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = ERROR_TYPES.UnauthorizedError;
  }
}

export class InvalidOTPError extends Error {
  constructor(message) {
    super(message);
    this.name = ERROR_TYPES.InvalidOTPError;
  }
}

export class ExpiredOTPError extends Error {
  constructor(message) {
    super(message);
    this.name = ERROR_TYPES.ExpiredOTPError;
  }
}

export class InvalidTransactionError extends Error {
  constructor(message) {
    super(message);
    this.name = ERROR_TYPES.InvalidTransactionError;
  }
}

export class EmailAlreadyExistsError extends Error {
  constructor(message) {
    super(message);
    this.name = ERROR_TYPES.EmailAlreadyExistsError;
  }
}

export class PhoneAlreadyExistsError extends Error {
  constructor(message) {
    super(message);
    this.name = ERROR_TYPES.PhoneAlreadyExistsError;
  }
}
export class GuestNotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = ERROR_TYPES.GuestNotFoundError;
  }
}

export class TooMuchSmsLinkRequestError extends Error {
  constructor(message) {
    super(message);
    this.name = ERROR_TYPES.TooMuchSmsLinkRequestError;
  }
}

export class VenueNotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = ERROR_TYPES.VenueNotFoundError;
  }
}

export class GenericError extends Error {
  constructor(message) {
    super(message);
    this.name = ERROR_TYPES.GenericError;
  }
}
