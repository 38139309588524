import { css } from '@emotion/react';

export {
  pageWrapper,
  pageHeader,
  container,
  poweredByUMAIWrapper,
  fixedToBottom,
} from '../../GuestSignup.styles';

const leadingTextSize = css`
  font-size: 1.35rem;
`;

export const pageHeadingText = css`
  color: var(--text-primary);
  font-size: 1.6rem;
  font-weight: 500;
  margin: 1.5rem 1rem;
  padding: 1rem;
  line-height: 1.3;
`;

export const pageSubheadingText = css`
  color: var(--text-primary);
  ${leadingTextSize}
  font-weight: normal;
  margin: 1.5rem 1rem;
  padding: 1rem;
  line-height: 1.3;
`;
