import { getVenueSlugFromURL } from 'util/url';
import { cleanPhoneNumber } from 'util/number-helper';
import { ERROR_TYPES } from 'util/errors';
import { getPhonePrefixByCountryISO, getCountryIsoCode } from 'util/locale-helper';
import { DEFAULT_LANGUAGE } from 'constants/app-env';
import { ThemeContext } from 'contexts/ThemeProvider';
import {
  searchPhoneNumber as searchPhoneNumberGET,
  sendProfileLink as sendProfileLinkGET,
  signupAsLoyaltyMember as signupAsLoyaltyMemberPOST,
  fetchVenueSettings as fetchVenueSettingsGET,
} from 'api/signup';
import React, { useContext, useEffect } from 'react';
import { setVenueTimeZone } from 'services/venue';
import { PartnerContext } from 'contexts/PartnerProvider';

const ACTIONS = {
  SET_GUEST: 'SET_GUEST',
  SET_SMS_LINK_SENDING_FLAG: 'SET_SMS_LINK_SENDING_FLAG',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_SEND_PROFILE_LINK_BUTTON_STATUS_FLAG: 'SET_SEND_PROFILE_LINK_BUTTON_STATUS_FLAG',
  SET_VENUE_SET_SETTINGS: 'SET_VENUE_SET_SETTINGS',
  GOTO_STEP: 'GOTO_STEP',
};

export const STEPS = {
  ENTER_PHONE_NUMBER: 'ENTER_PHONE_NUMBER',
  SEND_PROFILE_LINK: 'SEND_PROFILE_LINK',
  REGISTER_AS_LOYALTY_MEMBER: 'REGISTER_AS_LOYALTY_MEMBER',
  REGISTRATION_SUCCESSFUL: 'REGISTRATION_SUCCESSFUL',
  VENUE_NOT_FOUND: 'VENUE_NOT_FOUND',
};

const initialState = {
  hasLoaded: false,
  step: STEPS.ENTER_PHONE_NUMBER,
  phoneNumber: '60',
  guest: {},
  hasSentProfileLink: false,
  isSendProfileButtonEnabled: true,
  hasErrorMessage: false,
  errorMessage: '',
  venueSetSettings: {
    venueName: '',
    isLoyaltyProgramEnabled: false,
    loyaltyDataGatheringSetting: {},
    languages: [],
    defaultLanguage: DEFAULT_LANGUAGE,
    countryIsoCode: '',
  },
  isBlocked: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_GUEST: {
      const prevState = { ...state };
      const { guest = {}, STEP: step, ...rest } = action.payload;
      const updatedGuest = {
        ...prevState.guest,
        ...guest,
      };
      return {
        ...prevState,
        guest: updatedGuest,
        step,
        hasErrorMessage: false,
        hasSentProfileLink: false,
        isSendProfileButtonEnabled: true,
        ...rest,
      };
    }
    case ACTIONS.SET_SMS_LINK_SENDING_FLAG: {
      const prevState = { ...state };
      return {
        ...prevState,
        hasSentProfileLink: true,
        hasErrorMessage: false,
        isSendProfileButtonEnabled: false,
      };
    }
    case ACTIONS.SET_ERROR_MESSAGE: {
      const prevState = { ...state };
      return {
        ...prevState,
        ...action.payload,
      };
    }
    case ACTIONS.SET_SEND_PROFILE_LINK_BUTTON_STATUS_FLAG: {
      const prevState = { ...state };
      return {
        ...prevState,
        ...action.payload,
      };
    }
    case ACTIONS.SET_VENUE_SET_SETTINGS: {
      const prevState = { ...state };
      return {
        ...prevState,
        ...action.payload,
      };
    }
    case ACTIONS.GOTO_STEP: {
      const prevState = { ...state };
      const { STEP: step, ...rest } = action.payload;
      return { ...prevState, step, ...rest };
    }
    default: {
      throw new Error('Invalid action dispatched');
    }
  }
};

const useWrapper = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { updateThemeColors } = useContext(ThemeContext);
  const { updateLanguages } = useContext(PartnerContext);

  const {
    hasLoaded,
    step,
    phoneNumber,
    guest = {},
    hasSentProfileLink,
    isSendProfileButtonEnabled,
    hasErrorMessage,
    errorMessage,
    venueSetSettings,
    isBlocked,
  } = state;

  useEffect(() => {
    const venueSlug = getVenueSlugFromURL();
    fetchVenueSettingsGET({ venueSlug })
      .then((response) => {
        const {
          name: responseVenueName,
          isLoyaltyProgramEnabled,
          loyaltyDataGatheringSetting,
          currencyCode,
          color,
          languages,
          defaultLanguage,
          address,
          isBlockedDueToPayment,
          hasActiveSubscriptions,
        } = response;

        if (color?.enabled) {
          updateThemeColors(color);
        }
        setVenueTimeZone(response);
        updateLanguages(languages);
        dispatch({
          type: ACTIONS.SET_VENUE_SET_SETTINGS,
          payload: {
            hasLoaded: true,
            phoneNumber: getPhonePrefixByCountryISO(
              getCountryIsoCode(address?.country, currencyCode)
            ),
            venueSetSettings: {
              venueName: responseVenueName,
              isLoyaltyProgramEnabled,
              loyaltyDataGatheringSetting,
              languages,
              defaultLanguage,
              countryIsoCode: getCountryIsoCode(address?.country, currencyCode),
            },
            isBlocked: isBlockedDueToPayment || !hasActiveSubscriptions,
          },
        });

        // if venue does not enable loyalty system, show error message
        if (!isLoyaltyProgramEnabled) {
          dispatch({
            type: ACTIONS.SET_ERROR_MESSAGE,
            payload: {
              hasLoaded: true,
              hasErrorMessage: true,
              errorMessage: 'This venue does not have a loyalty system',
            },
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: ACTIONS.SET_ERROR_MESSAGE,
          payload: {
            hasLoaded: true,
            hasErrorMessage: true,
            errorMessage: e.message || '',
          },
        });
      });
    // eslint-disable-next-line
  }, []);

  const phoneNumberSearchHandler = (phoneNum) => {
    const venueSlug = getVenueSlugFromURL();
    phoneNum = cleanPhoneNumber(phoneNum);

    searchPhoneNumberGET({ venueSlug, phoneNumber: phoneNum })
      .then((response) => {
        const { tokenId } = response;
        dispatch({
          type: ACTIONS.SET_GUEST,
          payload: {
            guest: { tokenId },
            STEP: STEPS.SEND_PROFILE_LINK,
            phoneNumber: phoneNum,
          },
        });
      })
      .catch((e) => {
        if (e.name === ERROR_TYPES.GuestNotFoundError) {
          dispatch({
            type: ACTIONS.GOTO_STEP,
            payload: {
              STEP: STEPS.REGISTER_AS_LOYALTY_MEMBER,
              phoneNumber: phoneNum,
            },
          });
        } else {
          dispatch({
            type: ACTIONS.SET_ERROR_MESSAGE,
            payload: {
              hasErrorMessage: true,
              errorMessage: e.message || '',
            },
          });
        }
      });
  };

  const sendProfileLinkHandler = () => {
    const { tokenId } = guest;
    const venueSlug = getVenueSlugFromURL();

    sendProfileLinkGET({ venueSlug, tokenId })
      .then(() => {
        dispatch({
          type: ACTIONS.SET_SMS_LINK_SENDING_FLAG,
          payload: {},
        });
      })
      .catch((e) => {
        if (e.name === ERROR_TYPES.TooMuchSmsLinkRequestError) {
          dispatch({
            type: ACTIONS.SET_ERROR_MESSAGE,
            payload: {
              hasErrorMessage: true,
              errorMessage: e.message || 'Your profile link has been sent a few minutes ago.',
            },
          });
        } else {
          dispatch({
            type: ACTIONS.SET_ERROR_MESSAGE,
            payload: {
              hasErrorMessage: true,
              errorMessage: e.message || '',
            },
          });
          console.error(e);
        }
      });
  };

  const setResendProfileButtonFlag = () => {
    dispatch({
      type: ACTIONS.SET_SEND_PROFILE_LINK_BUTTON_STATUS_FLAG,
      payload: { isSendProfileButtonEnabled: true },
    });
  };

  const signupToVenueLoyaltyHandler = (
    { firstName = '', lastName = '', gender = '', email = '', birthday, locale },
    setAllFieldsAreValid = () => {}
  ) => {
    const venueSlug = getVenueSlugFromURL();
    const requestPayload = {
      guest: {
        first_name: firstName,
        last_name: lastName,
        phone: cleanPhoneNumber(phoneNumber),
        birthday,
        gender,
        email,
        locale,
      },
    };
    setAllFieldsAreValid(false); // disable the register button to avoid multiple clicks
    signupAsLoyaltyMemberPOST({ venueSlug, requestPayload })
      .then(() => {
        dispatch({
          type: ACTIONS.GOTO_STEP,
          payload: {
            STEP: STEPS.REGISTRATION_SUCCESSFUL,
          },
        });
      })
      .catch((e) => {
        setAllFieldsAreValid(true);
        dispatch({
          type: ACTIONS.SET_ERROR_MESSAGE,
          payload: {
            hasErrorMessage: true,
            errorMessage: e.message || '',
          },
        });
        console.error(e);
      });
  };

  const goBackToPhoneNumberStep = () => {
    dispatch({
      type: ACTIONS.GOTO_STEP,
      payload: {
        STEP: STEPS.ENTER_PHONE_NUMBER,
        hasErrorMessage: false,
        errorMessage: '',
      },
    });
  };

  return {
    hasLoaded,
    step,
    phoneNumber,
    venueSetSettings,
    hasSentProfileLink,
    isSendProfileButtonEnabled,
    hasErrorMessage,
    errorMessage,
    phoneNumberSearchHandler,
    sendProfileLinkHandler,
    setResendProfileButtonFlag,
    signupToVenueLoyaltyHandler,
    goBackToPhoneNumberStep,
    isBlocked,
  };
};

export default useWrapper;
