/* eslint-disable no-unused-vars */
import { UnauthorizedError } from 'util/errors';
import { hasError } from 'util/error-helper';
import { noteEndpoints } from './endpoints';
import request from './request';
import { getDefaultAuthHeaders } from './api-util';

export const addNoteToTransaction = async ({
  token,
  currentVenueId,
  transactionId,
  note = '',
} = {}) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');

  try {
    const url = noteEndpoints.addNotePOST({ currentVenueId, transactionId });
    const defaultAuthHeaders = getDefaultAuthHeaders(token);
    const response = await request(url, {
      method: 'PATCH',
      headers: {
        ...defaultAuthHeaders,
      },
      body: JSON.stringify({
        loyalty_transaction: {
          note,
        },
      }),
    });

    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (hasError(resData, 'Authorization token is invalid')) {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    } else if (e?.response?.status === 404) return {};
    throw e;
  }
};
