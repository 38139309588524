import React from 'react';
import PropTypes from 'prop-types';
import { RemoveDigit } from 'components/Icons';
import { assignEventListener } from 'util/event-helper';
import * as Styles from './Numpad.styles';

const Numpad = (props) => {
  const { addDigitHandler, removeDigitHandler, type = 'phone', isPopup = false } = props;

  let numStyles = [Styles.num];
  let deleteBtnStyles = [Styles.deleteBtnWrapper];
  const numColStyles = !isPopup ? Styles.numCol : Styles.numColPopup;
  if (isPopup) {
    numStyles = [...numStyles, [Styles.numPopup]];
    deleteBtnStyles = [...numStyles, [Styles.deleteBtnWrapper, Styles.deleteBtnWrapperPopup]];
  }

  const renderBottomLeftButton = () => {
    switch (type) {
      case 'phone': {
        return (
          <div css={numColStyles} {...assignEventListener(() => addDigitHandler('+'))}>
            <div css={[...numStyles, Styles.specialChar]}>+</div>
          </div>
        );
      }
      case 'amount': {
        return (
          <div css={numColStyles} {...assignEventListener(() => addDigitHandler('.'))}>
            <div css={[...numStyles, Styles.specialChar]}>.</div>
          </div>
        );
      }
      case 'otp': {
        return (
          <div css={numColStyles}>
            <div css={Styles.numDummy} />
          </div>
        );
      }
      default: {
        return (
          <div css={numColStyles}>
            <div css={Styles.numDummy} />
          </div>
        );
      }
    }
  };

  return (
    <div css={[Styles.numPad, isPopup ? Styles.numPadPopup : '']}>
      <div css={Styles.numRow}>
        <div
          id="num-1-button"
          css={numColStyles}
          {...assignEventListener(() => addDigitHandler(1))}
        >
          <div css={numStyles}>1</div>
        </div>
        <div
          id="num-2-button"
          css={numColStyles}
          {...assignEventListener(() => addDigitHandler(2))}
        >
          <div css={numStyles}>2</div>
        </div>
        <div
          id="num-3-button"
          css={numColStyles}
          {...assignEventListener(() => addDigitHandler(3))}
        >
          <div css={numStyles}>3</div>
        </div>
      </div>
      <div css={Styles.numRow}>
        <div
          id="num-4-button"
          css={numColStyles}
          {...assignEventListener(() => addDigitHandler(4))}
        >
          <div css={numStyles}>4</div>
        </div>
        <div
          id="num-5-button"
          css={numColStyles}
          {...assignEventListener(() => addDigitHandler(5))}
        >
          <div css={numStyles}>5</div>
        </div>
        <div
          id="num-6-button"
          css={numColStyles}
          {...assignEventListener(() => addDigitHandler(6))}
        >
          <div css={numStyles}>6</div>
        </div>
      </div>
      <div css={Styles.numRow}>
        <div
          id="num-7-button"
          css={numColStyles}
          {...assignEventListener(() => addDigitHandler(7))}
        >
          <div css={numStyles}>7</div>
        </div>
        <div
          id="num-8-button"
          css={numColStyles}
          {...assignEventListener(() => addDigitHandler(8))}
        >
          <div css={numStyles}>8</div>
        </div>
        <div
          id="num-9-button"
          css={numColStyles}
          {...assignEventListener(() => addDigitHandler(9))}
        >
          <div css={numStyles}>9</div>
        </div>
      </div>
      <div css={Styles.numRow}>
        {renderBottomLeftButton()}
        <div
          id="num-0-button"
          css={numColStyles}
          {...assignEventListener(() => addDigitHandler(0))}
        >
          <div css={numStyles}>0</div>
        </div>
        <div
          id="remove-num-button"
          css={numColStyles}
          {...assignEventListener(() => removeDigitHandler())}
        >
          <div css={deleteBtnStyles}>
            <RemoveDigit />
          </div>
        </div>
      </div>
    </div>
  );
};

Numpad.propTypes = {
  addDigitHandler: PropTypes.func.isRequired,
  removeDigitHandler: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['phone', 'amount', 'otp']),
  isPopup: PropTypes.bool,
};

export default Numpad;
