import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './ProgressBar.styles';

const Filler = ({ percentage }) => (
  <>
    <div css={Styles.filler} style={{ width: `${Math.min(100, Math.max(0, percentage))}%` }}></div>
    <div css={Styles.dot}></div>
  </>
);

const ProgressBar = ({ percentage }) => (
  <div css={Styles.progressBar}>
    <Filler percentage={percentage} />
  </div>
);

Filler.propTypes = {
  percentage: PropTypes.string.isRequired,
};

ProgressBar.propTypes = {
  percentage: PropTypes.string.isRequired,
};

export default ProgressBar;
