import React from 'react';

const Resend = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.968"
    height="19.965"
    viewBox="0 0 18.968 19.965"
  >
    <path
      d="M18.835 8.8a.737.737 0 1 0-1.456.234v.013a8.117 8.117 0 1 1-16 2.742 8.18 8.18 0 0 1 6.645-9.443 8.071 8.071 0 0 1 7.343 2.543L12.1 5.987a.745.745 0 0 0-.467.94.736.736 0 0 0 .932.471l4.425-1.487a.743.743 0 0 0 .5-.706V.744a.737.737 0 1 0-1.474 0V3.46a9.424 9.424 0 0 0-13.404.34 9.631 9.631 0 0 0 .336 13.53 9.424 9.424 0 0 0 13.408-.339A9.627 9.627 0 0 0 18.835 8.8z"
      data-name="resend icon"
    />
  </svg>
);

export default Resend;
