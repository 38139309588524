import { css } from '@emotion/react';

export const pageWrapper = css`
  text-align: center;
`;

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 2rem;
`;

export const title = css`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const tableWrapper = css`
  // height: 100%;
  // overflow-y: auto;
`;

export const table = css`
  margin-top: 1rem;
  // min-height: 100%;

  thead {
    background-color: var(--color-grey);
  }

  td,
  th {
    border: none;
    padding: 0.5rem 1rem;
    margin: 0.25rem 0;
    font-size: 0.875rem;
  }
`;

export const nameTD = css`
  display: flex;
  position: relative;
  padding-left: 1.5rem !important;
  align-items: center;
`;
export const phoneTD = css``;

export const actionTD = css`
  line-height: 1.2;
`;
export const dateTD = css`
  line-height: 1.2;
`;
export const statusTD = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
`;

const statusbg = css`
  padding: 0.125rem 0.5rem;
  border-radius: 5px;
`;
export const statusSuccess = css`
  ${statusbg};
  background-color: #b3d985;
`;
export const statusFailed = css`
  ${statusbg};
  background-color: #e39b97;
`;
export const statusPending = css`
  ${statusbg};
  background-color: #ebebeb;
`;

export const notesTrigger = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;

  svg {
    margin-right: 0.5rem;
  }
`;

export const triggerWrapperCss = css`
  display: flex;
  margin-top: -1px;
  position: relative;
`;

export const noteDate = css`
  margin-top: 0.5rem;
  font-size: 15px;
`;

export const bottomRow = css`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem;
  width: 100%;
  position: relative;
`;

export const appVersion = css`
  font-size: 0.75rem;
  opacity: 0.8;
  padding: 0 1rem;
`;

export const fixedToBottom = css`
  margin-top: auto;
`;
