import 'whatwg-fetch';
import humps from 'humps';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
const parseJSON = (response) => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
};

const transformJSON = (json) => {
  if (!json) {
    return null;
  }
  return humps.camelizeKeys(json);
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = (response) => {
  if (!response.ok) {
    console.error(response);
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }

  return response;
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
const request = (url, options) =>
  fetch(url, options).then(checkStatus).then(parseJSON).then(transformJSON);

export default request;
