import { css } from '@emotion/react';
import { breakpoints } from '@styles/media-queries';

export const pageWrapper = css`
  text-align: center;
`;

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  body.venue-in-payment-warning & {
    min-height: calc(100vh - var(--payment-warning-height));
  }
`;

export const topRow = css`
  flex: 1 0 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
  position: relative;
`;

export const logoWrapper = css`
  flex: 1;
  font-size: 1.5rem;
  font-weight: 300;
  font-weight: 200;
  letter-spacing: 2px;
  color: white;
`;

export const bottomRow = css`
  flex: 1 1 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 2rem 1rem;
  width: 100%;
  position: relative;
`;

export const bottomLang = css`
  position: absolute;
  left: 150px;
  ${breakpoints.smallOnly} {
    left: 80px;
  }
  z-index: 2;

  body.venue-access-blocked & {
    display: none;
  }
`;

export const powerWrapper = css`
  position: absolute;
  bottom: 1rem;
`;

export const logoutWrapper = css`
  margin-bottom: 0.25rem;
  color: white;
  border-bottom: 1.25px solid currentColor;

  &:hover {
    color: var(--color-primary);
  }

  body.venue-access-blocked & {
    display: none;
  }
`;

export const venueDropdownWrapper = css`
  position: absolute;
  right: 0;
  top: 0;
  margin: 1rem 1rem 0 0;
  height: 2rem;

  ${breakpoints.smallDown} {
    margin: 0.5rem 0.5rem 0 0;
  }

  body.venue-access-blocked & {
    display: none;
  }
`;

export const appVersion = css`
  color: var(--text-white);
  font-size: 0.75rem;
  opacity: 0.8;
`;
