import React from 'react';
import PropTypes from 'prop-types';

const RedeemCredit = ({ css }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" css={css}>
    <g id="check_icon" data-name="check icon" transform="translate(3.705 1)">
      <g id="Oval" className="cls-1">
        <path
          d="M13 25.25a12.17 12.17 0 0 1-8.662-3.588A12.17 12.17 0 0 1 .75 13a12.17 12.17 0 0 1 3.588-8.662A12.17 12.17 0 0 1 13 .75a12.17 12.17 0 0 1 8.662 3.588A12.17 12.17 0 0 1 25.25 13a12.17 12.17 0 0 1-3.588 8.662A12.17 12.17 0 0 1 13 25.25z"
          className="cls-3"
          transform="translate(-3.705 -1)"
        />
        <path
          d="M13 1.5c-3.072 0-5.96 1.196-8.132 3.368A11.425 11.425 0 0 0 1.5 13c0 3.072 1.196 5.96 3.368 8.132A11.425 11.425 0 0 0 13 24.5c3.072 0 5.96-1.196 8.132-3.368A11.425 11.425 0 0 0 24.5 13c0-3.072-1.196-5.96-3.368-8.132A11.425 11.425 0 0 0 13 1.5M13 0c7.18 0 13 5.82 13 13s-5.82 13-13 13S0 20.18 0 13 5.82 0 13 0z"
          className="cls-4"
          transform="translate(-3.705 -1)"
        />
      </g>
      <path
        id="Path"
        d="M9.854.15a.493.493 0 0 0-.707 0L3.156 6.267.854 3.916a.493.493 0 0 0-.707 0 .518.518 0 0 0 0 .722L2.8 7.35a.493.493 0 0 0 .707 0L9.854.872a.518.518 0 0 0 0-.722z"
        className="cls-2"
        transform="translate(4.295 8.5)"
      />
    </g>
  </svg>
);

RedeemCredit.propTypes = {
  css: PropTypes.object,
};
export default RedeemCredit;
