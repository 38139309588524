import { css } from '@emotion/react';

export const wrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;

  .error-boundary-box {
    max-height: 85vh;
    width: 600px;
    @media screen and (max-width: 672px) {
      width: 95%;
    }
    background: #fff;
    border-radius: 8px;
    box-shadow: 3px 3px 8px 3px #0000002f;
    padding: 1rem;
    color: #000;
    overflow: auto;

    // used for confetti on hover on reload of chunkload box
    &.no-overflow {
      overflow: unset;
    }

    .title {
      font-size: 1.5rem;
      .emoji {
        font-size: 1.7rem;
      }
      svg {
        vertical-align: text-bottom;
      }
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .description {
      margin-bottom: 0.5rem;
      font-weight: 400;
      font-size: 1rem;
    }

    .reload-button {
      font-size: 1rem;
      margin-top: 1.25rem;
      display: flex;
      text-align: center;
      justify-content: center;
      align-content: center;

      button {
        cursor: pointer;
        border: 0;
        background-color: #169a89;
        color: #fff;
        border-radius: 8px;
        height: 44px;
        width: 200px;
      }
    }

    .link-button {
      display: inline;
      color: #1f8fe1;
      text-decoration: none;
      margin: 0;
      padding: 0;
      border: 0;
      background-color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }

    .bold {
      font-weight: 500;
    }

    .fit-content {
      width: fit-content;
    }

    .troubleshoot {
      font-size: 1rem;

      .troubleshoot-title {
        font-size: 1.25rem;
        font-weight: 500;
        padding: 0.5rem 0;
      }

      .troubleshoot-description {
        margin-bottom: 1rem;
      }

      .troubleshoot-list {
        margin-top: 1rem;
        list-style: inside;
        li {
          margin-bottom: 0.75rem;
          text-align: start;
        }
      }
    }

    .error-help-links {
      padding: 1rem 0;
    }
  }
`;
