import { css } from '@emotion/react';

export const wrapper = css`
  min-height: calc(100vh - (3rem * 2) - (1.5rem * 2));
  display: flex;
  flex-wrap: wrap;
  > div {
    flex: 1;
  }
`;

export const contentContainer = css`
  background: lighten(lightgreen, 20%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const title = css`
  padding: 0.5rem 0;
  font-weight: bold;
  font-size: 1.375rem;
`;

export const otpWrapper = css`
  display: flex;
  margin: 0 0 0.5rem;
`;

const digitWobble = css`
  @keyframes digit-wobble {
    25% {
      transform: translateY(-4px);
    }
    50% {
      transform: translateY(0px);
    }
    75% {
      transform: translateY(-1px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

export const otpDigit = css`
  margin: 0 1rem;
  width: 3rem;
  height: 3rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid var(--color-grey);
  color: var(--color-grey);
  // font-weight: bold;
  font-size: 1.75rem;
`;

export const otpDigitNumber = css`
  ${digitWobble};
  animation-name: digit-wobble;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;

  border-bottom: 2px solid var(--color-primary);
  color: var(--color-primary);
`;

export const otpDigitPlaceholder = css``;

export const otpDigitError = css`
  border-bottom: 2px solid var(--color-error);
  color: var(--color-error);
`;

export const CTA = css`
  border: none;
  outline: none;
  appearance: none;
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
  min-width: 16rem;
  min-height: 3.25rem;
  border-radius: 5px;
  cursor: pointer;
`;

export const disabledCTA = css`
  ${CTA}
  background-color: var(--color-grey);
  cursor: auto;
`;

export const timer = css`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-size: 1.15rem;

  svg {
    margin-top: -1px;
    margin-right: 0.5rem;
  }
`;

export const resendBtn = css`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const errMsg = css`
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-error);
  min-height: 1.5rem;
`;

export const errMsgPlaceholder = css`
  height: 1.5rem;
`;

const baseOverlayStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 5px;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.75);
`;

export const loadingOverlay = css`
  ${baseOverlayStyles}
`;

export const loadingOverlayHidden = css`
  ${baseOverlayStyles}
  display: none;
`;

export const usePasswordLink = css`
  color: var(--color-primary);
  margin-top: 1rem;
  text-decoration: underline;
  cursor: pointer;
`;
