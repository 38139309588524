import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { tint, getContrast } from 'polished';
import { PartnerContext } from 'contexts/PartnerProvider';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { defaultTheme } from '@styles/theme';

const getCurrentVenueColor = (partner) => {
  if (partner?.currentVenueId) {
    const { color } =
      (partner.venues || []).find(
        ({ id }) => parseInt(id, 10) === parseInt(partner.currentVenueId, 10)
      ) || {};

    // Make sure the selected color is readable on a white background
    if (color?.bodyColor && getContrast('#fff', color.bodyColor) > 2) {
      return color;
    }
  }
  // The default colors will be used
  return null;
};

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const { partner } = useContext(PartnerContext);
  const [theme, setTheme] = useState(defaultTheme);

  const updateThemeColors = (color = {}) => {
    if (!color?.bodyColor) return;
    const themeColors = {
      primary: color.bodyColor,
      alternate: tint(0.85, color.bodyColor),
    };
    setTheme((t) => ({ ...t, colors: { ...t.colors, ...themeColors } }));
  };

  useEffect(() => {
    const venueColor = getCurrentVenueColor(partner);
    if (venueColor) {
      updateThemeColors(venueColor);
    } else {
      // Use default theme when no colors are set for the selected venue
      setTheme(defaultTheme);
    }
    // eslint-disable-next-line
  }, [partner?.currentVenueId]);

  return (
    // eslint-disable-next-line
    <ThemeContext.Provider value={{ theme, updateThemeColors }}>
      <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ThemeContext };
export default ThemeProvider;
