import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ERROR_TYPES } from 'util/errors';
import { getStepFromURL } from 'util/url';
import history from 'util/router-history';
import { getFieldFormattedDate } from 'util/data-gathering-helper';
import DATA_CAPTURE_FLOWS from 'constants/data-capture-flows';
import FIELD_OPTIONS from 'constants/data-field-options';
import { PartnerContext } from 'contexts/PartnerProvider';
import { AppContext } from 'contexts/AppProvider';
import {
  searchPhoneNumDebounced,
  addCustomer as addCustomerPOST,
  updateCustomer as updateCustomerPATCH,
} from 'api/venue';
import { addNoteToTransaction as addNoteToTransactionPOST } from 'api/notes';
import { claimAmount as claimAmountPOST } from 'api/claim';
// for dev purposes only
// import xinitialState from './mock-initial-state';

export const STEPS = {
  MOBILE_NUMBER: 'MOBILE_NUMBER',
  HAND_OVER: 'HAND_OVER',
  HAND_OVER_NEW_CUSTOMER: 'HAND_OVER_NEW_CUSTOMER',
  ADD_CUSTOMER: 'ADD_CUSTOMER',
  DATA_ACQUISITION: 'DATA_ACQUISITION',
  CLAIM_LOYALTY: 'CLAIM_LOYALTY',
  CLAIM_SUCCESS: 'CLAIM_SUCCESS',
};

const ACTIONS = {
  RESET: 'RESET',
  SET_PHONE: 'SET_PHONE',
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_PHONE_REMOVE_CUSTOMER: 'SET_PHONE_REMOVE_CUSTOMER',
  SET_AMOUNT: 'SET_AMOUNT',
  GOTO_STEP: 'GOTO_STEP',
  SET_DUPLICATE_EMAIL_STATUS: 'SET_DUPLICATE_EMAIL_STATUS',
  ENABLE_EDIT_DETAILS_MODE_GOTO_DATA_ACQ_STEP: 'ENABLE_EDIT_DETAILS_MODE_GOTO_DATA_ACQ_STEP',
  SET_ERROR: 'SET_ERROR',
  IS_PHONE_LOADING: 'IS_PHONE_LOADING',
  IS_LOADING: 'IS_LOADING',
};

const initialState = {
  step: STEPS.MOBILE_NUMBER,
  customer: {
    id: 0,
    loyaltyAccountId: 0,
    isFound: false,
    phone: '',
    firstName: '',
    lastName: '',
    gender: '',
    birthday: null,
    email: '',
    balance: 0,
    totalClaimed: 0,
    totalRedeemed: 0,
    amountSpent: 0,
    amountClaimed: 0,
    lastTransactionId: 0,
    memberSince: '',
    renewalDate: '',
    hasDuplicateEmail: false,
    currentTier: {},
  },
  editDetailsMode: false,
  error: null, // error: { name: ERROR_NAME , message: ERROR_MESSAGE } it's an error object
  nextRequestId: 1,
  displayedRequestId: 0,
  isPhoneLoading: {},
  idempotencyKey: '',
  isLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.RESET:
      return initialState;
    case ACTIONS.SET_PHONE: {
      const prevState = { ...state };
      const customer = { ...prevState.customer, phone: action.payload };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_CUSTOMER: {
      const prevState = { ...state };
      const customer = {
        ...prevState.customer,
        isFound: true,
        ...action.payload,
      };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_PHONE_REMOVE_CUSTOMER: {
      const prevState = { ...state };
      const customer = { ...initialState.customer, phone: action.payload };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_AMOUNT: {
      const prevState = { ...state };
      const customer = { ...prevState.customer, ...action.payload };
      return { ...prevState, customer };
    }
    case ACTIONS.GOTO_STEP: {
      const prevState = { ...state };
      const { STEP: step, ...rest } = action.payload;
      return { ...prevState, step, ...rest };
    }
    case ACTIONS.SET_ERROR: {
      const prevState = { ...state };
      const { error, ...rest } = action.payload;
      return { ...prevState, error, ...rest };
    }
    case ACTIONS.SET_DUPLICATE_EMAIL_STATUS: {
      const prevState = { ...state };
      const { hasDuplicateEmail } = action.payload;
      const customer = { ...prevState.customer, hasDuplicateEmail };
      return { ...prevState, customer };
    }
    case ACTIONS.ENABLE_EDIT_DETAILS_MODE_GOTO_DATA_ACQ_STEP: {
      const prevState = { ...state };

      return {
        ...prevState,
        editDetailsMode: true,
        step: STEPS.DATA_ACQUISITION,
      };
    }
    case ACTIONS.IS_PHONE_LOADING: {
      const prevState = { ...state };
      const { isPhoneLoading } = action.payload;
      return {
        ...prevState,
        isPhoneLoading: { ...prevState.isPhoneLoading, ...isPhoneLoading },
      };
    }
    case ACTIONS.IS_LOADING: {
      const prevState = { ...state };
      return {
        ...prevState,
        isLoading: action.payload,
      };
    }
    default:
      throw new Error('Invalid action dispatched');
  }
};

/* Entire data control for claim steps */
const useWrapper = () => {
  const {
    partner: { token, currentVenueId, currencyDenomination, phonePrefix, venueSettings = {} },
    partner,
  } = React.useContext(PartnerContext);
  const {
    setAppState,
    app: { shouldReloadAppOnPageIdle = false, globalCustomer = {} },
  } = React.useContext(AppContext);

  const initialStateWithOverride = { ...initialState };
  initialStateWithOverride.customer = {
    ...initialState.customer,
    ...globalCustomer,
  };

  // get current step
  if (globalCustomer.phone && globalCustomer.phone.length >= 3) {
    const initialStep = getStepFromURL();
    initialStateWithOverride.step = initialStep || STEPS.MOBILE_NUMBER;
  }

  const [state, dispatch] = React.useReducer(reducer, initialStateWithOverride);
  const { step, customer, editDetailsMode, error, isPhoneLoading, idempotencyKey, isLoading } =
    state;

  const locale = {
    currencyDenomination,
    phonePrefix,
  };

  const resetDataHandler = () => {
    if (shouldReloadAppOnPageIdle) {
      // refresh page - force remove cache
      window.location.reload();
    } else {
      setAppState({ isAppIdle: true, globalCustomer: {} });
      dispatch({ type: ACTIONS.RESET });
    }
  };

  const phoneNumberSearchHandler = async (phoneNum) => {
    try {
      // To avoid race condition: we here are adding a count for each api hit
      // and saving it to local scope (requestId), so after api response we check with the local count
      initialState.nextRequestId += 1;
      const requestId = initialState.nextRequestId;

      dispatch({
        type: ACTIONS.IS_PHONE_LOADING,
        payload: {
          isPhoneLoading: { [phoneNum]: true },
        },
      });
      const data = await searchPhoneNumDebounced({
        token,
        currentVenueId,
        phone: phoneNum,
      });

      dispatch({
        type: ACTIONS.IS_PHONE_LOADING,
        payload: {
          isPhoneLoading: { [phoneNum]: false },
        },
      });

      // To avoid race condition: we here are returing (no action taken)
      // if the api requested is older then the global requestid we have in (displayedRequestId)
      // else we change the global requestId to the current requestId.
      if (requestId < initialState.displayedRequestId) return;
      initialState.displayedRequestId = requestId;

      const {
        id,
        firstName,
        lastName = '',
        phone,
        email,
        gender,
        birthday,
        loyaltyAccount: {
          id: loyaltyAccountId,
          balanceAmount,
          cashbackAmount,
          redeemedAmount,
          createdAt,
          renewalDate,
        } = {},
        currentTier = {},
      } = data;

      if ((firstName || lastName) && phone) {
        dispatch({
          type: ACTIONS.SET_CUSTOMER,
          payload: {
            id,
            loyaltyAccountId,
            firstName,
            lastName: lastName || '',
            phone,
            email,
            gender,
            birthday: birthday || '',
            balance: balanceAmount,
            totalClaimed: cashbackAmount,
            totalRedeemed: redeemedAmount,
            memberSince: getFieldFormattedDate(createdAt),
            renewalDate: getFieldFormattedDate(renewalDate),
            currentTier,
          },
        });

        setAppState({
          globalCustomer: {
            id,
            loyaltyAccountId,
            firstName,
            lastName: lastName || '',
            phone,
            email,
            gender,
            birthday: birthday || '',
            balance: balanceAmount,
            totalClaimed: cashbackAmount,
            totalRedeemed: redeemedAmount,
            memberSince: getFieldFormattedDate(createdAt),
            renewalDate: getFieldFormattedDate(renewalDate),
            currentTier,
          },
        });
      } else {
        // IF NOT FOUND - goto ADD_CUSTOMER step
        dispatch({
          type: ACTIONS.SET_PHONE_REMOVE_CUSTOMER,
          payload: phoneNum,
        });
      }
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else {
        // error scenario
        console.error('Something blew up while searching for phone number', e);
        dispatch({
          type: ACTIONS.IS_PHONE_LOADING,
          payload: {
            isPhoneLoading: { [phoneNum]: false },
          },
        });
        dispatch({
          type: ACTIONS.SET_PHONE_REMOVE_CUSTOMER,
          payload: phoneNum,
        });
      }
    }
  };

  const gotoNextStepFromPhoneNumStep = () => {
    setAppState({ isAppIdle: false });

    // check if data acquisition flow is enabled
    const { dataCaptureFlow = 'off', secondaryFieldsToCapture } = venueSettings;

    // if dataCaptureFlow is off, proceed wit normal flow
    const { isFound } = customer;
    if (isFound) {
      if (dataCaptureFlow === DATA_CAPTURE_FLOWS.COLLECTION) {
        // if dataCaptureFlow is enabled
        // check if customer details is sufficient

        // get all mandatory and optional fields from the settings
        const allFields = Object.keys(secondaryFieldsToCapture).filter(
          (key) =>
            secondaryFieldsToCapture[key] === FIELD_OPTIONS.MANDATORY ||
            secondaryFieldsToCapture[key] === FIELD_OPTIONS.OPTIONAL
        );

        let emptyFieldsCount = allFields.length;
        // if there's a mandatory field
        // create a new object by merging the mandatory fields with the customer data
        // if there is an empty field, then show the data acquisition field
        if (allFields.length) {
          const customerData = {};

          Object.keys(customer)
            .filter((key) => allFields.indexOf(key) !== -1)
            .forEach((key) => {
              // eslint-disable-next-line no-extra-boolean-cast
              if (!!customer[key]) emptyFieldsCount -= 1;
              customerData[key] = customer[key];
            });
        }

        // should we get all optional fields before showing the popup?
        if (emptyFieldsCount > 0) {
          dispatch({
            type: ACTIONS.GOTO_STEP,
            payload: { STEP: STEPS.DATA_ACQUISITION },
          });
        } else {
          dispatch({
            type: ACTIONS.GOTO_STEP,
            payload: { STEP: STEPS.HAND_OVER, idempotencyKey: uuidv4() },
          });
        }
      } else {
        dispatch({
          type: ACTIONS.GOTO_STEP,
          payload: { STEP: STEPS.HAND_OVER, idempotencyKey: uuidv4() },
        });
      }
    } else if (dataCaptureFlow === DATA_CAPTURE_FLOWS.COLLECTION) {
      // if dataCaptureFlow is set during 'collection', go to
      // data acquisition step
      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: STEPS.DATA_ACQUISITION },
      });
    } else {
      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: STEPS.ADD_CUSTOMER },
      });
    }
  };

  const addClaimHandler = async (amount) => {
    try {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: true,
      });

      const data = await claimAmountPOST({
        token,
        currentVenueId,
        loyaltyAccountId: customer.loyaltyAccountId,
        amount,
        customerId: customer.id,
        idempotencyKey,
      });

      const {
        id: lastTransactionId,
        totalSpend: amountSpent,
        amount: amountClaimed,
        guest: { loyaltyAccount: { id: loyaltyAccountId } = {} } = {},
      } = data;

      setAppState({
        globalCustomer: {
          id: customer.id || 0,
          loyaltyAccountId: customer.loyaltyAccountId || loyaltyAccountId,
          phone: customer.phone || '',
          firstName: customer.firstName || '',
          lastName: customer.lastName || '',
          gender: customer.gender || '',
          birthday: customer.birthday || null,
          email: customer.email || '',
          balance: parseFloat((Number(customer.balance) || 0) + Number(amountClaimed)).toFixed(1),
          currentTier: customer.currentTier,
        },
      });

      // claim success
      dispatch({
        type: ACTIONS.SET_AMOUNT,
        payload: {
          amountSpent,
          amountClaimed,
          lastTransactionId,
          loyaltyAccountId,
        },
      });
      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: STEPS.CLAIM_SUCCESS },
      });
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else {
        // error scenario
        console.error('Something blew up while claiming the amount', e);
      }
    } finally {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: false,
      });
    }
  };

  const addCustomerHandler = async ({ firstName, phone, ...secondaryDataToCapture }) => {
    try {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: true,
      });

      const data = await addCustomerPOST({
        token,
        currentVenueId,
        firstName,
        phone,
        secondaryDataToCapture,
      });

      const {
        id,
        firstName: fn,
        lastName: ln,
        phone: ph,
        email,
        birthday,
        gender,
        loyaltyAccount: {
          id: loyaltyAccountId,
          balanceAmount,
          cashbackAmount,
          redeemedAmount,
          createdAt,
          renewalDate,
        } = {},
        currentTier = {},
      } = data;

      setAppState({
        globalCustomer: {
          id,
          loyaltyAccountId,
          firstName: fn,
          lastName: ln,
          phone: ph,
          email,
          gender,
          birthday: birthday || '',
          balance: balanceAmount,
          totalClaimed: cashbackAmount,
          totalRedeemed: redeemedAmount,
          memberSince: getFieldFormattedDate(createdAt),
          renewalDate: getFieldFormattedDate(renewalDate),
          currentTier,
        },
      });

      dispatch({
        type: ACTIONS.SET_CUSTOMER,
        payload: {
          id,
          loyaltyAccountId,
          firstName: fn,
          lastName: ln,
          phone: ph,
          email,
          gender,
          birthday: birthday || '',
          balance: balanceAmount,
          totalClaimed: cashbackAmount,
          totalRedeemed: redeemedAmount,
          memberSince: getFieldFormattedDate(createdAt),
          renewalDate: getFieldFormattedDate(renewalDate),
          currentTier,
        },
      });

      dispatch({
        type: ACTIONS.SET_DUPLICATE_EMAIL_STATUS,
        payload: { hasDuplicateEmail: false },
      });

      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: STEPS.HAND_OVER_NEW_CUSTOMER },
      });
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else if (e.name === ERROR_TYPES.EmailAlreadyExistsError) {
        dispatch({
          type: ACTIONS.SET_DUPLICATE_EMAIL_STATUS,
          payload: { hasDuplicateEmail: true },
        });
      } else {
        // add failed
        console.error('Something blew up while adding new customer', e);
      }
    } finally {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: false,
      });
    }
  };

  const updateCustomerHandler = async ({
    customerId,
    firstName,
    phone,
    ...secondaryFieldsToCapture
  }) => {
    // check
    try {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: true,
      });

      const customerData = { firstName, phone, ...secondaryFieldsToCapture };

      const data = await updateCustomerPATCH({
        token,
        currentVenueId,
        customerId,
        customerData,
      });

      const {
        id,
        firstName: fn,
        lastName: ln,
        phone: ph,
        email,
        birthday,
        gender,
        loyaltyAccount: {
          id: loyaltyAccountId,
          balanceAmount,
          cashbackAmount,
          redeemedAmount,
          createdAt,
          renewalDate,
        } = {},
        currentTier = {},
      } = data;

      // TODO - not ideal to use multiple dispatches
      // should refactor to use a single dispatch
      dispatch({
        type: ACTIONS.SET_CUSTOMER,
        payload: {
          id,
          loyaltyAccountId,
          firstName: fn,
          lastName: ln,
          phone: ph,
          email,
          gender,
          birthday: birthday || '',
          balance: balanceAmount,
          totalClaimed: cashbackAmount,
          totalRedeemed: redeemedAmount,
          memberSince: getFieldFormattedDate(createdAt),
          renewalDate: getFieldFormattedDate(renewalDate),
          currentTier,
        },
      });

      setAppState({
        globalCustomer: {
          id,
          loyaltyAccountId,
          firstName: fn,
          lastName: ln,
          phone: ph,
          email,
          gender,
          birthday: birthday || '',
          balance: balanceAmount,
          totalClaimed: cashbackAmount,
          totalRedeemed: redeemedAmount,
          memberSince: getFieldFormattedDate(createdAt),
          renewalDate: getFieldFormattedDate(renewalDate),
          currentTier,
        },
      });

      dispatch({
        type: ACTIONS.SET_DUPLICATE_EMAIL_STATUS,
        payload: { hasDuplicateEmail: false },
      });

      // this if case is not necessary but makes the code more verbose
      if (editDetailsMode) {
        // from EDIT goto back to AMOUNT
        dispatch({
          type: ACTIONS.GOTO_STEP,
          payload: {
            STEP: STEPS.CLAIM_LOYALTY,
            editDetailsMode: false,
            error: null,
          },
        });
      } else {
        // from data acq go to AMOUNT
        dispatch({
          type: ACTIONS.GOTO_STEP,
          payload: { STEP: STEPS.CLAIM_LOYALTY, error: null },
        });
      }
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else if (e.name === ERROR_TYPES.EmailAlreadyExistsError) {
        dispatch({
          type: ACTIONS.SET_DUPLICATE_EMAIL_STATUS,
          payload: { hasDuplicateEmail: true },
        });
      } else if (e.name === ERROR_TYPES.PhoneAlreadyExistsError) {
        dispatch({
          type: ACTIONS.SET_ERROR,
          payload: { error: e },
        });
      } else {
        // add failed
        console.error('Something blew up while updating customer', e);
      }
    } finally {
      dispatch({
        type: ACTIONS.IS_LOADING,
        payload: false,
      });
    }
  };

  const handOverRedirectHandler = () => {
    // check if data acquisition flow is enabled
    const { dataCaptureFlow = 'off', secondaryFieldsToCapture } = venueSettings;

    // if dataCaptureFlow is off, proceed wit normal flow
    const { isFound } = customer;
    if (isFound) {
      if (dataCaptureFlow === DATA_CAPTURE_FLOWS.COLLECTION) {
        // if dataCaptureFlow is enabled
        // check if customer details is sufficient

        // get all mandatory fields from the settings
        const mandatoryFields = Object.keys(secondaryFieldsToCapture).filter(
          (key) => secondaryFieldsToCapture[key] === FIELD_OPTIONS.MANDATORY
        );

        let emptyFieldsCount = mandatoryFields.length;
        // if there's a mandatory field
        // create a new object by merging the mandatory fields with the customer data
        // if there is an empty field, then show the data acquisition field
        if (mandatoryFields.length) {
          const customerData = {};

          Object.keys(customer)
            .filter((key) => mandatoryFields.indexOf(key) !== -1)
            .forEach((key) => {
              // eslint-disable-next-line no-extra-boolean-cast
              if (!!customer[key]) emptyFieldsCount -= 1;
              customerData[key] = customer[key];
            });
        }

        // should we get all optional fields before showing the popup?
        if (emptyFieldsCount > 0) {
          dispatch({
            type: ACTIONS.GOTO_STEP,
            payload: { STEP: STEPS.DATA_ACQUISITION },
          });
        } else {
          dispatch({
            type: ACTIONS.GOTO_STEP,
            payload: { STEP: STEPS.CLAIM_LOYALTY },
          });
        }
      } else {
        dispatch({
          type: ACTIONS.GOTO_STEP,
          payload: { STEP: STEPS.CLAIM_LOYALTY },
        });
      }
    } else if (dataCaptureFlow === DATA_CAPTURE_FLOWS.COLLECTION) {
      // else if dataCaptureFlow is set during 'collection', go to
      // data acquisition step
      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: STEPS.DATA_ACQUISITION },
      });
    } else {
      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: STEPS.ADD_CUSTOMER },
      });
    }
  };

  const gotoEditDetailsHandler = () => {
    // GOTO EDIT STEP (DATA_ACQUISITION)
    dispatch({
      type: ACTIONS.ENABLE_EDIT_DETAILS_MODE_GOTO_DATA_ACQ_STEP,
    });
  };

  const claimSuccessRedirectHandler = () => {
    // window.location.reload();
    resetDataHandler();
  };

  const addNoteToTransactionHandler = (note) => {
    try {
      const { lastTransactionId } = customer;

      addNoteToTransactionPOST({
        token,
        currentVenueId,
        note,
        transactionId: lastTransactionId,
      });
    } catch (e) {
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push('/logout');
      } else {
        // error scenario
        console.error('Something blew up while adding notes', e);
      }
    }
  };

  return {
    step,
    partner,
    customer,
    locale,
    isLoading,
    isPhoneLoading,
    venueSettings,
    editDetailsMode,
    error,
    resetDataHandler,
    phoneNumberSearchHandler,
    gotoNextStepFromPhoneNumStep,
    addClaimHandler,
    addCustomerHandler,
    gotoEditDetailsHandler,
    handOverRedirectHandler,
    claimSuccessRedirectHandler,
    updateCustomerHandler,
    addNoteToTransactionHandler,
  };
};

export default useWrapper;
