import { css } from '@emotion/react';

export const svg = css`
  margin-right: 0.75rem;

  .a {
    fill: none;
  }
  .b {
    clip-path: url(#a);
  }
  .c {
    fill: #fff;
  }
  .d,
  .f {
    fill: var(--color-primary);
  }
  .d {
    stroke: var(--color-primary);
    stroke-miterlimit: 10;
  }
  .e,
  .f {
    stroke: none;
  }
`;
