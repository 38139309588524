import { css } from '@emotion/react';

export {
  pageWrapper,
  pageHeader,
  container,
  formWrapper,
  fieldWrapper,
  tip,
  submitButton,
  disabledSubmitButton,
  poweredByUMAIWrapper,
  errorMessageWrapper,
  fieldWrapperWithError,
} from '../../GuestSignup.styles';

const leadingTextSize = css`
  font-size: 1.35rem;
`;

export const pageHeadingText = css`
  color: var(--text-primary);
  ${leadingTextSize}
  font-weight: normal;
  margin: 1.5rem 0;
`;

export const stepsWrapper = css`
  margin: 0;
`;

export const stepDescWrapper = css`
  padding: 0.625rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.25rem;
`;

export const stepIndex = css`
  background-color: var(--color-alternate);
  color: var(--color-primary);
  font-weight: 400;
  border-radius: 50%;
  padding-bottom: 2px;
  min-width: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
`;

export const stepDesc = css`
  padding: 0 0 0 1rem;
  text-align: left;
  line-height: 1.2;
`;

export const phoneInputWrapper = css`
  #phone {
    padding-left: 35px !important;
  }
`;
