import moment from 'moment-timezone';
import {
  isProductionEnv,
  REACT_APP_INTERCOM_APP_ID,
  REACT_APP_ENVIRONMENT,
} from 'constants/app-env';
import { HELP_CHAT_INTERCOM_ID } from 'constants/app-const';
import { setIntercom } from 'util/intercom';
import { getCountryZone } from 'util/locale-helper';

export const setVenueTimeZone = (venue) => {
  const { timeZone = '', country: { countryIsoCode = 'MY' } = {} } = venue || {};
  moment.tz.setDefault(moment.tz.zone(timeZone) ? timeZone : getCountryZone(countryIsoCode));
};

export const setVenueIntercom = ({ venueId, venueName, username }) => {
  setIntercom({ tag: 'shutdown' });
  setIntercom({
    tag: 'boot',
    options: {
      hide_default_launcher: true,
      name: venueName?.replace(/\s+/g, '-') || 'Unnamed-venue',
      email: username,
      user_id: isProductionEnv() ? venueId : `${venueId}-${REACT_APP_ENVIRONMENT || 'DEV'}`,
      custom_launcher_selector: `#${HELP_CHAT_INTERCOM_ID}`,
      last_request_at: parseInt(new Date().getTime() / 1000, 10),
      // Workaround for solving intercom connection and latency issues for Vietnamese partners
      // https://forum.intercom.com/s/question/0D52G00004jhZo7SAE/why-am-i-having-issues-with-the-intercom-messenger-in-vietnam
      api_base: `https://${REACT_APP_INTERCOM_APP_ID}.intercom-messenger.com`,
    },
  });
};
