import { DEFAULT_LANGUAGE, DEFAULT_COUNTRY } from 'constants/app-env';

// Not all languages in our system are supported inside loyalty yet so we have filter only the ones available
// Returns the languages that are supported in the loyalty app
// The folders inside `public/locales` are read and added as array to `SUPPORTED_LANGUAGES` as below
// NOTE: make sure to updated the supported languages when adding a new language in public folder
const SUPPORTED_LANGUAGES = ['da', 'en', 'id', 'ru', 'pt', 'es'];

export const supportedLanguages = (languages = []) =>
  languages?.length
    ? languages.filter(({ language }) => SUPPORTED_LANGUAGES?.includes(language))
    : [{ country_code: DEFAULT_COUNTRY, language: DEFAULT_LANGUAGE }];
