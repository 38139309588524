import { css } from '@emotion/react';

export const languagePopover = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 176px;
  font-size: 1rem;
  font-weight: 400;

  .language-popover-item {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    color: #000;
    border-bottom: 1px solid #d9d9d9;
    text-transform: capitalize;

    &:hover {
      text-decoration: underline;
    }

    span {
      padding: 1rem;
    }

    :last-of-type {
      border-bottom: none;
    }
  }
`;

export const borderedLanguageSwitchButton = (colored) => css`
  border: 1px solid ${colored ? 'var(--color-primary)' : '#e4e4e4'};
  &:hover {
    ${!colored && 'border: 1px solid var(--color-primary)'};
  }
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
`;

export const languageSwitchButton = (colored, bordered, type) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
  color: ${colored ? 'var(--color-primary)' : (type === 'dark' && '#fff') || '#000'};
  margin-left: 10px;
  ${bordered ? borderedLanguageSwitchButton(colored) : ''}
  text-transform: uppercase;
  z-index: 1;
  &:hover {
    ${!colored && 'color: var(--color-primary)'};
  }
`;
