import { DEFAULT_LANGUAGE, DEFAULT_COUNTRY, ENVIRONMENT } from '@umai/common';

export const MAX_DIGITS = 18;

export const MAX_AMOUNT = 10;

export const {
  REACT_APP_INTERCOM_APP_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_ENVIRONMENT,
  REACT_APP_API_ENDPOINT,
  REACT_APP_UMAI360_URL,
} = process.env;

const API_ENDPOINT = REACT_APP_API_ENDPOINT || 'http://localhost:3000';

export const REACT_APP_API_ENDPOINT_LOYALTY_V1 = `${API_ENDPOINT}${
  process.env.REACT_APP_API_ENDPOINT_LOYALTY_V1 || '/loyalty/api/v1'
}`;

export const isProductionEnv = () => REACT_APP_ENVIRONMENT === ENVIRONMENT.PRODUCTION;

export const APPLE_REVIEW_PARTNER_ID = 4;

export { DEFAULT_LANGUAGE, DEFAULT_COUNTRY };

export default ENVIRONMENT;
