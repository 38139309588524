import { DuplicateError } from 'util/errors';
import { getMomentDate, getDateFormatter } from 'util/date-helper';
import i18n from 'services/i18n';
import { reportEndpoints } from './endpoints';
import request from './request';
import { getDefaultAuthHeaders } from './api-util';
import { showErrorMessage } from '@umai/common';

const convertDateTypeToServerFormat = getDateFormatter('YYYY/MM/DD');

export const createReport = async ({ token, currentVenueId, date, numberOfBills, totalValue }) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');

  let formattedDate;
  try {
    formattedDate = convertDateTypeToServerFormat(date);
  } catch (e) {
    throw new Error('Something blew up while parsing date into server format');
  }

  try {
    const url = reportEndpoints.createReportPOST(currentVenueId);
    const defaultAuthHeaders = getDefaultAuthHeaders(token);
    const response = await request(url, {
      method: 'POST',
      headers: {
        ...defaultAuthHeaders,
      },
      body: JSON.stringify({
        loyalty_report: {
          report_date: formattedDate,
          total_number_of_bill: Number(numberOfBills),
          total_value_of_bill: Number(totalValue),
        },
      }),
    });
    return response;
  } catch (e) {
    if (e?.response?.status === 422) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (
        resData.errors &&
        resData.errors.length === 1 &&
        resData.errors[0] === 'Report date has already been taken'
      ) {
        // already created for date - handle duplicate error
        showErrorMessage(i18n.t('report-already-created-for-date'));
        throw new DuplicateError(i18n.t('report-already-created-for-date'));
      }
    }
    throw e;
  }
};

export const fetchReports = async ({ token, currentVenueId }) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');

  const url = reportEndpoints.fetchReportsGET(currentVenueId);

  const defaultAuthHeaders = getDefaultAuthHeaders(token);
  const response = await request(url, {
    method: 'GET',
    headers: {
      ...defaultAuthHeaders,
    },
  });

  const transformedRes = response.map((r) => ({
    ...r,
    reportDate: getMomentDate(r.reportDate),
  }));

  return transformedRes;
};

export const fetchDaysReport = async ({ token, currentVenueId, date }) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');
  const url = reportEndpoints.fetchDaysReportsGET({
    currentVenueId,
    date: convertDateTypeToServerFormat(date),
  });

  const defaultAuthHeaders = getDefaultAuthHeaders(token);
  const response = await request(url, {
    method: 'GET',
    headers: {
      ...defaultAuthHeaders,
    },
  });

  const transformedRes = response.map((r) => ({
    ...r,
    reportDate: getMomentDate(r.reportDate),
  }));

  return transformedRes;
};

export const closeDay = async ({ token, currentVenueId, date }) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');
  if (!date) throw new Error(i18n.t('date-is-required-for-marking-a-day-as-closed'));

  let formattedDate;
  try {
    formattedDate = convertDateTypeToServerFormat(date);
  } catch (e) {
    throw new Error('Something blew up while parsing date into server format');
  }

  try {
    const url = reportEndpoints.closeDayPOST(currentVenueId);
    const defaultAuthHeaders = getDefaultAuthHeaders(token);
    const response = await request(url, {
      method: 'POST',
      headers: {
        ...defaultAuthHeaders,
      },
      body: JSON.stringify({
        loyalty_report: {
          report_date: formattedDate,
          status: 'closed',
        },
      }),
    });
    return response;
  } catch (e) {
    if (e?.response?.status === 422) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (
        resData.errors &&
        resData.errors.length === 1 &&
        resData.errors[0] === 'Report date has already been taken'
      ) {
        // already created for date - handle duplicate error
        throw new DuplicateError(i18n.t('report-already-created-for-date'));
      }
    }
    throw e;
  }
};
