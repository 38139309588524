import moment from 'moment-timezone';
import { isValidDate } from '@umai/scroll-input-date';
import { EMAIL_REGEX } from 'constants/regex';

export const isFirstNameValid = (firstName) => !!firstName.trim();

export const isLastNameValid = (lastName) => !!lastName.trim();

export const isGenderValid = (gender) => !!gender;

export const isBirthdayValid = (birthdayStr, formatStr) => {
  // Return true because we don't want to validate falsy or empty value,
  // otherwise error message will be shown when input field is empty.
  if (!birthdayStr || birthdayStr.trim() === '') {
    return true;
  }

  return isValidDate(birthdayStr, formatStr);
};

export const isEmailValid = (email) => EMAIL_REGEX.test(email.trim());

export const removeBirthdayYear = (date, isCollectBirthYear) =>
  isCollectBirthYear ? date : date.replace(/\/\d{4}$/, '');

export const addBirthdayYear = (date) =>
  date.split('/').length === 2 ? date.replace(/$/, '/1800') : date;

export const getFieldFormattedDate = (date) => {
  if (!date) {
    return '';
  }
  return moment(date).format('ll');
};
