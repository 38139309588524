import { isAndroid, isiPadiPodOriPhone } from 'util/device-helper';

const getWhatsAppBaseUrl = () => {
  let link = `https://wa.me/`;
  if (isAndroid() || isiPadiPodOriPhone()) {
    // use this link for android devices
    link = `whatsapp://send`;
  }
  return link;
};

const urlEncodeText = (text) => encodeURIComponent(text);

const shareTextToWhatsApp = (text) => {
  window.location.href = `${getWhatsAppBaseUrl()}?text=${urlEncodeText(text)}`;
};

const getWhatsAppClickToChatLink = (text) => `${getWhatsAppBaseUrl()}?text=${urlEncodeText(text)}`;

const shareTextViaNativeSharing = async (data, fallbackFunction) => {
  try {
    await navigator.share(data);
  } catch (err) {
    if (typeof fallbackFunction === 'function') {
      fallbackFunction();
    }
  }
};

export { shareTextToWhatsApp, getWhatsAppClickToChatLink, shareTextViaNativeSharing };
