import React from 'react';
import { MAX_DIGITS } from 'constants/app-env';

const useNumpad = (prefix = '') => {
  const [num, setNum] = React.useState(prefix);

  const addDigit = (digit) => {
    if (num.length + 1 > MAX_DIGITS) return; // + 1 for the digit
    setNum(`${num}${digit?.toString()}`);
  };

  const removeDigit = () => {
    setNum(num.slice(0, -1));
  };

  const resetNumpad = () => {
    setNum(prefix);
  };

  return {
    num,
    addDigit,
    removeDigit,
    resetNumpad,
  };
};

export default useNumpad;
