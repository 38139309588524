// Get an instance of `PhoneNumberUtil`.
import { debounceFunction } from 'util/helpers';
import { UnauthorizedError, EmailAlreadyExistsError, PhoneAlreadyExistsError } from 'util/errors';
import { DEFAULT_LANGUAGE } from 'constants/app-env';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import i18n from 'services/i18n';
import { venueEndpoints } from './endpoints';
import request from './request';
import { getDefaultAuthHeaders } from './api-util';

const searchPhoneNum = async ({ token, currentVenueId, phone }) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');

  let nationalNumber;
  const internationalNumber = phone.replace('+', '');
  try {
    const phoneNumber = parsePhoneNumber(phone);
    nationalNumber = phoneNumber.formatNational().replace(/\D/g, '');
  } catch (e) {
    console.error('An error occured while trying to parse number', e);
    nationalNumber = phone;
  }

  const url = venueEndpoints.findCustomerByPhoneGET({
    currentVenueId,
    nationalNumber,
    internationalNumber,
  });
  const defaultAuthHeaders = getDefaultAuthHeaders(token);

  try {
    const response = await request(url, {
      method: 'GET',
      headers: {
        ...defaultAuthHeaders,
      },
    });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (resData.error && resData.error === 'Authorization token is invalid') {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    } else if (e?.response?.status === 404) return {};
    throw e;
  }
};

export const searchPhoneNumDebounced = debounceFunction(searchPhoneNum, {
  onlyResolvesLast: false,
});

export const addCustomer = async ({
  token,
  currentVenueId,
  firstName,
  phone,
  secondaryDataToCapture,
}) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');
  if (!firstName || !phone)
    throw new Error(i18n.t('firstname-phone-required-for-adding-a-new-customer'));

  const { lastName, email, gender, birthday } = secondaryDataToCapture;

  const requestPayload = {
    guest: {
      first_name: firstName,
      phone,
      locale: i18n?.language || DEFAULT_LANGUAGE,
    },
  };

  // eslint-disable-next-line no-prototype-builtins
  if (secondaryDataToCapture.hasOwnProperty('lastName')) requestPayload.guest.last_name = lastName;
  // eslint-disable-next-line no-prototype-builtins
  if (secondaryDataToCapture.hasOwnProperty('email')) requestPayload.guest.email = email;
  // eslint-disable-next-line no-prototype-builtins
  if (secondaryDataToCapture.hasOwnProperty('gender')) requestPayload.guest.gender = gender;
  // eslint-disable-next-line no-prototype-builtins
  if (secondaryDataToCapture.hasOwnProperty('birthday')) requestPayload.guest.birthday = birthday;
  try {
    const url = venueEndpoints.addCustomerPOST(currentVenueId);
    const defaultAuthHeaders = getDefaultAuthHeaders(token);
    const response = await request(url, {
      method: 'POST',
      headers: {
        ...defaultAuthHeaders,
      },
      body: JSON.stringify(requestPayload),
    });
    return response;
  } catch (e) {
    if (e?.response) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (e?.response?.status === 401) {
        if (resData.error && resData.error === 'Authorization token is invalid') {
          throw new UnauthorizedError('Authorization token is invalid');
        }
      } else if (e?.response?.status === 422) {
        if (resData.errors && resData.errors.indexOf('Email has already been taken') !== -1) {
          throw new EmailAlreadyExistsError(i18n.t('email-has-already-been-taken'));
        }
      }
    }
    throw e;
  }
};

export const fetchHistory = async ({ token, currentVenueId }) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');

  try {
    const url = venueEndpoints.fetchHistoryGET(currentVenueId);
    const defaultAuthHeaders = getDefaultAuthHeaders(token);
    const response = await request(url, {
      method: 'GET',
      headers: {
        ...defaultAuthHeaders,
      },
    });

    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (resData.error && resData.error === 'Authorization token is invalid') {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    }
    throw e;
  }
};

// eslint-disable-next-line
export const fetchSettings = async ({ token, currentVenueId }) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');

  try {
    const url = venueEndpoints.fetchSettingsGET(currentVenueId);
    const defaultAuthHeaders = getDefaultAuthHeaders(token);
    const response = await request(url, {
      method: 'GET',
      headers: {
        ...defaultAuthHeaders,
      },
    });

    const { birthday, gender, email, dataCaptureFlow, lastName, tier, collectBirthYear } = response;

    return {
      dataCaptureFlow,
      secondaryFieldsToCapture: {
        lastName,
        gender,
        email,
        birthday,
        tier,
        collectBirthYear,
      },
    };
  } catch (e) {
    if (e?.response?.status === 401) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (resData.error && resData.error === 'Authorization token is invalid') {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    }
    throw e;
  }
};

export const updateCustomer = async ({ token, currentVenueId, customerId, customerData }) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');
  if (!customerId) throw new Error('customerId invalid');

  const { firstName, lastName, phone, email, gender, birthday } = customerData;

  if (!firstName) throw new Error(i18n.t('first-name-is-required-for-updating-a-customer'));

  const requestPayload = {
    guest: {
      first_name: firstName,
      locale: i18n?.language || DEFAULT_LANGUAGE,
    },
  };

  if (Object.prototype.hasOwnProperty.call(customerData, 'phone'))
    requestPayload.guest.phone = phone;
  if (Object.prototype.hasOwnProperty.call(customerData, 'lastName'))
    requestPayload.guest.last_name = lastName;
  if (Object.prototype.hasOwnProperty.call(customerData, 'email'))
    requestPayload.guest.email = email;
  if (Object.prototype.hasOwnProperty.call(customerData, 'gender'))
    requestPayload.guest.gender = gender;
  if (Object.prototype.hasOwnProperty.call(customerData, 'birthday'))
    requestPayload.guest.birthday = birthday;

  try {
    const url = venueEndpoints.updateCustomerPATCH({
      currentVenueId,
      customerId,
    });
    const defaultAuthHeaders = getDefaultAuthHeaders(token);
    const response = await request(url, {
      method: 'PATCH',
      headers: {
        ...defaultAuthHeaders,
      },
      body: JSON.stringify(requestPayload),
    });
    return response;
  } catch (e) {
    if (e?.response) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (e?.response?.status === 401) {
        if (resData.error && resData.error === 'Authorization token is invalid') {
          throw new UnauthorizedError('Authorization token is invalid');
        }
      } else if (e?.response?.status === 422) {
        if (resData.errors && resData.errors.indexOf('Email has already been taken') !== -1) {
          throw new EmailAlreadyExistsError(i18n.t('email-has-already-been-taken'));
        } else if (
          resData.errors &&
          resData.errors.indexOf('Phone has already been taken') !== -1
        ) {
          throw new PhoneAlreadyExistsError(i18n.t('this-phone-number-already-exists'));
        }
      }
    }
    throw e;
  }
};
