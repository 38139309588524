import React from 'react';
import moment from 'moment-timezone';
import { getLastNDaysInDateType } from 'util/date-helper';
import { formatNumberPrecision } from 'util/number-helper';
import { ERROR_TYPES } from 'util/errors';
import { messageCreator } from 'util/whatsapp-helper';
import { captureException } from 'util/sentry';
import copyToClipboard from 'copy-text-to-clipboard';
import { PartnerContext } from 'contexts/PartnerProvider';
import {
  createReport as createReportPOST,
  fetchReports as fetchReportsGET,
  closeDay as closeDayPOST,
  fetchDaysReport as fetchDaysReportGET,
} from 'api/report';
import * as SharingService from 'services/sharing';

const initialState = {
  totalPOSBillsForTheDay: 0,
  totalPOSAmountForTheDay: 0,
  totalLoyaltyEntriesCapturedForTheDay: 0,
  totalLoyaltyAmountCapturedForTheDay: 0,
  showConfirmationModal: false,
  allReports: [], // includes future reports if submitted (can lah)
  reports: [], // last n days
  reportForTheDay: {},
};

/* Entire data control for report */
const useWrapper = () => {
  const {
    partner: { token, currentVenueId, currentVenueName, venues, currencyDenomination, phonePrefix },
    partner,
  } = React.useContext(PartnerContext);
  const [state, setState] = React.useState(initialState);
  const [isCopiedToClipboard, setCopiedToClipboard] = React.useState(false);
  const [isSubmittingReport, setSubmittingReport] = React.useState(false);
  const [copyToClipboardFailed, setCopyToClipboardFailed] = React.useState(false);

  const resetState = () => {
    setState(initialState);
  };

  // this should never throw
  // eslint-disable-next-line eqeqeq
  const venueName = venues.find((v) => v.id == currentVenueId)?.name;

  const locale = {
    currencyDenomination,
    phonePrefix,
  };

  const resetDataHandler = () => {
    resetState();
  };

  const fetchReports = () =>
    fetchReportsGET({
      token,
      currentVenueId,
    });

  const refreshDataAndUpdateState = async () => {
    try {
      const reports = await fetchReports();
      const todaysReport =
        reports.find((r) => r.reportDate.date() === moment().date() && r.id) || {};
      // last 3 days + today
      const last4Days = getLastNDaysInDateType(4);
      const filteredReports = last4Days.map((d) => {
        let reportForDay = reports.find((r) => r.reportDate.date() === d.date());
        if (!reportForDay) {
          reportForDay = {
            reportDate: d,
            status: 'NOT_REPORTED',
          };
        }
        return reportForDay;
      });

      setState((s) => ({
        ...s,
        allReports: reports,
        reports: filteredReports,
        totalLoyaltyEntriesCapturedForTheDay: formatNumberPrecision(
          todaysReport.totalNumberOfTransaction
        ),
        totalLoyaltyAmountCapturedForTheDay: formatNumberPrecision(
          todaysReport.totalValueOfTransaction,
          2
        ),
        totalPOSBillsForTheDay: formatNumberPrecision(todaysReport.totalNumberOfBill),
        totalPOSAmountForTheDay: formatNumberPrecision(todaysReport.totalValueOfBill, 2),
        reportForTheDay: todaysReport,
      }));
    } catch (e) {
      captureException(e);
      console.error('Something blew up while setting up reports state', e);
    }
  };

  const calculateReportDataForDate = async (date) => {
    // get % of loyalty bills captured
    // total POS bills / total claimed transactions for the day
    let daysReport = await fetchDaysReportGET({
      token,
      currentVenueId,
      date,
    });

    daysReport = daysReport[0] || undefined;

    if (!daysReport) {
      return {
        date,
        reportFound: false,
      };
    }

    if (daysReport && (daysReport.status || '').toUpperCase() === 'CLOSED') {
      return {
        date,
        reportFound: false,
        isClosed: true,
      };
    }

    return {
      date,
      reportFound: true,
      numLoyaltyClaims: formatNumberPrecision(daysReport.totalNumberOfTransaction),
      totalValLoyaltyClaims: formatNumberPrecision(daysReport.totalValueOfTransaction, 2),
      newInHouseSignUps: daysReport.totalGuestInHouse,
      newOnlineSignUps: daysReport.totalGuestOnline,
      repeatVisitors: daysReport.totalRepeatGuests,
      numPOSBills: formatNumberPrecision(daysReport.totalNumberOfBill),
      totalValPOSBills: formatNumberPrecision(daysReport.totalValueOfBill, 2),
      billsCapturedPercentage: formatNumberPrecision(daysReport.captureRate, 2),
      amountCapturedPercentage: formatNumberPrecision(daysReport.amountCaptureRate, 2),
    };
  };

  const messageCreatorForReports = async (date) =>
    messageCreator({
      reports: {
        reportToday: await calculateReportDataForDate(moment(date)),
        reportOneDayAgo: await calculateReportDataForDate(moment(date).subtract(1, 'days')),
        reportTwoDaysAgo: await calculateReportDataForDate(moment(date).subtract(2, 'days')),
        reportThreeDaysAgo: await calculateReportDataForDate(moment(date).subtract(3, 'days')),
        reportFourDaysAgo: await calculateReportDataForDate(moment(date).subtract(4, 'days')),
        reportFiveDaysAgo: await calculateReportDataForDate(moment(date).subtract(5, 'days')),
      },
      currentVenueName,
    });

  const calculateReportAndSendWAText = async (date) => {
    try {
      const message = await messageCreatorForReports(date);
      SharingService.shareTextViaNativeSharing(
        {
          text: message,
        },
        () => {
          // fallback function if native sharing is not supported
          //  whatsapp click to chat feature via whatsapp://
          SharingService.shareTextToWhatsApp(message);
          SharingService.getWhatsAppClickToChatLink(message);
          // const whatsappLink = SharingService.getWhatsAppClickToChatLink(message);
          // message tracking for debugging purposes
          // Sentry.captureMessage(whatsappLink);
        }
      );
    } catch (err) {
      captureException(err);
    }
  };

  const createReportHandler = async ({ date, numberOfBills, totalValue }) => {
    setSubmittingReport(true);
    // if date is after the current date (Check both day, month and year)
    if (moment().isBefore(date, 'day')) {
      console.error('Cannot submit report for future');
      return;
    }

    try {
      await createReportPOST({
        token,
        currentVenueId,
        date,
        numberOfBills,
        totalValue,
      });

      await calculateReportAndSendWAText(date);

      setSubmittingReport(false);
      await refreshDataAndUpdateState();
    } catch (e) {
      setSubmittingReport(false);
      if (e.name === ERROR_TYPES.DuplicateError) {
        // report already created for date
        calculateReportAndSendWAText(date);
      } else {
        captureException(e);
        console.error('Something blew up while creating a new report', e);
      }
    }
  };

  const reportTodayAsClosedHandler = async (date) => {
    try {
      await closeDayPOST({
        token,
        currentVenueId,
        date,
      });

      await refreshDataAndUpdateState();
    } catch (e) {
      if (e.name === ERROR_TYPES.DuplicateError) {
        // report already created for date
      } else {
        captureException(e);
        console.error('Something blew up while creating a new report', e);
      }
    }
  };

  const copyReport = (message) => {
    const isCopied = copyToClipboard(message);
    setCopiedToClipboard(isCopied);
    setCopyToClipboardFailed(!isCopied);
  };

  const copyReportToClipboardHandler = async (date) => {
    setCopiedToClipboard(false);
    try {
      const message = await messageCreatorForReports(date);
      // copy report to clipboard
      copyReport(message);
      // also trigger native sharing if supported by browser
      SharingService.shareTextViaNativeSharing({
        text: message,
      });
    } catch (err) {
      captureException(err);
    }
  };

  const resendWhatsappReportHandler = async (date) => {
    calculateReportAndSendWAText(date);
  };

  const showConfirmationModalHandler = () => {
    setState((s) => ({ ...s, showConfirmationModal: true }));
  };

  const confirmCloseReportHandler = async (date) => {
    await reportTodayAsClosedHandler(date);
    setState((s) => ({ ...s, showConfirmationModal: false }));
  };

  const cancelCloseReportHandler = async () => {
    setState((s) => ({ ...s, showConfirmationModal: false }));
  };

  React.useEffect(() => {
    refreshDataAndUpdateState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVenueId]);

  return {
    ...state,
    partner: { ...partner, currentVenueName: venueName },
    locale,
    resetDataHandler,
    createReportHandler,
    showConfirmationModalHandler,
    confirmCloseReportHandler,
    cancelCloseReportHandler,
    copyReportToClipboardHandler,
    isCopiedToClipboard,
    isSubmittingReport,
    copyToClipboardFailed,
    resendWhatsappReportHandler,
  };
};

export default useWrapper;
