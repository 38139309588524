/* eslint-disable react/prop-types */
import React from 'react';
import PasswordValidationStep from 'components/PasswordValidation';
import PhoneNumberStep from 'components/PhoneNumberStep';
import { useTranslation } from 'react-i18next';
import useWrapper, { STEPS } from './Redeem.useWrapper';
import OTPStep from './components/OTPStep';
import RedeemAmountStep from './components/RedeemAmountStep';
import RedeemCampaignStep from './components/RedeemCampaignStep';
import RedeemCampaignConfirmStep from './components/RedeemCampaignConfirmStep';
import RedeemSuccessStep from './components/RedeemSuccessStep';
import RedeemCampaignSuccessStep from './components/RedeemCampaignSuccessStep';
import DataAcquisitionStep from 'components/DataAcquisitionStep';
import * as Styles from './Redeem.styles';

const Redeem = (props) => {
  const {
    step,
    partner,
    customer,
    locale,
    editDetailsMode,
    errorMessage,
    isLoading,
    venueSettings: { secondaryFieldsToCapture, dataCaptureFlow },
    error,
    resetDataHandler,
    resetAndRedirectToDefaultPageHandler,
    phoneNumberSearchHandler,
    gotoNextStepFromPhoneNumStep,
    gotoRedeemCashbackStep,
    redeemCampaignGotoConfirmHandler,
    redeemCampaignHandler,
    redeemCampaignSuccessRedirectHandler,
    redeemCampaignsRedirectHandler,
    redeemCashbackRedirectHandler,
    redeemAmountHandler,
    submitOTPHandler,
    resendOTPHandler,
    redeemSuccessRedirectHandler,
    gotoEditDetailsHandler,
    removeErrorIndicator,
    updateCustomerHandler,
    selectedRedeemableCampaign,
    selectedRedeemableCampaignRedeemed,
    addNoteToTransactionHandler,
    isPhoneLoading,
    usePasswordValidation,
    authorizationSuccessHandler,
    authorizationErrorHandler,
  } = props;

  const { hasDuplicateEmail } = customer;

  const [t] = useTranslation();

  // eslint-disable-next-line no-shadow
  const renderStep = (step) => {
    switch (step) {
      case STEPS.MOBILE_NUMBER: {
        return null;
      }
      case STEPS.REDEEM_CAMPAIGN: {
        return (
          <RedeemCampaignStep
            customer={customer}
            gotoRedeemCashbackStep={gotoRedeemCashbackStep}
            redeemCampaignGotoConfirmHandler={redeemCampaignGotoConfirmHandler}
            resetModalHandler={resetDataHandler}
            locale={locale}
            isLoading={isLoading}
          />
        );
      }
      case STEPS.REDEEM_CAMPAIGN_CONFIRM: {
        return (
          <RedeemCampaignConfirmStep
            customer={customer}
            selectedRedeemableCampaign={selectedRedeemableCampaign}
            selectedRedeemableCampaignRedeemed={selectedRedeemableCampaignRedeemed}
            gotoRedeemCashbackStep={gotoRedeemCashbackStep}
            redeemCampaignHandler={redeemCampaignHandler}
            resetModalHandler={resetDataHandler}
            locale={locale}
            isLoading={isLoading}
          />
        );
      }
      case STEPS.REDEEM_CAMPAIGN_SUCCESS: {
        return (
          <RedeemCampaignSuccessStep
            customer={customer}
            selectedRedeemableCampaign={selectedRedeemableCampaign}
            selectedRedeemableCampaignRedeemed={selectedRedeemableCampaignRedeemed}
            redeemCampaignSuccessRedirectHandler={redeemCampaignSuccessRedirectHandler}
            redeemCashbackRedirectHandler={redeemCashbackRedirectHandler}
            resetModalHandler={resetAndRedirectToDefaultPageHandler}
            addNoteToTransactionHandler={addNoteToTransactionHandler}
            locale={locale}
          />
        );
      }
      case STEPS.REDEEM_AMOUNT: {
        return (
          <RedeemAmountStep
            customer={customer}
            step={STEPS.CLAIM_LOYALTY}
            secondaryFieldsToDisplay={secondaryFieldsToCapture}
            redeemAmountHandler={redeemAmountHandler}
            resetModalHandler={resetDataHandler}
            locale={locale}
            isLoading={isLoading}
            dataCaptureFlow={dataCaptureFlow}
            gotoEditDetailsHandler={gotoEditDetailsHandler}
            redeemCampaignsRedirectHandler={redeemCampaignsRedirectHandler}
          />
        );
      }
      case STEPS.VALIDATE_OTP: {
        return (
          <OTPStep
            customer={customer}
            errorMessage={errorMessage}
            isLoading={isLoading}
            resetModalHandler={resetDataHandler}
            submitOTPHandler={submitOTPHandler}
            resendOTPHandler={resendOTPHandler}
            removeErrorIndicator={removeErrorIndicator}
          />
        );
      }
      case STEPS.VALIDATE_PASSWORD: {
        return (
          <PasswordValidationStep
            customer={customer}
            locale={locale}
            resetModalHandler={resetDataHandler}
            buttonText={t('transactions.types.redemption')}
            title={t('transactions.types.redemption')}
            amount={customer.amountRedeemed}
            transactionId={customer.lastTransactionId}
            authorizationSuccessHandler={authorizationSuccessHandler}
            authorizationErrorHandler={authorizationErrorHandler}
          />
        );
      }
      case STEPS.VALIDATE_OTP_OR_PASSWORD: {
        return (
          <OTPStep
            customer={customer}
            errorMessage={errorMessage}
            isLoading={isLoading}
            resetModalHandler={resetDataHandler}
            submitOTPHandler={submitOTPHandler}
            resendOTPHandler={resendOTPHandler}
            removeErrorIndicator={removeErrorIndicator}
            usePasswordValidation={usePasswordValidation}
            showPasswordValidationOption
          />
        );
      }
      case STEPS.REDEEM_SUCCESS: {
        return (
          <RedeemSuccessStep
            customer={customer}
            redeemSuccessRedirectHandler={redeemSuccessRedirectHandler}
            redeemCampaignsRedirectHandler={redeemCampaignsRedirectHandler}
            resetModalHandler={resetAndRedirectToDefaultPageHandler}
            addNoteToTransactionHandler={addNoteToTransactionHandler}
            locale={locale}
          />
        );
      }
      case STEPS.DATA_ACQUISITION: {
        return (
          <DataAcquisitionStep
            customer={customer}
            editDetailsMode={editDetailsMode}
            secondaryFieldsToCapture={secondaryFieldsToCapture}
            updateCustomerHandler={updateCustomerHandler}
            addCustomerHandler={() => {}}
            resetModalHandler={resetDataHandler}
            isLoading={isLoading}
            hasDuplicateEmail={hasDuplicateEmail}
            error={error}
          />
        );
      }
      default:
        throw new Error("STEP didn't match");
    }
  };

  return (
    <div css={Styles.container}>
      <PhoneNumberStep
        customer={customer}
        phoneNumberSearchHandler={phoneNumberSearchHandler}
        gotoNextHandler={gotoNextStepFromPhoneNumStep}
        resetDataHandler={resetDataHandler}
        venue={{ name: partner.currentVenueName }}
        locale={locale}
        isPhoneLoading={isPhoneLoading}
        phoneNumberButtonText={t('redeem-credit')}
        transactionText={{
          title: t('redeem-credit-here'),
          desc: t('use-your-credits-to-purchase-goods'),
          steps: [
            {
              index: 'a',
              desc: t('enter-your-phone-number'),
            },
            {
              index: 'b',
              desc: t('let-the-staff-know-what-you-would-like-to-redeem'),
            },
            {
              index: 'c',
              desc: t('enjoy-your-selection-without-dealing-with-any-cash'),
            },
          ],
        }}
      />
      {renderStep(step)}
    </div>
  );
};

Redeem.propTypes = {};

export default () => {
  const props = useWrapper();
  return <Redeem {...props} />;
};
