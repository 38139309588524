import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GuestSignUpHeader from 'components/GuestSignUpHeader';
import * as Styles from './RegistrationSuccessStep.styles';

const RegistrationSuccessStep = (props) => {
  const {
    venueSetSettings: { venueName },
  } = props;
  const [t] = useTranslation();

  return (
    <>
      <GuestSignUpHeader
        pageHeaderStyles={Styles.pageHeader}
        hasBackButton={false}
        venueName={venueName}
      />
      <h4 css={Styles.pageHeadingText}>
        {t('congratulations-you-have-successfully-signed-up-for-venue-loyalty-program', {
          venueName,
        })}
      </h4>
      <h4 css={Styles.pageSubheadingText}>
        {t('we-have-sent-you-a-unique-link-via-sms-where-you-can-check-your-details')}
      </h4>
    </>
  );
};

RegistrationSuccessStep.propTypes = {
  venueSetSettings: PropTypes.object.isRequired,
};

export default RegistrationSuccessStep;
