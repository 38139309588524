import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import LanguagePopover from 'components/LanguagePopover';
import { Logo } from 'components/Icons';
import { getRandomBackgroundImageURL } from 'services/suspension-backgrounds';
import styles from './SuspensionScreen.module.scss';

const SuspensionScreen = ({ venueName, languages }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Only run 1 time on mount (isLoading = true)
    if (isLoading) {
      // Set a random background image when component is mounted
      const { desktop, mobile } = getRandomBackgroundImageURL();
      document.documentElement.style.setProperty(
        '--umai-suspension-background',
        `url('${desktop}')`
      );
      document.documentElement.style.setProperty(
        '--umai-suspension-background-mobile',
        `url('${mobile}')`
      );

      // Required so we can fade in the page with a nice transition
      // Once the image is loaded the `isLoading` class will be removed from the main component
      // triggering the CSS transitions to fade in the content
      const handleImageLoaded = () => setIsLoading(false);
      const image = new Image();
      // The media query here should be the same as the one used in `SuspensionScreen.module.scss` for the background image
      image.src = window.matchMedia('(min-width: 600px)').matches ? desktop : mobile;
      image.addEventListener('load', handleImageLoaded);

      // In case image hasn't been loaded after 2 seconds we remove the `isLoading` class and just show the page
      const fallbackTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 2000);

      return () => {
        image.removeEventListener('load', handleImageLoaded);
        clearTimeout(fallbackTimeout);
      };
    }
    return () => {};
  }, [isLoading]);

  return (
    <main className={classnames(styles.suspensionScreen, { [styles.isLoading]: isLoading })}>
      {languages?.length > 0 && (
        <div className={styles.language}>
          <LanguagePopover className={styles.languageButton} guestLanguages={languages} showArrow />
        </div>
      )}
      <section className={styles.content}>
        <header className={styles.header}>
          <a
            className={styles.logo}
            href="https://umai.io"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit UMAI website"
          >
            <Logo />
          </a>
          <h2 className={styles.tagline}>The future of restaurant software</h2>
        </header>
        <h3 className={styles.title}>
          {t('venue-is-currently-not-available-umai', { venueName })}
        </h3>
        <footer className={styles.footer}>
          <p className={styles.visit}>{t('visit-us-to-learn-more-about')}</p>
          <a
            className={styles.button}
            href="https://umai.io"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit UMAI website"
          >
            UMAI.IO
          </a>
        </footer>
      </section>
    </main>
  );
};

SuspensionScreen.propTypes = {
  venueName: PropTypes.string.isRequired,
  languages: PropTypes.array,
};

export default SuspensionScreen;
