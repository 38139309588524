import React from 'react';
import * as Styles from './Note.styles';

const Note = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    css={Styles.svg}
  >
    <g className="b">
      <path
        className="c"
        d="M13.94,16.638H3.268a2.561,2.561,0,0,1-2.562-2.56V3.265A2.561,2.561,0,0,1,3.268.705H14.091a2.561,2.561,0,0,1,2.562,2.56V14.527a.353.353,0,0,0,.706,0V3.265A3.267,3.267,0,0,0,14.091,0H3.268A3.267,3.267,0,0,0,0,3.265V14.078a3.267,3.267,0,0,0,3.268,3.265H13.94a.353.353,0,1,0,0-.705"
        transform="translate(0.313 0.328)"
      />
      <path
        className="c"
        d="M9.345,0H.436a.436.436,0,1,0,0,.873H9.345a.436.436,0,1,0,0-.873"
        transform="translate(5.24 4.046)"
      />
      <path
        className="c"
        d="M8.436,0h-8a.436.436,0,1,0,0,.873h8a.436.436,0,1,0,0-.873"
        transform="translate(5.24 8.555)"
      />
      <path
        className="c"
        d="M4.345,0H.436a.436.436,0,1,0,0,.873H4.345a.436.436,0,1,0,0-.873"
        transform="translate(5.24 13.064)"
      />
      <path
        className="c"
        d="M.653,0h0a.653.653,0,1,0,.653.653A.654.654,0,0,0,.653,0"
        transform="translate(3 3.829)"
      />
      <path
        className="c"
        d="M.653,0h0a.653.653,0,1,0,.653.653A.654.654,0,0,0,.653,0"
        transform="translate(3 8.338)"
      />
      <path
        className="d"
        d="M.653,0h0a.653.653,0,1,0,.653.653A.654.654,0,0,0,.653,0"
        transform="translate(3 12.847)"
      />
    </g>
  </svg>
);

Note.propTypes = {};
export default Note;
