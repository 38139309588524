const bp = {
  zero: 0,
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200,
  unlimited: 9999,
};

export const breakpoints = {
  all: `@media (min-width: ${bp.zero}px)`,
  smallUp: `@media (min-width: ${bp.zero}px)`,
  smallDown: `@media (max-width: ${bp.small}px)`,
  smallOnly: `@media (min-width: ${bp.zero}px) and (max-width: ${bp.small}px)`,
  mediumUp: `@media (min-width: ${bp.small}px)`,
  mediumDown: `@media (max-width: ${bp.medium}px)`,
  mediumOnly: `@media (min-width: ${bp.small}px) and (max-width: ${bp.medium}px)`,
  largeUp: `@media (min-width: ${bp.medium}px)`,
  largeDown: `@media (max-width: ${bp.large}px)`,
  largeOnly: `@media (min-width: ${bp.medium}px) and (max-width: ${bp.large}px)`,
  xlargeUp: `@media (min-width: ${bp.large}px)`,
  xlargeDown: `@media (max-width: ${bp.unlimited}px)`,
  xlargeOnly: `@media (min-width: ${bp.large}px) and (max-width: ${bp.unlimited}px)`,
};
