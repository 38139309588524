import React from 'react';
import * as Styles from './DeductIcon.styles';

const DeductIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    css={Styles.svg}
  >
    <g className="b">
      <g className="c">
        <circle className="e" cx="8" cy="8" r="8" />
        <circle className="a" cx="8" cy="8" r="7.5" />
      </g>
      <rect
        className="d"
        width="7.172"
        height="1.655"
        rx="0.828"
        transform="translate(4.414 7.172)"
      />
    </g>
  </svg>
);

DeductIcon.propTypes = {};
export default DeductIcon;
