const ROUTES = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  CAPTURE: '/capture',
  REPORTS: '/reports',
  HISTORY: '/history',
  NAV: '/nav',
  GUEST_INTERFACE: '/venues',
};

export const PUBLIC_ROUTES = [ROUTES.LOGIN, ROUTES.LOGOUT, ROUTES.GUEST_INTERFACE];

export const CAPTURE_PAGE_VIEW = {
  CLAIM: 'CLAIM',
  REDEEM: 'REDEEM',
  DEDUCT: 'DEDUCT',
  ADD: 'ADD',
};

export const ROUTES_THAT_REQUIRE_PERMISSION = [ROUTES.REPORTS, ...Object.values(CAPTURE_PAGE_VIEW)];

export default ROUTES;
