import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { openPowerByUmai } from 'util/url';
import * as Styles from './PoweredByUMAI.styles';

const PoweredByUMAI = ({ type = 'dark' }) => {
  const [t] = useTranslation();

  const powerByStyles = type === 'dark' ? Styles.poweredByDark : Styles.poweredByLight;
  return (
    // eslint-disable-next-line
    <div css={Styles.wrapper} onClick={() => openPowerByUmai()}>
      <span css={powerByStyles}>{t('powered-by')}</span>&nbsp;
      <span>
        {/* eslint-disable-next-line */}
        <a css={Styles.umaiLogo} rel="noopener noreferrer">
          UMAI
        </a>
      </span>
    </div>
  );
};

PoweredByUMAI.propTypes = {
  type: PropTypes.oneOf(['light', 'dark']),
};

export default PoweredByUMAI;
