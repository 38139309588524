import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import ModalWrapper from 'components/ModalWrapper';
import AmountPad from 'components/Amount/AmountPad';
import CustomerDetails from 'components/CustomerDetails';
import * as Styles from './RedeemAmountStep.styles';

const RedeemAmountStep = (props) => {
  const {
    customer: {
      firstName,
      lastName,
      phone,
      balance,
      gender,
      email,
      birthday,
      currentTier,
      memberSince,
      renewalDate,
    } = {},
    isLoading,
    dataCaptureFlow,
    secondaryFieldsToDisplay = {},
    redeemAmountHandler,
    gotoEditDetailsHandler,
    resetModalHandler,
    redeemCampaignsRedirectHandler,
    locale,
  } = props;
  const [t] = useTranslation();
  const renderCustomer = () => (
    <CustomerDetails
      Styles={Styles}
      customer={{
        firstName,
        lastName,
        phone,
        balance,
        gender,
        email,
        birthday,
        currentTier,
        memberSince,
        renewalDate,
      }}
      dataCaptureFlow={dataCaptureFlow}
      secondaryFieldsToDisplay={secondaryFieldsToDisplay}
      gotoEditDetailsHandler={gotoEditDetailsHandler}
      redeemCampaignsRedirectHandler={redeemCampaignsRedirectHandler}
      locale={locale}
      amountLabel={t('available-credit')}
      popOverLinks={['edit', 'claim', 'redeemRedirectHandler', 'add', 'deduct']}
    />
  );

  return (
    <div className="redeem-loyalty-step">
      <ModalWrapper wrapperSelector=".redeem-loyalty-step" resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <div css={Styles.cusContainer}>{renderCustomer()}</div>
          <AmountPad
            Styles={Styles}
            header={t('enter-the-amount-to-redeem')}
            currencyDenomination={locale.currencyDenomination}
            isAmountGreaterThanBalance={(number) => Number(number) > Number(balance)}
            isAmountCTAEnabled={(number, isGreaterThanBalance) =>
              Number(number) > 0 && !isGreaterThanBalance
            }
            errMsg={t('there-is-not-enough-credit-to-redeem')}
            amountHandler={redeemAmountHandler}
            amountButton={t('transactions.types.redemption')}
          />
          <div css={isLoading ? Styles.loadingOverlay : Styles.loadingOverlayHidden}>
            <ReactLoading type={'spin'} color={'#169A89'} width={50} />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

RedeemAmountStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
    birthday: PropTypes.string,
    currentTier: PropTypes.object,
    balance: PropTypes.string,
    memberSince: PropTypes.string,
    renewalDate: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  dataCaptureFlow: PropTypes.string.isRequired,
  secondaryFieldsToDisplay: PropTypes.object,
  redeemAmountHandler: PropTypes.func.isRequired,
  gotoEditDetailsHandler: PropTypes.func,
  resetModalHandler: PropTypes.func.isRequired,
  redeemCampaignsRedirectHandler: PropTypes.func,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
};

export default RedeemAmountStep;
