import request from './request';
import { guestEndpoints } from './endpoints';
import { getDefaultAuthHeaders } from './api-util';

export const fetchCampaignsByGuestId = async ({ token, currentVenueId, guestId }) => {
  if (!guestId) throw new Error('guestId invalid');

  const url = guestEndpoints.fetchCampaignsForGuestGET({
    guestId,
    currentVenueId,
  });
  const defaultAuthHeaders = getDefaultAuthHeaders(token);
  const response = await request(url, {
    method: 'GET',
    headers: {
      ...defaultAuthHeaders,
    },
  });

  return response;
};

export const redeemCampaign = async ({ token, guestId, campaignId, venueId } = {}) => {
  if (!guestId) throw new Error('guestId invalid');
  if (!campaignId) throw new Error('campaignId invalid');
  if (!venueId) throw new Error('venueId invalid');

  const url = guestEndpoints.redeemCampaignPATCH({ guestId, campaignId });
  const defaultAuthHeaders = getDefaultAuthHeaders(token);
  const response = await request(url, {
    method: 'PATCH',
    headers: { ...defaultAuthHeaders },
    body: JSON.stringify({
      loyalty_campaign_log: {
        redemption_venue_id: venueId,
      },
    }),
  });

  return response;
};
