import React from 'react';
import PropTypes from 'prop-types';

function AlertIcon({ width = 80, height = 80, color = '#000' }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4.324a4.54 4.54 0 00-4.53 4.541v3.626a.293.293 0 00.586 0V8.865c0-2.18 1.769-3.954 3.944-3.954a3.954 3.954 0 013.944 3.954v1.116a.293.293 0 00.586 0V8.865A4.54 4.54 0 0010 4.325z"
        fill={color}
      />
      <path
        d="M10.586 6.75H9.414a.293.293 0 00-.293.294v4.307a.293.293 0 00.293.293h1.172a.294.294 0 00.293-.293V7.044a.292.292 0 00-.293-.293zm-.293 4.307h-.586V7.338h.586v3.719zM10 12.114a.881.881 0 10-.004 1.762.881.881 0 00.004-1.762zm0 1.175a.294.294 0 11-.001-.588.294.294 0 01.001.588z"
        fill={color}
      />
      <path
        d="M19.12 17.4h-3.36v-2.056a.294.294 0 00-.292-.293h-.938v-3.788a.291.291 0 00-.293-.296.293.293 0 00-.293.296v3.788H6.056v-1.292a.293.293 0 10-.586 0v1.292h-.938a.292.292 0 00-.292.293V17.4H.88a.88.88 0 100 1.761h18.24a.881.881 0 000-1.761zm0 1.174H.88a.293.293 0 110-.587H12.52a.294.294 0 100-.587H4.825v-1.762h10.35V17.4h-1.413a.294.294 0 000 .587h5.357a.294.294 0 010 .587zM10 .84a.293.293 0 00-.293.294v1.364a.293.293 0 10.586 0V1.134A.292.292 0 0010 .84zM6.855 3.261L6.172 2.08a.293.293 0 10-.507.295l.683 1.182a.293.293 0 10.507-.295zM4.26 5.635l-1.183-.683a.294.294 0 00-.292.51l1.183.682a.293.293 0 00.292-.51zM3.204 8.983H1.838a.294.294 0 100 .587h1.366a.294.294 0 100-.587zM18.162 8.983h-1.366a.294.294 0 000 .587h1.366a.293.293 0 100-.587zM17.323 5.043a.292.292 0 00-.4-.108l-1.183.683a.293.293 0 10.292.509l1.183-.683a.294.294 0 00.108-.4zM14.228 1.943a.293.293 0 00-.4.106l-.683 1.182a.294.294 0 00.329.431.292.292 0 00.178-.136l.683-1.182a.294.294 0 00-.107-.401z"
        fill={color}
      />
      <path d="M7.03 16.174H5.743a.293.293 0 100 .587H7.03a.293.293 0 100-.587z" fill={color} />
    </svg>
  );
}

AlertIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default AlertIcon;
