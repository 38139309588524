import { css } from '@emotion/react';

// const leadingTextSize = css`
//   font-size: 1.35rem;
// `;

export const pageHeader = css`
  min-height: 90px;
  max-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-white);
  font-size: 1.6rem;
  background-image: var(--bg-image);
  font-weight: 500;
`;

export const accountBalanceWrapper = css`
  margin: 1rem;
`;

export const accountBalanceLabel = css`
  color: var(--color-darkGrey);
  font-size: 1rem;
  margin-bottom: -0.25rem;
`;

export const accountBalance = css`
  color: var(--color-primary);
  font-size: 1.6rem;
  font-weight: 500;
`;

export const transactionsWrapper = css`
  // padding: 1rem;
`;

export const transactionsTable = css`
  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  tr th {
    background: #ebebeb;
  }

  tr th,
  tr td {
    padding: 0.5rem 1rem;
    vertical-align: middle;
    border: none;
  }

  tr th:last-of-type,
  tr td:last-of-type {
    text-align: right;
  }
`;
export const transactionType = css`
  display: flex;
  flex-direction: column;
`;

export const createdAt = css`
  display: flex;
  flex-direction: column;
`;

export const paginationIndicator = css`
  font-size: 0.85rem;
  color: var(--color-darkGrey);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;

  a {
    cursor: pointer;
    background: var(--color-primary);
    color: var(--text-white);
    padding: 0.35rem 1rem;
    border-radius: 5px;
    display: flex;
  }
`;

export const backButtonWrapper = css`
  margin: 1rem;
`;

export const backButton = css`
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--text-primary);
  background: transparent;
  color: var(--text-primary);
  font-weight: 500;
  padding: 0.75rem 1rem;
  display: block;
  margin: 0 auto;
  transition: all 0.3s;

  &:hover,
  &:focus {
    color: white;
    background: var(--text-primary);
  }
`;
