import {
  GuestNotFoundError,
  TooMuchSmsLinkRequestError,
  VenueNotFoundError,
  GenericError,
} from 'util/errors';
import { getErrorMessage } from 'util/error-helper';
import i18n from 'services/i18n';
import { signupEndpoints } from './endpoints';
import request from './request';

export const fetchVenueSettings = async ({ venueSlug } = {}) => {
  if (!venueSlug) throw new Error('venueSlug invalid');

  try {
    const url = signupEndpoints.fetchVenueLoyaltySettingsGET({
      venueSlug,
    });
    const response = await request(url);

    return response;
  } catch (e) {
    const errorMessage = await getErrorMessage(e);
    if (errorMessage === 'Venue not found') {
      throw new VenueNotFoundError(i18n.t('this-venue-does-not-exist'));
    }

    throw new GenericError(errorMessage);
  }
};

export const searchPhoneNumber = async ({ venueSlug, phoneNumber } = {}) => {
  if (!venueSlug) throw new Error('venueSlug invalid');
  if (!phoneNumber) throw new Error('phoneNumber invalid');

  try {
    const url = signupEndpoints.searchGuestGET({
      venueSlug,
      phoneNumber,
    });
    const response = await request(url);

    return response;
  } catch (e) {
    const errorMessage = await getErrorMessage(e);
    if (errorMessage === 'Guest not found') {
      throw new GuestNotFoundError(i18n.t('this-guest-is-not-found'));
    }

    throw new GenericError(errorMessage);
  }
};

export const sendProfileLink = async ({ venueSlug, tokenId } = {}) => {
  if (!venueSlug) throw new Error('venueSlug invalid');
  if (!tokenId) throw new Error('tokenId invalid');

  try {
    const url = signupEndpoints.sendProfileLinkGET({
      venueSlug,
      tokenId,
    });
    const response = await request(url);

    return response;
  } catch (e) {
    const errorMessage = await getErrorMessage(e);
    if (errorMessage === 'Too much request within 5 minutes') {
      throw new TooMuchSmsLinkRequestError(i18n.t('too-much-request-within-n-minutes', { n: 5 }));
    }

    throw new GenericError(errorMessage);
  }
};

export const signupAsLoyaltyMember = async ({ venueSlug, requestPayload } = {}) => {
  if (!venueSlug) throw new Error('venueSlug invalid');

  try {
    const url = signupEndpoints.registerAsMemberPOST({
      venueSlug,
    });
    const response = await request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestPayload),
    });

    return response;
  } catch (e) {
    const errorMessage = await getErrorMessage(e);

    throw new GenericError(errorMessage);
  }
};
