import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './InputAlt.styles';

const InputAlt = React.forwardRef((props, ref) => {
  const {
    type = 'text',
    name = '',
    label = '',
    invalid = false,
    css: styleOverrides = '',
    errMsg = '',
    isPhoneInput = false,
    ...rest
  } = props;
  return (
    <div css={Styles.inputWrapper}>
      <div css={isPhoneInput ? Styles.phoneInputWrapper : {}}>
        <Styles.StyledInput
          css={styleOverrides}
          type={type}
          name={name}
          placeholder={label}
          invalid={invalid}
          ref={ref}
          {...rest}
        />
      </div>
      {invalid && errMsg ? (
        <div css={Styles.errorMsg}>
          <div>{errMsg}</div>
        </div>
      ) : null}
    </div>
  );
});

InputAlt.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  invalid: PropTypes.bool,
  css: PropTypes.string,
  errMsg: PropTypes.string,
  isPhoneInput: PropTypes.bool,
};

export default InputAlt;
