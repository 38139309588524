import { UnauthorizedError, InvalidTransactionError } from 'util/errors';
import { hasError } from 'util/error-helper';
import i18n from 'services/i18n';
import { validationEndpoints } from './endpoints';
import request from './request';
import { getDefaultAuthHeaders } from './api-util';

export const validatePassword = async ({ token, currentVenueId, transactionId, password } = {}) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');

  try {
    const url = validationEndpoints.validatePassword(transactionId);
    const defaultAuthHeaders = getDefaultAuthHeaders(token);
    const response = await request(url, {
      method: 'POST',
      headers: {
        ...defaultAuthHeaders,
      },
      body: JSON.stringify({ password: String(password) }),
    });

    return response;
  } catch (e) {
    let resData = null;
    await e.response.json().then((data) => {
      resData = data;
    });

    if (e?.response?.status === 401) {
      if (hasError(resData, 'Authorization token is invalid')) {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    } else if (e?.response?.status === 422) {
      if (hasError(resData, 'Transaction is not valid')) {
        throw new InvalidTransactionError(i18n.t('redeem-transaction-is-not-valid'));
      }
    } else if (e?.response?.status === 404) return {};
    throw e;
  }
};
