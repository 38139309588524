import countries from 'moment-timezone/data/meta/latest.json';
import { getParamByISO, getISOByParam } from 'iso-country-currency';
import { DEFAULT_LANGUAGE } from 'constants/app-env';
import COUNTRY_DIALING_CODES from 'constants/country-dialing-codes';

const getISOCodeByCurrencyCode = (currencyCode = '') => {
  try {
    return getISOByParam('currency', currencyCode.toUpperCase()); // won't work with non-uppercase code
  } catch (err) {
    throw new Error(`Currency code does not match any country: ${currencyCode.toUpperCase()}`);
  }
};

// isoCode: ISO 3166-1 alpha-2,  2 letters code
export const getCurrencyDenominationByCountryISO = (isoCode = '') => {
  try {
    return getParamByISO(isoCode, 'symbol');
  } catch (err) {
    throw new Error(`ISO code does not match any country: ${isoCode}`);
  }
};

const getCurrencyCodeByCountryISO = (isoCode = '') => {
  try {
    return getParamByISO(isoCode, 'currency');
  } catch (err) {
    throw new Error(`ISO code does not match any country: ${isoCode}`);
  }
};

export const getPhonePrefixByCountryISO = (isoCode = '') => {
  if (COUNTRY_DIALING_CODES[isoCode]) {
    return `+${COUNTRY_DIALING_CODES[isoCode]}`;
  }
  throw new Error(`ISO code does not match any country: ${isoCode}`);
};

export const setMomentLocale = async (languages) =>
  Promise.all(
    languages
      .filter((l) => l !== DEFAULT_LANGUAGE)
      .map((locale) => import(`moment/locale/${locale}`))
  );

export const getCountryZone = (isoCodeCountry) =>
  countries && countries[isoCodeCountry] && countries[isoCodeCountry].zones[0];

export const getCountryIsoCode = (country, currencyCode) => {
  try {
    return country?.countryIsoCode || getISOCodeByCurrencyCode(currencyCode);
  } catch (error) {
    console.error('country code not found of venue', error);
    return 'MY';
  }
};

const getCurrencyCode = (country, currencyCode) => {
  try {
    return currencyCode || getCurrencyCodeByCountryISO(country?.countryIsoCode);
  } catch (error) {
    console.error('currency code not found of venue', error);
    return 'MYR';
  }
};

export const getVenueCurrencyAndCountryCode = (venue, currencyCode) => {
  const { address = {} } = venue || {};
  const { country = {} } = address || {};
  return `${getCountryIsoCode(country, currencyCode)},${getCurrencyCode(country, currencyCode)}`;
};
