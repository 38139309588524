import { css } from '@emotion/react';

export {
  pageWrapper,
  pageHeader,
  container,
  headerBackButton,
  formWrapper,
  fieldWrapper,
  submitButton,
  disabledSubmitButton,
  poweredByUMAIWrapper,
  errorMessageWrapper,
  fixedToBottom,
} from '../../GuestSignup.styles';

const leadingTextSize = css`
  font-size: 1.35rem;
`;

export const pageHeadingText = css`
  color: var(--text-primary);
  font-size: 1.6rem;
  font-weight: 500;
  margin: 1.5rem 0;
  padding: 1rem;
  line-height: 1.3;
`;

export const pageSubheadingText = css`
  color: var(--text-primary);
  ${leadingTextSize}
  font-weight: normal;
  margin: 1.5rem 0;
  padding: 1rem;
  line-height: 1.3;
`;

export const timer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-top: -2rem;

  svg {
    margin-top: -1px;
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
`;
