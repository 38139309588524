import styled from '@emotion/styled';

export const StyledButton = styled.button`
  margin: 1rem 0;
  text-align: center;
  border: none;
  height: 3.5rem;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  font-weight: 500;

  background-color: var(--color-primary);
  color: white;
  ${(props) => props.css};

  &:disabled {
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
