/* eslint-disable no-console */
import ActionCable from 'actioncable';

class SocketConnection {
  constructor(socketHostUrl = '') {
    this.cable = ActionCable.createConsumer(socketHostUrl);
  }

  // eslint-disable-next-line
  subscribe = (channelData = {}, { onReceivedData }) =>
    this.cable.subscriptions.create(channelData, {
      connected: () => console.debug(`socket connection connected`),
      disconnected: () => console.debug(`socket connection disconnected`),
      received: onReceivedData,
      rejected: () => console.debug(`socket connection rejected`),
    });

  unsubscribe = (subscription) => subscription?.unsubscribe();

  disconnect = () => this.cable.disconnect();
}

export default SocketConnection;
