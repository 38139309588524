import React from 'react';
import PropTypes from 'prop-types';
import LogoUMAI from 'components/LogoUMAI';
import { useTranslation } from 'react-i18next';
import * as Styles from './AppName.styles';

const AppName = ({ type = 'light' }) => {
  const [t] = useTranslation();
  let logoStyle = Styles.logoLight;
  let appName = Styles.appNameLight;

  if (type === 'dark') logoStyle = Styles.logoDark;
  if (type === 'theme') logoStyle = Styles.logoTheme;

  if (type === 'dark') appName = Styles.appNameDark;
  if (type === 'theme') appName = Styles.appNameTheme;

  return (
    <div css={logoStyle}>
      <div css={Styles.logoImage}>
        <LogoUMAI />
      </div>
      <p css={appName}>{t('loyalty')}</p>
    </div>
  );
};

AppName.propTypes = {
  type: PropTypes.oneOf(['light', 'dark', 'theme']),
};

export default AppName;
