import { css } from '@emotion/react';

const baseStyle = css`
  flex: 1;
  font-size: 1.5em;
`;

const appNameBaseStyle = css`
  font-size: 1.15rem;
  font-weight: 300;
  line-height: 1.5rem;
  letter-spacing: 1px;
  margin-bottom: 0;
`;

export const logoLight = css`
  ${baseStyle};
  color: white;

  .st0 {
    fill: #ffffff;
  }
`;

export const logoDark = css`
  ${baseStyle};

  svg {
    .st0 {
      fill: #000000;
    }
  }
`;

export const logoTheme = css`
  ${baseStyle};

  svg {
    .st0 {
      fill: var(--color-primary);
    }
  }
`;

export const appNameLight = css`
  ${appNameBaseStyle};
  color: white;
`;

export const appNameDark = css`
  ${appNameBaseStyle};
`;

export const appNameTheme = css`
  ${appNameBaseStyle};
  color: var(--color-primary);
`;

export const logoImage = css`
  svg {
    width: 170px;
  }
`;
