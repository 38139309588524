import ROUTES, { CAPTURE_PAGE_VIEW } from 'constants/routes';
import { OPERATIONAL_PERMISSIONS } from 'constants/operational-permission';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppHelmet from 'components/AppHelmet';
import PrivateRoute from 'components/PrivateRoute';
import { getAllowedPermission } from 'services/auth';
import { Redirect } from 'react-router-dom';
import { PartnerContext } from 'contexts/PartnerProvider';
import Layout from 'components/Layout';
import Claim from './components/Claim';
import Redeem from './components/Redeem';
import Deduct from './components/Deduct';
import Add from './components/Add';
import styles from './CapturePage.module.scss';

const CapturePage = (props) => {
  const {
    partner: { currentUser },
  } = useContext(PartnerContext);
  const [t] = useTranslation();
  // get url params from react router url => /:view?
  const { match: { params: { view = '' } = {} } = {} } = props; // eslint-disable-line react/prop-types

  const viewComponents = {
    [CAPTURE_PAGE_VIEW.CLAIM]: Claim,
    [CAPTURE_PAGE_VIEW.REDEEM]: Redeem,
    [CAPTURE_PAGE_VIEW.DEDUCT]: Deduct,
    [CAPTURE_PAGE_VIEW.ADD]: Add,
  };

  let ViewComponent = viewComponents[view.toUpperCase()];

  // On login if the user has permission for transactions, show the Claim page by default.
  // If the user has permission for additions and deductions, show the Add page by default.
  if (!ViewComponent) {
    if (currentUser.permissions.includes(OPERATIONAL_PERMISSIONS.LOYALTY_TRANSACTIONS)) {
      ViewComponent = Claim;
    } else if (
      currentUser.permissions.includes(OPERATIONAL_PERMISSIONS.LOYALTY_ADDITIONS_DEDUCTIONS)
    ) {
      ViewComponent = Add;
    }
  }

  // Current user has no  transaction and additions_deductions permissions , redirect to the navigation page
  if (!ViewComponent) {
    return <Redirect to={ROUTES.NAV} />;
  }

  return (
    <div className={styles['page-wrapper']}>
      <AppHelmet
        title={`${t('loyalty')} | ${t('claim-redeem')}`}
        description={`${t('loyalty')} | Claim or Redeem loyalty to diners`}
      />
      <Layout showPaymentProfile showBackButton>
        <PrivateRoute
          allowedPermission={getAllowedPermission(view.toUpperCase())}
          path={view.toUpperCase()}
          isPage
        >
          <ViewComponent />
        </PrivateRoute>
      </Layout>
    </div>
  );
};

export default CapturePage;
