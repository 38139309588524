import { css } from '@emotion/react';

export const layoutWrapper = css`
  display: flex;
  min-height: 100%;
`;

const contentWrapperBaseStyle = css`
  margin: 0 auto;
  // max-width: 90%;
  max-width: 100%;
  min-height: 100%;
  flex: 1;
  z-index: 1;
  display: flex;
  justify-content: center;

  @media (min-width: calc((992px + (992px / 10)))) {
    max-width: 992px;
  }
`;

export const contentWrapper = css`
  ${contentWrapperBaseStyle}
`;

export const contentWrapperCentered = css`
  ${contentWrapperBaseStyle}
  align-items: center;
`;

export const contentContainer = css`
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  body.venue-in-payment-warning & {
    padding-top: var(--payment-warning-height);
  }
`;

export const imagePlaceholderWrapper = css`
  // background-color: #f6f6f6;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: fixed;
  overflow: hidden;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;

  &.loaded::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  img {
    position: absolute;
    opacity: 1;
    left: 0;
    height: 100%;
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    object-position: 0 100%;
  }
`;

export const imagePlaceholderWrapperDark = css`
  &.loaded::after {
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const thumbnailImage = css`
  filter: blur(50px);
  /* this is needed so Safari keeps sharp edges */
  transform: scale(1);
`;

export const imageFadeAnimation = css`
  img {
    opacity: 0;
    transition: opacity 1s linear;

    &.loaded {
      opacity: 1;
    }
  }
`;
