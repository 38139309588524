import { css } from '@emotion/react';

export const wrapper = css`
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const container = css`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 1rem;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border: 1px solid #707070;
  border-bottom: none;
  padding: 2rem 1.5rem;
  max-height: 100%;

  @media (min-width: 768px) {
    top: 50%;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 100%;
    border-radius: 40px;
    max-height: 100%;
  }
`;

export const campaignName = css`
  color: var(--text-primary);
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
`;

export const campaignExpiry = css`
  color: var(--color-darkGrey);
  font-size: 1rem;
`;

export const campaignSmsContent = css`
  color: var(--text-primary);
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
`;

export const campaignRedemptionInstructions = css`
  font-size: 1rem;
  color: var(--color-darkGrey);
  margin: 1rem 0;
  margin-bottom: 2rem;
`;

export const buttonWrapper = css``;

export const closePopupButton = css`
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  background: var(--color-primary);
  color: var(--text-white);
  font-weight: 500;
  padding: 0.75rem 1rem;
  display: block;
  margin: 0 auto;
  width: 100%;
`;
