import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ISO6391 from 'iso-639-1';
import moment from 'moment';
import i18n from 'services/i18n';
import { supportedLanguages } from 'services/language';
import { DEFAULT_LANGUAGE } from 'constants/app-env';
import { PartnerContext } from 'contexts/PartnerProvider';
import Popup from 'components/Popup';
import { ArrowDown } from 'components/Icons';
import * as styles from './LanguagePopover.styles';

const LanguagePopover = ({
  className,
  colored,
  bordered = false,
  type = 'light',
  guestLanguages,
  showArrow = false,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const {
    partner: { languages = [] },
  } = React.useContext(PartnerContext);

  const languageClickHandler = (lang) => {
    if (i18n) {
      const currentLanguage = lang || DEFAULT_LANGUAGE;
      moment.locale(currentLanguage);
      i18n.changeLanguage(currentLanguage);
    }
  };

  const getLanguages = () => {
    if (guestLanguages?.length) {
      return guestLanguages;
    }
    return languages;
  };

  if (getLanguages()?.length <= 1) return null;

  return (
    <Popup
      open={showPopover}
      onOpenChange={(v) => {
        setShowPopover(v);
      }}
      placement="topRight"
      triggerElement={
        <div css={styles.languageSwitchButton(colored, bordered, type)} className={className}>
          <span>{i18n.language}</span>
          {showArrow && <ArrowDown />}
        </div>
      }
    >
      {/* eslint-disable-next-line */}
      <div
        onClick={() => {
          setShowPopover(false);
        }}
        css={styles.languagePopover}
      >
        {supportedLanguages(getLanguages()).map(({ language }) => (
          // eslint-disable-next-line
          <a
            className="language-popover-item"
            rel="noopener noreferrer"
            onClick={() => languageClickHandler(language)}
            key={language}
          >
            <span>
              {ISO6391.getNativeName(language)} ({ISO6391.getName(language)})
            </span>
          </a>
        ))}
      </div>
    </Popup>
  );
};

LanguagePopover.propTypes = {
  className: PropTypes.string,
  guestLanguages: PropTypes.array,
  type: PropTypes.oneOf(['light', 'dark']),
  colored: PropTypes.bool,
  bordered: PropTypes.bool,
  showArrow: PropTypes.bool,
};

export default LanguagePopover;
