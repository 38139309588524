import { css } from '@emotion/react';

const leadingTextSize = css`
  font-size: 1.35rem;
`;

export const wrapper = css`
  @media (min-width: 768px) {
    max-width: 768px;
  }
  margin: 0 auto;
`;

export const pageWrapper = css`
  text-align: center;
`;

export const pageHeader = css`
  min-height: 90px;
  max-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-white);
  font-size: 1.6rem;
  background-image: var(--bg-image);
  font-weight: 500;
  margin: -1.5rem;
  margin-bottom: 0;
  position: relative;
`;

export const headerBackButton = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  // width: 5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-white);
  cursor: pointer;
  .go-back {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px 2px #e4e4e466;
    padding: 0 0.2rem;
    font-size: 1.5rem;
    &:hover {
      border: 1px solid var(--text-grey);
      box-shadow: 1px 1px 2px #e4e4e466;
      top: 3px;
    }
  }
  .go-back-text {
    padding-left: 5px;
    font-size: 1rem;
    box-shadow: none;
    border: none;
  }
`;

export const container = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

export const formWrapper = css`
  margin: 2rem 0;
  margin-bottom: 0;
`;

export const phoneNumberWrapper = css`
  text-align: left;
  margin-bottom: 1.5rem;
`;

export const phoneNumberLabel = css`
  color: var(--color-darkGrey);
`;

export const phoneNumber = css`
  ${leadingTextSize}
  color: var(--text-primary);
  font-weight: 500;
`;

export const fieldWrapper = css`
  position: relative;

  .field-label {
    position: absolute;
    z-index: 5;
    top: -12px;
    left: 16px;
    font-size: 1rem;
    background: var(--text-white);
    color: var(--color-primary);
    padding: 0 0.5rem;
    background: transparent;

    &::before {
      content: '';
      position: absolute;
      top: 12px;
      left: 0;
      right: 0;
      transform: translate(0, -50%);
      background: #fafafa;
      height: 4px;
      z-index: -1;
    }
  }

  input {
    padding: 0 24px !important;
    font-size: 20px !important;
    border: 1px solid var(--color-primary);
    // border: 1px solid #d9d9d9;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    height: 50px;
    padding: 0;
    border-radius: 8px;
    background: transparent;

    &:focus {
      background: transparent;
      border-color: var(--color-primary);
    }
  }
`;

export const fieldWrapperWithError = css`
  ${fieldWrapper}

  input {
    border-color: var(--color-error);
    &:focus {
      border-color: var(--color-error);
    }
  }

  .field-label {
    color: var(--color-error);
  }
`;

export const tip = css`
  color: #939393;
  font-size: 13px;

  a {
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
  }
`;

export const submitButton = css`
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  background: var(--color-primary);
  color: var(--text-white);
  font-weight: 500;
  padding: 0 1rem;
  height: 50px;
  display: block;
  margin: 0 auto;
  width: 100%;
  margin: 2.5rem 0;
  transition: all 0.3s;
  ${leadingTextSize}

  @media (min-width: 768px) {
    &:hover,
    &:focus {
      color: white;
      background: var(--color-primary);
    }
  }
`;

export const disabledSubmitButton = css`
  ${submitButton}
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  color: #ffffff;

  @media (min-width: 768px) {
    &:hover,
    &:focus {
      color: white;
      background: #d9d9d9;
      border: 1px solid #d9d9d9;
    }
  }
`;

export const poweredByUMAIWrapper = css`
  position: absolute;
  bottom: 0rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const fixedToBottom = ({ hasLanguagePopover }) => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(!hasLanguagePopover && 'margin-top: 2rem') || 'margin-top: 0'};
  width: 100%;
  position: relative;
`;

export const errorMessageWrapper = css`
  background: var(--color-error);
  padding: 1rem 2rem;
  color: #fff;
  border-radius: 8px;
  margin: 2.5rem 0;
  word-break: break-word;
`;
