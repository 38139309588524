import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';
import { PartnerContext } from 'contexts/PartnerProvider';
import * as Styles from './VenueDropdown.styles';

const VenueDropdown = ({ type = 'light' }) => {
  const [showGenderPopover, setGenderShowPopover] = useState(false);
  const {
    partner: { venues, currentVenueName },
    changeVenue,
  } = useContext(PartnerContext);

  const venueSelectHandler = (value) => {
    changeVenue(value);
    setGenderShowPopover(false);
  };

  const dropdownStyles = type === 'light' ? Styles.venueDropdownLight : Styles.venueDropdown;

  return (
    <Popup
      open={showGenderPopover}
      onOpenChange={(v) => {
        setGenderShowPopover(v);
      }}
      placement="bottomLeft"
      triggerElement={
        <div css={dropdownStyles}>
          <span>{currentVenueName}</span>
        </div>
      }
    >
      <div css={Styles.venueNamesPopup}>
        {venues.map((v) => (
          <button
            key={v.id}
            type="button"
            onClick={() => {
              venueSelectHandler(v.id);
            }}
          >
            {v.name}
          </button>
        ))}
      </div>
    </Popup>
  );
};

VenueDropdown.propTypes = {
  type: PropTypes.oneOf(['light', 'dark']),
};

export default VenueDropdown;
