import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ScrollInputDate from '@umai/scroll-input-date';
import '@umai/scroll-input-date/dist/style.css';
import { useTranslation, Trans } from 'react-i18next';
import { DEFAULT_LANGUAGE } from 'constants/app-env';
import { formatNumber } from 'util/number-helper';
import {
  isFirstNameValid,
  isLastNameValid,
  isGenderValid,
  isBirthdayValid,
  isEmailValid,
  addBirthdayYear,
} from 'util/data-gathering-helper';
import { openPrivacyAndPolicy, openTermAndCondition } from 'util/url';
import useFormField from 'hooks/useFormField';
import Input from 'components/Input';
import GuestSignUpHeader from 'components/GuestSignUpHeader';
import * as Styles from './RegistrationStep.styles';

// TODO: Hide/show fields depending on loyalty data gathering for self-signup

const RegistrationStep = (props) => {
  const {
    phoneNumber = '',
    hasErrorMessage = false,
    errorMessage = '',
    venueSetSettings,
    signupToVenueLoyaltyHandler = () => {},
    goBackToPhoneNumberStep = () => {},
  } = props;

  const {
    venueName,
    loyaltyDataGatheringSetting: { collectBirthYear },
  } = venueSetSettings;
  const [firstName, setFirstName, firstNameValid, setFirstNameValid] = useFormField('');
  const [lastName, setLastName, lastNameValid, setLastNameValid] = useFormField('');
  const [email, setEmail, emailValid, setEmailValid] = useFormField('');
  const [gender, setGender, genderValid, setGenderValid] = useFormField('');
  const [birthday, setBirthday, birthdayValid, setBirthdayValid] = useFormField('');
  const birthdayFormat = collectBirthYear ? 'dd/MM/yyyy' : 'dd/MM';

  const [t, i18n] = useTranslation();

  const isAllFieldAreValid = () =>
    isFirstNameValid(firstName) &&
    isLastNameValid(lastName) &&
    isEmailValid(email) &&
    isBirthdayValid(birthday, birthdayFormat) &&
    isGenderValid(gender);

  const [allFieldsAreValid, setAllFieldsAreValid] = useState(isAllFieldAreValid());

  useEffect(() => {
    setAllFieldsAreValid(isAllFieldAreValid());
    // eslint-disable-next-line
  }, [firstName, lastName, email, gender, birthday]);

  const handleBirthDayChange = (date) => {
    setBirthday({
      target: {
        value: date,
      },
    });
    setBirthdayValid(isBirthdayValid(date, birthdayFormat));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (allFieldsAreValid) {
      signupToVenueLoyaltyHandler(
        {
          firstName,
          lastName,
          email,
          gender,
          birthday: addBirthdayYear(birthday),
          locale: i18n?.language || DEFAULT_LANGUAGE,
        },
        setAllFieldsAreValid
      );
    } else {
      setFirstNameValid(isFirstNameValid(firstName));
      setBirthdayValid(isBirthdayValid(birthday, birthdayFormat));
      setLastNameValid(isLastNameValid(lastName));
      setEmailValid(isEmailValid(email));
      setGenderValid(isGenderValid(gender));
    }
  };

  const renderErrorMessages = () =>
    hasErrorMessage ? <div css={Styles.errorMessageWrapper}>{errorMessage}</div> : null;

  return (
    <>
      <GuestSignUpHeader
        pageHeaderStyles={Styles.pageHeader}
        hasBackButton
        venueName={venueName}
        headerBackButtonStyles={Styles.headerBackButton}
        goBackHandler={goBackToPhoneNumberStep}
      />
      <h4 css={Styles.pageHeadingText}>
        {t('enter-your-details-to-start-earning-cashback-loyalty-rewards')}
      </h4>
      {renderErrorMessages()}
      <div css={Styles.formWrapper}>
        <form onSubmit={handleFormSubmit}>
          <div css={Styles.phoneNumberWrapper}>
            <div css={Styles.phoneNumberLabel}>{t('phone-number')}</div>
            <div css={Styles.phoneNumber}>{formatNumber(phoneNumber)}</div>
          </div>
          <div css={Styles.inputWrapper}>
            <Input
              type="text"
              name="firstname"
              label={t('first-name')}
              value={firstName}
              onChange={setFirstName}
              invalid={!firstNameValid}
              errMsg={t('please-enter-a-valid-first-name')}
              onFocus={() => setFirstNameValid(isFirstNameValid(firstName))}
              required
            />
          </div>

          <div css={Styles.inputWrapper}>
            <Input
              type="text"
              name="lastname"
              label={t('last-name')}
              value={lastName}
              onChange={setLastName}
              invalid={!lastNameValid}
              errMsg={t('please-enter-a-valid-last-name')}
              onFocus={() => setLastNameValid(isLastNameValid(lastName))}
              required
            />
          </div>

          <div css={Styles.inputWrapper}>
            <Input
              type="email"
              name="email"
              label={t('email')}
              value={email}
              onChange={setEmail}
              invalid={!emailValid}
              errMsg={t('please-enter-a-valid-email')}
              onFocus={() => setEmailValid(isEmailValid(email))}
              required
            />
          </div>

          <div css={Styles.inputWrapper}>
            <div css={Styles.inputLabel}>{t('date-of-birth')} (DD/MM/YYYY)</div>
            <div css={Styles.datePickerWrapper}>
              <ScrollInputDate
                onChange={handleBirthDayChange}
                value={birthday}
                id="birthday-registration"
                name="birthday"
                placeholder={collectBirthYear ? 'dd/mm/yyyy' : 'dd/mm'}
                showYear={collectBirthYear}
                listHeight={196}
                modalWidth={collectBirthYear ? 240 : 180}
                numberOfElementInView={7}
                css={Styles.datePickerInput}
                monthsData={moment.months()}
              />
            </div>
            {!birthdayValid && birthday ? (
              <div css={Styles.errorMsg}>{t('please-enter-a-valid-birthday')}</div>
            ) : null}
          </div>

          <div css={Styles.inputWrapper}>
            <div css={Styles.inputLabel}>{t('gender')}</div>
            <select css={Styles.selectBox} value={gender} onChange={setGender}>
              <option value="" disabled />
              <option value="Male">{t('male')}</option>
              <option value="Female">{t('female')}</option>
              <option value="Other">{t('other')}</option>
            </select>
            {!genderValid ? (
              <div css={Styles.errorMsg}>{t('please-select-a-valid-gender')} </div>
            ) : null}
          </div>

          <div css={Styles.tip}>
            <Trans i18nKey="tocAndPrivacyPolicy">
              By signing up you agree to the
              <a
                href="https://letsumai.com/terms-and-conditions"
                target="_blank"
                onClick={() => openTermAndCondition()}
                rel="noopener noreferrer"
              >
                terms of use
              </a>
              and
              {/*  eslint-disable-next-line */}
              <a onClick={() => openPrivacyAndPolicy()} rel="noopener noreferrer">
                privacy policy
              </a>
            </Trans>
          </div>

          <button
            type="submit"
            css={allFieldsAreValid ? Styles.submitButton : Styles.disabledSubmitButton}
          >
            {t('join-the-loyalty-program')}
          </button>
        </form>
      </div>
    </>
  );
};

RegistrationStep.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  venueSetSettings: PropTypes.object.isRequired,
  hasErrorMessage: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  signupToVenueLoyaltyHandler: PropTypes.func.isRequired,
  goBackToPhoneNumberStep: PropTypes.func.isRequired,
};

export default RegistrationStep;
