import React from 'react';
import * as Styles from './Pen.styles';

const Pen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    css={Styles.svg}
  >
    <g transform="translate(0 -0.485)">
      <path
        className="a"
        d="M424.912,79.123a1.477,1.477,0,0,1-.345.16L417.7,81.451a.74.74,0,0,1-.746-.183l-.741-.741a.74.74,0,0,1-.183-.747l2.167-6.863a1.472,1.472,0,0,1,.365-.6L433.53,57.353a2.962,2.962,0,0,1,4.189,0l2.412,2.412a2.962,2.962,0,0,1,0,4.189L425.168,78.918A1.485,1.485,0,0,1,424.912,79.123Zm.144-2.187,9.893-9.893-4.507-4.507-9.893,9.893A6.307,6.307,0,0,1,425.056,76.937Zm-6.539-.1a6.321,6.321,0,0,1,2.13,2.13l3.1-.979a4.816,4.816,0,0,0-4.251-4.251Zm-.935,2.96.1.1,1.493-.471a4.836,4.836,0,0,0-1.126-1.126Zm21.5-16.891a1.481,1.481,0,0,0,0-2.095L436.672,58.4a1.481,1.481,0,0,0-2.094,0l-.868.868,4.507,4.507Zm-6.422-2.592-1.175,1.174L436,66l1.175-1.174Zm-3.486,5.094a.741.741,0,0,1,1.047,1.047l-5.184,5.184a.741.741,0,1,1-1.047-1.047Zm1.851,1.851a.741.741,0,0,1,1.047,1.047l-5.184,5.184a.74.74,0,0,1-1.047-1.047Z"
        transform="translate(-416 -56)"
      />
    </g>
  </svg>
);

Pen.propTypes = {};
export default Pen;
