import { matchPath } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { REACT_APP_SENTRY_DSN, isProductionEnv, REACT_APP_ENVIRONMENT } from 'constants/app-env';
import ROUTES from 'constants/routes';
import { getPartnerSession } from './session-helper';
import history from './router-history';
import packageJson from '../../package.json';
import { defaultTheme } from 'styles/theme';

// eslint-disable-next-line import/no-mutable-exports
export let sentryFeedbackDialog;

export const captureException = (error, exceptionOptions) =>
  Sentry.captureException(error, {
    tags: {
      environment: REACT_APP_ENVIRONMENT,
    },
    ...exceptionOptions,
  });

export const initSentry = () => {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    release: `loyalty@${packageJson.version}`,
    environment: `loyalty@${REACT_APP_ENVIRONMENT}`,
    ignoreErrors: [
      /ResizeObserver loop limit exceeded.*/,
      /ResizeObserver loop completed with undelivered notifications.*/,
      /SecurityError: The operation is insecure.*/,
      /SecurityError: Blocked a frame with origin.*/,
      'Unprocessable Entity',
      /SecurityError: The operation is insecure.*/,
      /The operation is insecure.*/,
      // Network errors such as going offline or being blocked by a proxy
      'Failed to fetch',
      // Random plugins/extensions
      'top.GLOBALS',
    ],
    denyUrls: [
      // Firefox extensions
      /^resource:\/\//i,
      // Chrome extensions
      /^extensions\//i,
      /^chrome:\/\//i,
    ],
    initialScope: {
      user: {
        id: getPartnerSession().currentVenueId,
        email: getPartnerSession().username,
      },
    },
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        routes: Object.values(ROUTES).map((route) => ({ path: route })),
        matchPath,
      }),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: isProductionEnv() ? 0.1 : 0.6,
    // Capture Replay for 5% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.001,
    replaysOnErrorSampleRate: 1.0,
  });
};

// Supported Lazy loading sentry integration:
// https://docs.sentry.io/platforms/javascript/configuration/integrations/#2-load-from-cdn-with-lazyloadintegration
export const addSentryIntegrations = () => {
  const addIntegrations = async () => {
    try {
      const replayIntegration = await Sentry.lazyLoadIntegration('replayIntegration');
      Sentry.addIntegration(
        replayIntegration({
          blockAllMedia: false,
          maskAllText: false,
          block: ['iframe'],
        })
      );
    } catch (error) {
      console.error(error);
    }

    try {
      const feedbackIntegration = await Sentry.lazyLoadIntegration('feedbackIntegration');
      const feedbackDialog = feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        id: getPartnerSession().currentVenueId,
        autoInject: false,
        colorScheme: 'light',
        showBranding: false,
        isEmailRequired: true,
        formTitle: 'Please share your feedback',
        buttonLabel: 'Provide feedback',
        submitButtonLabel: 'Submit',
        themeLight: {
          foreground: defaultTheme.text.primary,
          outline: defaultTheme.text.loyaltyGrey,
          accentBackground: defaultTheme.colors.primary,
          successColor: defaultTheme.colors.primary,
          errorColor: defaultTheme.colors.error,
        },
      });

      Sentry.addIntegration(feedbackDialog);
      sentryFeedbackDialog = await feedbackDialog.createForm();
      sentryFeedbackDialog.appendToDom();
    } catch (error) {
      console.error(error);
    }
  };

  // Later in your application
  // By calling this inside of `Sentry.onLoad()`, we can be sure the SDK has been initialized at this point.
  Sentry?.onLoad(addIntegrations);
};
