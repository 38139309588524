import React, { useContext, Suspense, useMemo } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import history from 'util/router-history';
import { setWebView } from 'util/device-helper';
import ROUTES from 'constants/routes';
import { OPERATIONAL_PERMISSIONS } from 'constants/operational-permission';
import { initI18next } from 'services/i18n';
import LoadingPage from 'pages/LoadingPage';
import HistoryPage from 'pages/HistoryPage';
import NavPage from 'pages/NavPage';
import LoginPage from 'pages/LoginPage';
import LogoutPage from 'pages/LogoutPage';
import CapturePage from 'pages/CapturePage';
import ReportsPage from 'pages/ReportsPage';
import GuestInterface from 'pages/GuestInterface';
import GuestSignup from 'pages/GuestSignup';
import PrivateRoute from 'components/PrivateRoute';
import { AppContext } from 'contexts/AppProvider';
import { PartnerContext } from 'contexts/PartnerProvider';
import InitTheme from './InitTheme';
import useGetIpInfo from 'hooks/useGetIpInfo';

function App() {
  const { partner } = useContext(PartnerContext);
  const { app } = useContext(AppContext);

  useGetIpInfo();

  // To avoid fetching and creating multiple instances of i18n, otherwise it fetches locales on every state changed.
  const i18nInstance = useMemo(
    () =>
      initI18next({
        languages: partner?.languages,
        defaultLanguage: partner?.defaultLanguage,
      }),
    // To avoid fetching and creating multiple instances of i18n, the dependency array includes `app.hasLoaded` instead of 'partner?.languages & partner?.defaultLanguage'.
    // This is because passing objects as dependencies would trigger the i18next initialization on every re-render due to their reference changes.
    // eslint-disable-next-line
    [app.hasLoaded]
  );

  setWebView();

  if (!app.hasLoaded) return <LoadingPage />;

  return (
    <div className="loyalty">
      <InitTheme />
      {/* // Not using Suspense will result in rendering translations before they load,
    //  which will cause the keys to be shown instead of the translated text. */}
      <Suspense fallback={<LoadingPage />}>
        <I18nextProvider i18n={i18nInstance}>
          <Router history={history}>
            <Switch>
              <PrivateRoute exact path="/" render={(props) => <LoginPage {...props} />} />
              <PrivateRoute
                exact
                path={`${ROUTES.CAPTURE}/:view?`}
                render={(props) => <CapturePage {...props} />}
              />
              <PrivateRoute
                exact
                allowedPermission={OPERATIONAL_PERMISSIONS.LOYALTY_MANAGEMENT_REPORTING}
                path={ROUTES.REPORTS}
                render={(props) => <ReportsPage {...props} />}
              />
              <PrivateRoute exact path={ROUTES.NAV} render={(props) => <NavPage {...props} />} />
              <PrivateRoute
                exact
                path={ROUTES.HISTORY}
                render={(props) => <HistoryPage {...props} />}
              />
              <Route exact path={ROUTES.LOGIN} render={(props) => <LoginPage {...props} />} />
              <Route exact path={ROUTES.LOGOUT} render={(props) => <LogoutPage {...props} />} />
              <Route
                exact
                path={`${ROUTES.GUEST_INTERFACE}/:venue/guests/search`}
                render={(props) => <GuestInterface {...props} />}
              />
              <Route
                exact
                path={`${ROUTES.GUEST_INTERFACE}/:venue/guests/registration`}
                render={(props) => <GuestSignup {...props} />}
              />
              {/* default redirect page when routes don't match */}
              <Route render={(props) => <LoginPage {...props} />} />
            </Switch>
          </Router>
        </I18nextProvider>
      </Suspense>
    </div>
  );
}

export default App;
