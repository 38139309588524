import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './Button.styles';

const Button = ({ children, type = 'primary', css = '', ...rest }) => (
  <StyledButton css={css} type={type} {...rest}>
    {children}
  </StyledButton>
);

Button.propTypes = {
  type: PropTypes.string.isRequired,
  css: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
};

export default Button;
