import { DEBOUNCE_TIME } from 'constants/numbers';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

// version from response - first param, local version second param
export const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const trueTypeOf = (obj) => Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

export const isTypeOfObject = (obj) => trueTypeOf(obj) === 'object';

export const debounceFunction = (callback, options) =>
  AwesomeDebouncePromise(callback, DEBOUNCE_TIME, options);

export const getObjectValues = (obj) => {
  const values = [];
  // eslint-disable-next-line no-restricted-syntax, no-unused-vars
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      values.push(obj[key]);
    }
  }
  return values;
};

export const stringToElementId = (str, elem = 'button') =>
  `${str.trim().replace(/\W+/g, '-').toLowerCase()}-${elem}`;
