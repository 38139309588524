import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import FIELD_OPTIONS from 'constants/data-field-options';
import { ERROR_TYPES } from 'util/errors';
import { assignEventListener } from 'util/event-helper';
import useFormField from 'hooks/useFormField';
import usePhoneNumField from 'hooks/usePhoneNumField';
import ModalWrapper from 'components/ModalWrapper';
import Input from 'components/Input';
import {
  addBirthdayYear,
  removeBirthdayYear,
  isBirthdayValid as validateBirthdayInput,
} from 'util/data-gathering-helper';
import ScrollInputDate from '@umai/scroll-input-date';
import Popup from 'components/Popup';
import * as Styles from './DataAcquisitionStep.styles';

const DataAcquisitionStep = (props) => {
  const [t] = useTranslation();
  const {
    customer: { id: customerId, firstName, lastName, gender, email, phone, birthday } = {},
    isLoading,
    hasDuplicateEmail,
    error,
    secondaryFieldsToCapture = {},
    addCustomerHandler,
    updateCustomerHandler,
    resetModalHandler,
  } = props;
  const [firstNameInput, onChangeFirstNameInput] = useFormField(firstName || '');
  const [lastNameInput, onChangeLastNameInput] = useFormField(lastName || '');
  const [genderInput, onChangeGenderInput] = useFormField(gender || '');
  const { collectBirthYear } = secondaryFieldsToCapture;

  const birthdayInitialValue = birthday ? removeBirthdayYear(birthday, collectBirthYear) : '';
  const [birthdayInput, onChangeBirthdayInput] = useState(birthdayInitialValue);
  const [birthdayValid, setBirthdayValid] = useState(true);
  const [emailInput, onChangeEmailInput] = useFormField(email || '');
  const [phoneNum, formattedPhoneNum, onChangePhoneNum, isPhoneNumValid] = usePhoneNumField(phone);
  const [showGenderPopover, setGenderShowPopover] = useState(false);
  const birthdayFormat = collectBirthYear ? 'dd/mm/yyyy' : 'dd/mm';

  const isAnExistingCustomer = customerId > 0;

  const handleGenderChange = (value) => {
    onChangeGenderInput({ target: { value } });
    setGenderShowPopover(false);
  };

  const saveCustomer = () => {
    const customerData = {
      firstName: firstNameInput,
      phone: phoneNum,
    };

    const emailField = secondaryFieldsToCapture.email;
    const birthdayField = secondaryFieldsToCapture.birthday;
    const genderField = secondaryFieldsToCapture.gender;
    const lastNameField = secondaryFieldsToCapture.lastName;

    if (emailField && emailField !== FIELD_OPTIONS.DISABLED) customerData.email = emailInput;
    if (birthdayField && birthdayField !== FIELD_OPTIONS.DISABLED)
      customerData.birthday = addBirthdayYear(birthdayInput);
    if (genderField && genderField !== FIELD_OPTIONS.DISABLED) customerData.gender = genderInput;
    if (lastNameField && lastNameField !== FIELD_OPTIONS.DISABLED)
      customerData.lastName = lastNameInput;

    if (isAnExistingCustomer) {
      customerData.customerId = customerId;
      return updateCustomerHandler(customerData);
    }
    return addCustomerHandler(customerData);
  };

  const renderLastNameField = () => {
    const lastNameField = secondaryFieldsToCapture.lastName;
    if (lastNameField && lastNameField !== FIELD_OPTIONS.DISABLED) {
      const label = t('last-name');
      return (
        <div css={Styles.inputWrapper}>
          <Input
            type="text"
            name="lastname"
            fieldState={lastNameField === FIELD_OPTIONS.MANDATORY ? 'REQUIRED' : 'OPTIONAL'}
            label={label}
            value={lastNameInput}
            onChange={onChangeLastNameInput}
          />
        </div>
      );
    }
    return <div />;
  };

  const renderEmailField = () => {
    const emailField = secondaryFieldsToCapture.email;
    if (emailField && emailField !== FIELD_OPTIONS.DISABLED) {
      const label = t('email');
      const inputWrapperStyles = hasDuplicateEmail
        ? Styles.inputWrapperWithError
        : Styles.inputWrapper;
      return (
        <div css={inputWrapperStyles}>
          <Input
            type="email"
            name="email"
            fieldState={emailField === FIELD_OPTIONS.MANDATORY ? 'REQUIRED' : 'OPTIONAL'}
            label={label}
            value={emailInput}
            onChange={onChangeEmailInput}
          />

          {hasDuplicateEmail ? (
            <div css={Styles.errMsg}>{t('email-has-already-been-taken')}</div>
          ) : null}
        </div>
      );
    }
    return <div />;
  };

  const renderBirthdayField = () => {
    const birthdayField = secondaryFieldsToCapture.birthday;
    if (birthdayField && birthdayField !== FIELD_OPTIONS.DISABLED) {
      const labelSubText = birthdayField === FIELD_OPTIONS.MANDATORY ? '' : ` (${t('optional')})`;

      return (
        <div css={Styles.inputWrapper}>
          <div css={Styles.inputLabel}>
            {t('birthday')}
            {labelSubText}
          </div>

          <div css={Styles.datePickerWrapper}>
            <ScrollInputDate
              onChange={(value) => {
                onChangeBirthdayInput(value);
                setBirthdayValid(validateBirthdayInput(value, birthdayFormat));
              }}
              value={birthdayInput}
              id="birthday-acquisition"
              name="birthday"
              placeholder={birthdayFormat}
              showYear={collectBirthYear}
              listHeight={196}
              modalWidth={collectBirthYear ? 240 : 180}
              numberOfElementInView={7}
              css={Styles.datePickerInput}
              monthsData={moment.months()}
            />
            {!birthdayValid && birthdayInput ? (
              <div css={Styles.errMsg}>{t('please-enter-a-valid-birthday')}</div>
            ) : null}
          </div>
        </div>
      );
    }
    return <div />;
  };

  const renderGenderField = () => {
    const genderField = secondaryFieldsToCapture.gender;
    if (genderField && genderField !== FIELD_OPTIONS.DISABLED) {
      const labelSubText = genderField === FIELD_OPTIONS.MANDATORY ? '' : ` (${t('optional')})`;

      return (
        <div css={Styles.inputWrapper}>
          <div css={Styles.inputLabel}>
            {t('gender')}
            {labelSubText}
          </div>
          <Popup
            open={showGenderPopover}
            onOpenChange={setGenderShowPopover}
            placement="topRight"
            triggerElement={
              <div css={Styles.selectBox({ noValue: !genderInput })}>
                {t(genderInput.toLocaleLowerCase())}
              </div>
            }
          >
            <div css={Styles.selectPopover}>
              <button
                type="button"
                onClick={() => {
                  handleGenderChange('Female');
                }}
              >
                {t('female')}
              </button>
              <button
                type="button"
                onClick={() => {
                  handleGenderChange('Male');
                }}
              >
                {t('male')}
              </button>
              <button
                type="button"
                onClick={() => {
                  handleGenderChange('Other');
                }}
              >
                {t('other')}
              </button>
            </div>
          </Popup>
        </div>
      );
    }
    return <div />;
  };

  const isFirstNameValid = () => firstNameInput;
  const isLastNameValid = () => {
    let isValid = false;
    if (secondaryFieldsToCapture.lastName === FIELD_OPTIONS.DISABLED) {
      isValid = true;
    } else if (secondaryFieldsToCapture.lastName === FIELD_OPTIONS.OPTIONAL) {
      if (lastNameInput.trim().length === 0) isValid = true;
      else isValid = !!lastNameInput;
    } else if (secondaryFieldsToCapture.lastName === FIELD_OPTIONS.MANDATORY) {
      isValid = !!lastNameInput;
    } else {
      isValid = false;
    }
    return isValid;
  };

  const isGenderValid = () => {
    let isValid = false;
    if (secondaryFieldsToCapture.gender === FIELD_OPTIONS.DISABLED) {
      isValid = true;
    } else if (secondaryFieldsToCapture.gender === FIELD_OPTIONS.OPTIONAL) {
      isValid = true;
    } else if (secondaryFieldsToCapture.gender === FIELD_OPTIONS.MANDATORY) {
      isValid = genderInput;
    } else {
      isValid = false;
    }
    return isValid;
  };

  const isBirthdayValid = () => {
    let isValid = false;
    if (secondaryFieldsToCapture.birthday === FIELD_OPTIONS.DISABLED) {
      isValid = true;
    } else if (secondaryFieldsToCapture.birthday === FIELD_OPTIONS.OPTIONAL) {
      isValid = true;
    } else if (secondaryFieldsToCapture.birthday === FIELD_OPTIONS.MANDATORY) {
      isValid = birthdayInput;
    } else {
      isValid = false;
    }
    return isValid;
  };

  const isEmailValid = () => {
    let isValid = false;
    if (secondaryFieldsToCapture.email === FIELD_OPTIONS.DISABLED) {
      isValid = true;
    } else if (secondaryFieldsToCapture.email === FIELD_OPTIONS.OPTIONAL) {
      isValid = true;
    } else if (secondaryFieldsToCapture.email === FIELD_OPTIONS.MANDATORY) {
      isValid = emailInput;
    } else {
      isValid = false;
    }
    return isValid;
  };

  const isCTAEnabled =
    isPhoneNumValid &&
    isFirstNameValid() &&
    isLastNameValid() &&
    isGenderValid() &&
    isBirthdayValid() &&
    isEmailValid();

  return (
    <div className="data-acquisition-step">
      <ModalWrapper
        wrapperSelector={'.data-acquisition-step'}
        resetModalHandler={resetModalHandler}
      >
        <div css={Styles.wrapper}>
          <div css={Styles.cusContainer}>
            <div css={Styles.cusContent}>
              <div css={Styles.sectionDesc}>{t('enter-guest-details')}</div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryContent}>
                  <Input
                    type="text"
                    name="phone"
                    label={t('phone-number')}
                    value={formattedPhoneNum}
                    onChange={onChangePhoneNum}
                  />
                  {error && error.name === ERROR_TYPES.PhoneAlreadyExistsError ? (
                    <div css={Styles.errMsg}>{t('this-phone-number-already-exists')}</div>
                  ) : null}
                </div>
              </div>
              <div css={Styles.fieldsWrapper}>
                <div css={Styles.inputWrapper}>
                  <Input
                    type="text"
                    name="firstname"
                    label={t('first-name')}
                    value={firstNameInput}
                    onChange={onChangeFirstNameInput}
                    autoFocus
                  />
                </div>
                {renderLastNameField()}
                {renderBirthdayField()}
                {renderEmailField()}
                {renderGenderField()}
              </div>
              <div css={Styles.cusFooter}>
                <button
                  id="continue-button"
                  css={isCTAEnabled ? Styles.CTA : Styles.disabledCTA}
                  type="button"
                  {...assignEventListener(isCTAEnabled ? saveCustomer : null)}
                >
                  {t('continue')}
                </button>
              </div>
            </div>
          </div>
          <div css={isLoading ? Styles.loadingOverlay : Styles.loadingOverlayHidden}>
            <ReactLoading type={'spin'} color={'#169A89'} width={50} />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

DataAcquisitionStep.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    gender: PropTypes.string,
    birthday: PropTypes.any,
    email: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  hasDuplicateEmail: PropTypes.bool,
  error: PropTypes.object,
  secondaryFieldsToCapture: PropTypes.object.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  addCustomerHandler: PropTypes.func,
  updateCustomerHandler: PropTypes.func,
};

export default DataAcquisitionStep;
