import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ModalWrapper from 'components/ModalWrapper';
import { getDateFormatter } from 'util/date-helper';
import { assignEventListener } from 'util/event-helper';
import * as Styles from './CloseConfirmationModal.styles';

const formatDate = getDateFormatter('ll');

const CloseConfirmationModal = (props) => {
  const [t] = useTranslation();
  const { confirmCloseReportHandler, cancelCloseReportHandler, date } = props;

  return (
    <div className="report-close-modal">
      <ModalWrapper
        wrapperSelector=".report-close-modal"
        resetModalHandler={cancelCloseReportHandler}
        small
      >
        <div css={Styles.wrapper}>
          <div css={Styles.contentContainer}>
            <div css={Styles.title}>{t('confirm-close')}</div>
            <div css={Styles.helpText}>
              {t('are-you-sure-you-want-to-mark-date-as-closed', { date: formatDate(date) })}
            </div>
            <button
              id="confirm-close-report-button"
              css={Styles.CTA}
              type="button"
              {...assignEventListener(confirmCloseReportHandler)}
            >
              {t('confirm')}
            </button>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

CloseConfirmationModal.propTypes = {
  confirmCloseReportHandler: PropTypes.func.isRequired,
  cancelCloseReportHandler: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
};

export default CloseConfirmationModal;
