import { formatNumber, cleanPhoneNumber } from 'util/number-helper';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import InputAlt from 'components/InputAlt';
import GuestSignUpHeader from 'components/GuestSignUpHeader';
import { useTranslation } from 'react-i18next';
import * as Styles from './PhoneNumberStep.styles';

// longest phone number would be 15 digits
// https://en.wikipedia.org/wiki/Telephone_numbering_plan
// so adding the internation code before it, let's assume that the maximum allowed length is 18
const MAX_PHONE_NUMBER_DIGITS = 18;

const PhoneNumberStep = (props) => {
  const {
    phoneNumberSearchHandler,
    phoneNumber: initialPhoneNumber,
    hasErrorMessage = false,
    errorMessage = '',
    venueSetSettings: { venueName, isLoyaltyProgramEnabled = false, countryIsoCode },
  } = props;
  const [phoneNumber, setPhoneNumber] = React.useState(initialPhoneNumber);
  const [isValidNumber, setPhoneNumberValidity] = React.useState(true);
  const [t] = useTranslation();

  useEffect(() => {
    setPhoneNumber(formatNumber(initialPhoneNumber));
  }, [initialPhoneNumber]);

  const handlePhoneNumberChange = (event) => {
    const phoneNum = (event?.target || {}).value || '';

    if (phoneNum.length <= MAX_PHONE_NUMBER_DIGITS) {
      setPhoneNumberValidity(true);
      setPhoneNumber(formatNumber(event?.target?.value, countryIsoCode));
    }
  };

  const handlePhoneNumberSearch = (event) => {
    event.preventDefault();
    const cleanNumber = cleanPhoneNumber(phoneNumber);
    if (cleanNumber.length >= 7) {
      phoneNumberSearchHandler(phoneNumber);
    } else {
      setPhoneNumberValidity(false);
    }
  };

  const renderErrorMessages = () =>
    hasErrorMessage ? (
      <div css={Styles.formWrapper}>
        <div css={Styles.errorMessageWrapper}>{errorMessage}</div>
      </div>
    ) : null;

  const renderForm = () => {
    if (isLoyaltyProgramEnabled && !hasErrorMessage) {
      return (
        <div css={Styles.formWrapper}>
          <form onSubmit={handlePhoneNumberSearch}>
            <div css={isValidNumber ? Styles.fieldWrapper : Styles.fieldWrapperWithError}>
              <span className="field-label">{t('enter-phone-number')}</span>
              <div css={Styles.phoneInputWrapper}>
                <InputAlt
                  id="phone"
                  type="text"
                  name="phone"
                  invalid={!isValidNumber}
                  errMsg={t('please-enter-a-valid-phone-number')}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  required
                />
              </div>
            </div>
            <div css={Styles.tip}>
              {t(`already-signed-up-simply-enter-your-number-and-well-send-an-sms-link`)}
            </div>

            <button type="submit" id="submit-phone-button" css={Styles.submitButton}>
              {t('get-started')}
            </button>
          </form>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <GuestSignUpHeader
        pageHeaderStyles={Styles.pageHeader}
        hasBackButton={false}
        venueName={venueName}
      />
      <h4 css={Styles.pageHeadingText}>
        {t('receive-instant-cashback-and-earn-loyalty-rewards-when-you-spend')}
      </h4>
      <div css={Styles.stepsWrapper}>
        <div css={Styles.stepDescWrapper}>
          <div css={Styles.stepIndex}>a</div>
          <div css={Styles.stepDesc}>{t('enter-your-phone-number-to-get-started')}</div>
        </div>
        <div css={Styles.stepDescWrapper}>
          <div css={Styles.stepIndex}>b</div>
          <div css={Styles.stepDesc}>{t('sign-up-start-earning-credit-and-rewards')}</div>
        </div>
        <div css={Styles.stepDescWrapper}>
          <div css={Styles.stepIndex}>c</div>
          <div css={Styles.stepDesc}>{t('enjoy-your-credit-claim-rewards-on-future-visits')}</div>
        </div>
      </div>
      {renderErrorMessages()}
      {renderForm()}
    </>
  );
};

PhoneNumberStep.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  venueSetSettings: PropTypes.object.isRequired,
  hasErrorMessage: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  phoneNumberSearchHandler: PropTypes.func.isRequired,
};

export default PhoneNumberStep;
