import {
  UnauthorizedError,
  InvalidOTPError,
  ExpiredOTPError,
  InvalidTransactionError,
} from 'util/errors';
import { hasError } from 'util/error-helper';
import i18n from 'services/i18n';
import { redeemEndpoints } from './endpoints';
import request from './request';
import { getDefaultAuthHeaders } from './api-util';

export const redeemAmount = async ({
  token,
  currentVenueId,
  loyaltyAccountId,
  amount,
  customerId,
  idempotencyKey,
} = {}) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');

  try {
    const loyaltyTransaction = {
      amount: Number(amount),
      transaction_type: 'redemption',
      note: '',
    };
    if (loyaltyAccountId) loyaltyTransaction.loyalty_account_id = loyaltyAccountId;

    const url = redeemEndpoints.redeemAmountPOST(currentVenueId);
    const defaultAuthHeaders = getDefaultAuthHeaders(token);
    const response = await request(url, {
      method: 'POST',
      headers: {
        ...defaultAuthHeaders,
        'Idempotency-Key': idempotencyKey,
      },
      body: JSON.stringify({
        loyalty_transaction: loyaltyTransaction,
        guest_id: customerId,
      }),
    });

    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (hasError(resData, 'Authorization token is invalid')) {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    } else if (e?.response?.status === 404) return {};
    throw e;
  }
};

export const submitOTP = async ({ token, currentVenueId, transactionId, otp } = {}) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');

  try {
    const url = redeemEndpoints.submitOTPPOST(transactionId);
    const defaultAuthHeaders = getDefaultAuthHeaders(token);
    const response = await request(url, {
      method: 'POST',
      headers: {
        ...defaultAuthHeaders,
      },
      body: JSON.stringify({ otp_code: String(otp) }),
    });

    return response;
  } catch (e) {
    let resData = null;
    await e.response.json().then((data) => {
      resData = data;
    });

    if (e?.response?.status === 401) {
      if (hasError(resData, 'Authorization token is invalid')) {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    } else if (e?.response?.status === 422) {
      if (hasError(resData, 'OTP code is invalid')) {
        throw new InvalidOTPError(i18n.t('the-code-you-entered-was-incorrect'));
      } else if (hasError(resData, 'OTP code is expired')) {
        throw new ExpiredOTPError(i18n.t('the-code-you-entered-has-expired'));
      } else if (hasError(resData, 'Transaction is not valid')) {
        throw new InvalidTransactionError(i18n.t('redeem-transaction-is-not-valid'));
      }
    } else if (e?.response?.status === 404) return {};
    throw e;
  }
};

export const resendOTP = async ({ token, currentVenueId, transactionId } = {}) => {
  if (!currentVenueId) throw new Error('currentVenueId invalid');

  try {
    const url = redeemEndpoints.resendOTPPOST(transactionId);
    const defaultAuthHeaders = getDefaultAuthHeaders(token);
    const response = await request(url, {
      method: 'GET',
      headers: {
        ...defaultAuthHeaders,
      },
    });

    return response;
  } catch (e) {
    let resData = null;
    await e.response.json().then((data) => {
      resData = data;
    });

    if (e?.response?.status === 401) {
      if (hasError(resData, 'Authorization token is invalid')) {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    } else if (e?.response?.status === 422) {
      if (hasError(resData, 'OTP code is invalid')) {
        throw new InvalidOTPError(i18n.t('the-code-you-entered-was-incorrect'));
      } else if (hasError(resData, 'OTP code is expired')) {
        throw new ExpiredOTPError(i18n.t('the-code-you-entered-has-expired'));
      } else if (hasError(resData, 'Transaction is not valid')) {
        throw new InvalidTransactionError(i18n.t('redeem-transaction-is-not-valid'));
      }
    } else if (e?.response?.status === 404) return {};
    throw e;
  }
};
