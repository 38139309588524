import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { savePartnerSession, getPartnerSession, removeAllCookies } from 'util/session-helper';
import { getVenueCurrencyAndCountryCode, getPhonePrefixByCountryISO } from 'util/locale-helper';
import { getErrorMessage } from 'util/error-helper';
import { DEFAULT_LANGUAGE } from 'constants/app-env';
import ROUTES from 'constants/routes';
import { CHANNELS } from 'constants/app-const';
import { setIntercom } from 'util/intercom';
import history from 'util/router-history';
import { showErrorMessage } from '@umai/common';
import { AppContext } from 'contexts/AppProvider';
import {
  validateToken as validateTokenGET,
  getPartnerDetails as getPartnerDetailsGET,
  getPaymentProfiles,
  fetchCurrentUser,
} from 'api/partner';
import { fetchSettings as fetchSettingsGET } from 'api/venue';
import useSocket from 'hooks/useSocket';
import i18n from 'services/i18n';
import { setVenueIntercom, setVenueTimeZone } from 'services/venue';

/*
  - app is considered loaded only after auth is complete
*/

const initialContext = {
  isAuthenticated: false,
  username: undefined,
  currentUser: null,
  token: undefined,
  country: '',
  currencyDenomination: '',
  phonePrefix: '',
  currentVenueId: undefined,
  currentVenueName: undefined,
  venues: [],
  venueSettings: {},
  color: {},
  languages: [],
  defaultLanguage: DEFAULT_LANGUAGE,
  paymentProfiles: {},
  isLoyaltyProgramEnabled: false,
  isBlockedDueToPayment: false,
  partnerId: null,
};

const PartnerContext = React.createContext(initialContext);

const PartnerProvider = ({ children }) => {
  const {
    setAppState,
    app: { isAppIdle = false },
  } = useContext(AppContext);
  const [partner, setPartner] = useState(initialContext);
  const { token: accessToken, username: loginUserName } = partner;

  const { subscribeChannel, unsubscribeChannel } = useSocket();

  // Using useRef here to get the latest value of `isAppIdle` otherwise it would use the old value
  // even after context change
  // eslint-disable-next-line camelcase
  const isAppIdleLATEST = React.useRef(isAppIdle);
  const onReceived = () => {
    if (isAppIdleLATEST.current) {
      window.location.reload();
    } else {
      // this will be used later in the flow wrapper on the `resetDataHandler`
      // when the user closes the popup and there's new changes in the loyalty settings
      setAppState({ shouldReloadAppOnPageIdle: true });
    }
  };

  const logoutPartner = () => {
    setIntercom({ tag: 'shutdown' });
    removeAllCookies();
    setPartner(initialContext);
    history.push(ROUTES.LOGIN);
  };

  const authPartner = ({ username, token }) => {
    setPartner((p) => ({ ...p, username, token, isAuthenticated: true }));
  };

  const setCurrentVenue = async ({ currentUser, currentVenue } = {}) => {
    if (currentVenue?.id && currentUser?.id) {
      try {
        const currentVenueId = currentVenue.id;

        savePartnerSession({ currentVenueId });

        // unsubscribe current venue
        unsubscribeChannel();

        // subscribe to venue changes
        subscribeChannel({
          token: accessToken,
          onReceived,
          data: { venue_id: currentVenueId },
          channel: CHANNELS.loyaltyChannel,
        });

        subscribeChannel({
          token: accessToken,
          onReceived: (user) => {
            // We check if the accessibleVenues have been updated, and if the current user's access to the current venue has been revoked,
            //  then we log out the current user.
            if (
              Array.isArray(user.accessibleVenues) &&
              !user.accessibleVenues.includes(+currentVenueId)
            ) {
              history.push(ROUTES.LOGOUT);
            }
            setPartner((p) => ({ ...p, currentUser: { ...p.currentUser, ...user } }));
          },
          data: { user_id: currentUser.id },
          channel: CHANNELS.userChannel,
        });

        if (i18n?.isInitialized) {
          const currentLanguage = currentVenue.defaultLanguage.language || DEFAULT_LANGUAGE;
          i18n.changeLanguage(currentLanguage);
          moment.locale(currentLanguage);
        }

        // get loyalty settings of venue
        const venueSettings = await fetchSettingsGET({ token: accessToken, currentVenueId });

        setPartner((p) => ({
          ...p,
          currentVenueId,
          venueSettings,
          currentVenueName: currentVenue.name,
          defaultLanguage: currentVenue.defaultLanguage,
          languages: currentVenue.languages,
          isLoyaltyProgramEnabled: currentVenue.isLoyaltyProgramEnabled,
          isBlockedDueToPayment: currentVenue.isBlockedDueToPayment,
        }));

        setVenueTimeZone(currentVenue);

        // init chat box
        setVenueIntercom({
          venueName: currentVenue.name,
          venueId: currentVenueId,
          username: loginUserName,
        });
      } catch (e) {
        console.error('Yo, venue settings FAILED! Call Help... 😭', e);
      }
    } else {
      console.error('Required state values missing during partner state auth');
    }
  };

  const updateToken = (token) => {
    if (token) {
      savePartnerSession({ token });
      // set user immutably
      setPartner((p) => ({ ...p, token }));
    } else {
      throw new Error('token missing or invalid');
    }
  };

  const changeVenue = async (venueId) => {
    const { currentUser, venues } = partner;
    const currentVenue = venues.find((v) => +v.id === +venueId);

    setAppState({ hasLoaded: false });
    await setCurrentVenue({ currentUser, currentVenue });
    setAppState({ hasLoaded: true });
  };

  // Used on the public guest facing pages to set the languages after the venue is fetched
  const updateLanguages = (newLanguages) => {
    setPartner((currentPartner) => ({ ...currentPartner, languages: newLanguages }));
  };

  const getPaymentProfileDetails = async () => {
    try {
      const profiles = {};
      const { billingEntities } = await getPaymentProfiles(accessToken);

      billingEntities.forEach((profile) => {
        const { venueIds, warnings = [], ...restOfProfile } = profile;
        venueIds.forEach((venueId) => {
          profiles[venueId] = {
            ...restOfProfile,
            // sorting to move the smallest warning text on the top ( asc order ), so that button and svg are not overlapping on desktop mode
            warnings: warnings.sort((a, b) => a.length - b.length),
            isVisible: true,
          };
        });
      });

      setPartner((p) => ({ ...p, paymentProfiles: profiles }));
    } catch (error) {
      const errorMessage = await getErrorMessage(error);
      showErrorMessage(errorMessage);
    }
  };

  const setVisiblePaymentWarning = (isVisible) => {
    const { paymentProfiles, currentVenueId } = partner;

    setPartner((p) => ({
      ...p,
      paymentProfiles: {
        ...paymentProfiles,
        [currentVenueId]: { ...paymentProfiles[currentVenueId], isVisible },
      },
    }));
  };

  const selectVenuePaymentProfiles = () => {
    const { paymentProfiles, currentVenueId } = partner;
    return paymentProfiles[currentVenueId];
  };

  /**
   * If there is payment profile for a venue and
   * 1. show warning is false - that is the venue is paying the subscription fees to umai or
   * 2. is access blocked - that is venue has not paid the subscription fees to umai and is blocked
   * 3. or if the venue has closed the warning by clicking close icon
   * 4. and there are warning present for the payment profile
   * Then don't show the warning/
   * */
  const selectIsVenueInPaymentWarning = () => {
    const venuePaymentProfile = selectVenuePaymentProfiles();

    return (
      venuePaymentProfile &&
      venuePaymentProfile.showWarnings &&
      !venuePaymentProfile.isAccessBlocked &&
      venuePaymentProfile.isVisible &&
      !!venuePaymentProfile.warnings.length
    );
  };

  const selectIsVenueAccessBlocked = () => {
    const venuePaymentProfile = selectVenuePaymentProfiles();
    return venuePaymentProfile?.isAccessBlocked || partner.isBlockedDueToPayment;
  };

  const fetchPartnerDetails = async () => {
    try {
      setAppState({ hasLoaded: false });

      const partnerDetailsRes = await getPartnerDetailsGET(accessToken);
      const {
        venues,
        country: { countryIsoCode = '', currencyCode } = {},
        partner: { color, id: partnerId },
      } = partnerDetailsRes;
      let defaultVenueId;
      const { currentVenueId } = getPartnerSession();
      const venueIds = venues.map((venue) => venue.id);

      if (venueIds.includes(+currentVenueId)) {
        defaultVenueId = +currentVenueId;
      } else {
        defaultVenueId = venues[0].id;
      }

      const phonePrefix = getPhonePrefixByCountryISO(countryIsoCode);
      const currentVenue = venues.find((v) => +v.id === +defaultVenueId);
      const currencyDenomination = getVenueCurrencyAndCountryCode(currentVenue, currencyCode);

      // get the current user logged to set Venue
      // NOTE: fetchCurrentUser is called here to allow user to login and to show access denied page
      const currentUser = await fetchCurrentUser(accessToken);

      // Updating Partner specific changes
      setPartner((p) => ({
        ...p,
        partnerId,
        venues,
        currencyDenomination,
        phonePrefix,
        color,
        currentUser,
      }));

      // Updating Venue specific changes
      await setCurrentVenue({ currentUser, currentVenue });
    } catch (e) {
      setAppState({ errorOnLoad: true, hasLoaded: true });
      console.error('Yo, partner details fetch failed! Call Help... 😭', e);
    } finally {
      // app will show loading bar until auth is complete
      setAppState({ hasLoaded: true });
    }
  };

  // set partner session from cookies into to state if user already logged-in
  useEffect(() => {
    const { username, token } = getPartnerSession();

    async function validateTokenAndPartner() {
      try {
        await validateTokenGET({ token });
        authPartner({ username, token });
      } catch (error) {
        // initial state of hasLoaded is false, hence need to mark it as true to show login screen.
        setAppState({ hasLoaded: true });
        // auth error'ed so we should handle this in login page
        console.error('Yo, session auth FAILED! Call Help... 😭', error);
        history.push(ROUTES.LOGOUT);
      }
    }

    // Allow public signup pages (guest registration and search) to display without validating the token
    if (!window.location.pathname.includes(ROUTES.GUEST_INTERFACE)) {
      validateTokenAndPartner();
    } else {
      // initial state of hasLoaded is false, hence need to mark it as true
      // to show GUEST_INTERFACE pages which are: GuestInterface, GuestSignup.
      setAppState({ hasLoaded: true });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loginUserName && accessToken) {
      getPaymentProfileDetails();
      fetchPartnerDetails();
    }

    // eslint-disable-next-line
  }, [loginUserName, accessToken]);

  useEffect(() => {
    isAppIdleLATEST.current = isAppIdle;
  }, [isAppIdle]);

  return (
    <PartnerContext.Provider
      // eslint-disable-next-line
      value={{
        partner,
        authPartner,
        updateToken,
        changeVenue,
        logoutPartner,
        updateLanguages,
        setVisiblePaymentWarning,
        selectVenuePaymentProfiles,
        selectIsVenueInPaymentWarning,
        selectIsVenueAccessBlocked,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

PartnerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PartnerProvider;
export { PartnerContext };
