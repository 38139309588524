import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { Trans, useTranslation } from 'react-i18next';
import ROUTES from 'constants/routes';
import { assignEventListener } from 'util/event-helper';
import { ERROR_TYPES } from 'util/errors';
import history from 'util/router-history';
import { PartnerContext } from 'contexts/PartnerProvider';
import useFormField from 'hooks/useFormField';
import ModalWrapper from 'components/ModalWrapper';
import Input from 'components/Input';
import Button from 'components/Button';
import Amount from 'components/Amount';
import { validatePassword } from 'api/transaction-validation';
import * as Styles from './PasswordValidation.styles';

const PasswordValidation = (props) => {
  const {
    customer: { firstName } = {},
    transactionId,
    resetModalHandler,
    locale,
    amount = '',
    title = '',
    buttonText = '',
    authorizationSuccessHandler,
    authorizationErrorHandler,
  } = props;
  const [t] = useTranslation();
  const {
    partner: { token, currentVenueId },
  } = React.useContext(PartnerContext);

  const [password, onChangePassword] = useFormField('');
  const [isLoading, setLoadingState] = React.useState(false);
  const [isAuthorized, setAuthorizationState] = React.useState(true);

  const submitPasswordHandler = async () => {
    try {
      setLoadingState(true);
      const data = await validatePassword({
        token,
        currentVenueId,
        transactionId,
        password,
      });
      setAuthorizationState(true);
      // success callback
      authorizationSuccessHandler(data);
    } catch (e) {
      setLoadingState(false);
      setAuthorizationState(false);
      if (e.name === ERROR_TYPES.UnauthorizedError) {
        history.push(ROUTES.LOGOUT);
      } else {
        // error callback
        authorizationErrorHandler(e);
        // error scenario
        console.error('Something blew up while validating password', e);
      }
    }
  };

  const renderAmount = () => (
    <Amount currency={locale.currencyDenomination} amount={Number(amount).toFixed(2)} />
  );

  return (
    <div className="password-validation-step">
      <ModalWrapper
        wrapperSelector={'.password-validation-step'}
        resetModalHandler={resetModalHandler}
      >
        <div css={Styles.container}>
          <div css={Styles.contentWrapper}>
            <div css={Styles.title}>
              <Trans
                i18nKey="password-validation-title"
                values={{
                  title,
                  firstName,
                }}
                components={{
                  Amount: renderAmount(),
                }}
              />
            </div>
            <div css={Styles.helpText}>{t('enter-your-password-to-continue')}</div>
            <div css={Styles.passWrapper}>
              <Input
                type="password"
                name="password"
                label={t('password')}
                onChange={onChangePassword}
                autoFocus
              />
              {!isAuthorized ? (
                <div css={Styles.errorMsg}>
                  <span>{t('authentication-failed')}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div css={Styles.CTAWrapper}>
            <Button
              css={Styles.CTA}
              type="primary"
              {...assignEventListener(() => submitPasswordHandler(password))}
            >
              {buttonText}
            </Button>
          </div>
          <div css={isLoading ? Styles.loadingOverlay : Styles.loadingOverlayHidden}>
            <ReactLoading type={'spin'} color={'#169A89'} width={50} />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

PasswordValidation.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
  }),
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  transactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  authorizationSuccessHandler: PropTypes.func.isRequired,
  authorizationErrorHandler: PropTypes.func.isRequired,
};

export default PasswordValidation;
