import { css } from '@emotion/react';
import { breakpoints } from '@styles/media-queries';

const weirdDarkGrey = '#5d6572';

export const pageWrapper = css`
  text-align: center;
`;

export const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 2rem;
`;

export const topRow = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  body.venue-access-blocked & {
    display: none;
  }
`;

export const bottomRow = css`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem;
  width: 100%;
  position: relative;
`;

export const title = css`
  font-size: 1.25rem;
  font-weight: bold;
  padding: 1rem 0;
  color: ${weirdDarkGrey};
`;

const baseInputStyles = css`
  margin: 0.75rem 0;
  min-width: 20rem;
  max-width: 20rem;

  ${breakpoints.smallOnly} {
    min-width: 17.5rem;
  }
`;

export const datePickerWrapper = (content) => css`
  margin: 0.75rem 0;

  position: relative;
  &:before {
    padding: 0.25rem 1rem 0;
    font-size: 0.75rem;

    content: '${content}';
    position: absolute;
    top: 0;
    left: 0;
    color: var(--text-grey);
    z-index: 1;
  }

  &:after {
    padding: 0.25rem 1rem 0;
    font-size: 0.75rem;

    content: ' ';
    position: absolute;
    top: 1.75rem;
    right: 1.125rem;
    color: var(--text-grey);

    border: solid var(--text-grey);
    border-width: 0 2px 2px 0;
    padding: 4px;
    transform: rotate(45deg);
  }

  .react-datepicker-popper {
    z-index: 5;
  }
`;

export const datePickerInput = css`
  border: 0;
  appearance: none;
  margin: 0 0 1rem;
  background: #fff;
  border-bottom: 2px solid var(--border-alternate);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 1.25rem 0rem 0rem;
  color: ${weirdDarkGrey};
  text-align: center;

  transition: border 300ms ease;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--text-grey);
  }

  ${baseInputStyles};
  margin: 0;

  .SingleDatePicker {
    .DateInput {
      width: 100%;
    }
  }

  .SingleDatePicker_picker {
    .DayPicker {
      border-radius: 1rem;
    }

    .CalendarMonth_table {
      td {
        text-align: center;
        vertical-align: middle;
        border-radius: 50%;
        border: 0;
      }
    }
    .CalendarMonth_table {
      border-collapse: separate;
      border-spacing: 0;
    }

    .CalendarMonth_caption {
      text-transform: capitalize;
    }

    .CalendarDay {
      padding 10px !important;
    }

    .CalendarDay:hover {
      background: var(--color-primary);
      color: white;
    }

    .DayPicker_transitionContainer {
      border-radius: 1rem;
      min-height: 340px !important;
    }

    .CalendarDay__blocked_out_of_range {
      cursor: default;

      &:hover {
        background: #fff;
        color: #cacccd;
      }
    }

    .CalendarDay__firstDayOfWeek {
      padding-left: 0.96667rem !important;
    }

    .CalendarDay__lastDayOfWeek {
      padding-right: 0.96667rem !important;
    }
  }
`;

export const input = css`
  ${baseInputStyles};
`;

export const targetText = css`
  font-size: 0.875rem;
  color: ${weirdDarkGrey};
  margin: 1rem 0;
`;

export const prevReportsWrapper = css`
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

export const reportSectionTitle = css`
  font-size: 1rem;
  font-weight: 500;
  padding: 0.25rem 0;
`;

const reportEntryWrapperBase = css`
  display: flex;
  padding: 0.25rem 0;
`;

export const reportEntryWrapperDefault = css`
  ${reportEntryWrapperBase};
  color: ${weirdDarkGrey};
`;

export const reportEntryWrapperWarning = css`
  ${reportEntryWrapperBase};
  color: var(--color-error);
`;

export const reportDate = css``;
export const reportStatus = css``;

const baseCTAStyles = css`
  border: none;
  outline: none;
  appearance: none;
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
  margin: 0.5rem 0;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  cursor: pointer;
`;

export const CTASubmit = css`
  ${baseCTAStyles};
  width: 100%;
  max-width: 20rem;
`;

export const disabledCTASubmit = css`
  ${CTASubmit};
  background-color: var(--color-grey);
  color: white;
  width: 100%;
  max-width: 20rem;
  cursor: default;
`;

export const CTAClosed = css`
  ${baseCTAStyles};
  background-color: transparent;
  padding: 0.375rem 2rem;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
`;

export const disabledCTAClosed = css`
  ${CTAClosed};
  background-color: transparent;
  border: 2px solid var(--color-grey);
  color: var(--color-grey);
  cursor: default;
`;

export const logoutWrapper = css`
  display: flex;
  a {
    border-bottom: 1.25px solid currentColor;
    &:hover {
      color: var(--color-primary);
    }
  }
`;

export const copied = css`
  position: relative;
  font-size: 0.75rem;
  color: #5d6572;
`;

export const appVersion = css`
  font-size: 0.75rem;
  opacity: 0.8;
  padding: 0 1rem;
`;

export const checkmark = css`
  color: #dfdfdf;
`;

export const fixedToBottom = css`
  margin-top: auto;
`;
