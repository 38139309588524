import { css } from '@emotion/react';

export const notesWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

export const notesLink = css`
  text-decoration: underline;
  display: flex;
  align-items: center;
  color: var(--color-primary);
  padding: 1rem 1.5rem;
  margin-bottom: -0.75rem;
  cursor: pointer;
`;

export const notesFieldWrapper = css`
  height: 0px;
  display: none;
  transition: height 0.25s;
  position: relative;
`;

export const visibleNotesFieldWrapper = css`
  ${notesFieldWrapper}
  height: 45px;
  display: flex;
`;
export const notesField = css`
  border: none;
  border-bottom: 1px solid #ddd;
  background: transparent;
  min-width: 350px;
  margin-top: 1rem;
  padding-right: 2rem;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
`;

export const saveNoteBtn = css`
  background: transparent;
  border: none;
  border: 1px solid #ddd;
  font-size: calc(1rem - 3px);
  min-height: 30px;
  min-width: 90px;
`;

export const removeNote = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 1rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1rem;
`;
