import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'util/number-helper';
import history from 'util/router-history';
import { ERROR_TYPES } from 'util/errors';
import {
  getDisplayDateFromServerTime,
  getDisplayTimeFromServerTime,
  getDateFormatter,
} from 'util/date-helper';
import ROUTES from 'constants/routes';
import { PartnerContext } from 'contexts/PartnerProvider';
import { fetchHistory as fetchHistoryGET } from 'api/venue';
import AppHelmet from 'components/AppHelmet';
import ModalWrapper from 'components/ModalWrapper';
import Amount from 'components/Amount';
import Popover from 'components/Popover';
import Layout from 'components/Layout';
import { Note } from 'components/Icons';
import * as Styles from './HistoryPage.styles';

const getHumanizedDateTime = getDateFormatter('lll');

const HistoryPage = () => {
  const [t] = useTranslation();
  const {
    partner: { token, currentVenueId, currentVenueName, currencyDenomination },
  } = useContext(PartnerContext);
  const [transactions, setTransactions] = useState(undefined);
  useEffect(() => {
    fetchHistoryGET({ token, currentVenueId })
      .then((data) => {
        const { loyaltyTransactions = [] } = data;
        setTransactions(loyaltyTransactions);
      })
      .catch((e) => {
        if (e.name === ERROR_TYPES.UnauthorizedError) {
          history.push('/logout');
        } else {
          // error
          console.error('Error occured while fetching history', e);
        }
      });
    // eslint-disable-next-line
  }, []);

  const redirectToDefaultPageHandler = () => {
    history.push(ROUTES.NAV);
  };

  const renderTransactionNote = (transaction = {}, children = null) => {
    const { createdAt, note = '' } = transaction;
    if (note) {
      return (
        <Popover
          content={
            <div>
              <div>{note}</div>
              <div css={Styles.noteDate}>{getHumanizedDateTime(createdAt)}</div>
            </div>
          }
          triggerWrapperCss={Styles.triggerWrapperCss}
        >
          <Note />
          {children}
        </Popover>
      );
    }
    return null;
  };

  const renderTransactions = () => {
    // to use loaded here
    if (!transactions) return null;

    if (!transactions.length) return <center>{t(`you-dont-have-any-transactions-yet`)}</center>;

    return (
      <table css={Styles.table}>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('phone')}</th>
            <th>{t('action')}</th>
            <th>{t('date')}</th>
            <th>{t('status')}</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => {
            const { name, phone, createdAt, amount, transactionType, status } = transaction;
            const formattedPhone = '+'.concat(phone.replace('+', ''));
            const createdAtDate = getDisplayDateFromServerTime(createdAt);
            const createdAtTime = getDisplayTimeFromServerTime(createdAt);
            const statusText = t(`transactions.statuses.${status}`);
            let statusBG = Styles.statusSuccess;
            switch (status) {
              case 'success': {
                statusBG = Styles.statusSuccess;
                break;
              }
              case 'failed': {
                statusBG = Styles.statusFailed;
                break;
              }
              case 'pending': {
                statusBG = Styles.statusPending;
                break;
              }
              default: {
                statusBG = Styles.statusPending;
                break;
              }
            }

            return (
              <tr key={transaction.id}>
                <td aria-label="Guest name" css={Styles.nameTD}>
                  {/* eslint-disable-next-line */}
                  <a css={Styles.notesTrigger}>{renderTransactionNote(transaction)}</a>
                  {name}
                </td>
                <td aria-label="Phone" css={Styles.phoneTD}>
                  {formatNumber(formattedPhone)}
                </td>
                <td aria-label="Action" css={Styles.actionTD}>
                  {t(`transactions.types.${transactionType}`)}

                  <br />
                  <Amount currency={currencyDenomination} amount={amount} />
                </td>
                <td aria-label="Created date" css={Styles.dateTD}>
                  {createdAtDate}
                  <br />
                  {createdAtTime}
                </td>
                <td aria-label="Status" css={Styles.statusTD}>
                  <span css={statusBG}>{statusText}</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div css={Styles.pageWrapper}>
      <AppHelmet title={`${t('loyalty')} | History`} description={`${t('loyalty')} | History`} />
      <Layout showBackground hasDarkBackground showPaymentProfile showBackButton>
        <div css={Styles.container}>
          <div className="history-modal-wrapper">
            <ModalWrapper
              wrapperSelector={'.history-modal-wrapper'}
              resetModalHandler={redirectToDefaultPageHandler}
              scrollableArea={'body'}
              useMouseUpListener
            >
              <div css={Styles.title}>{currentVenueName}</div>
              <div css={Styles.tableWrapper}>{renderTransactions()}</div>
            </ModalWrapper>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default HistoryPage;
