import { css } from '@emotion/react';
import iconDropdown from 'images/chevron-dropdown-grey.svg';

export {
  pageWrapper,
  pageHeader,
  headerBackButton,
  container,
  formWrapper,
  phoneNumberWrapper,
  phoneNumberLabel,
  phoneNumber,
  tip,
  fieldWrapper,
  submitButton,
  disabledSubmitButton,
  poweredByUMAIWrapper,
  errorMessageWrapper,
} from '../../GuestSignup.styles';

const leadingTextSize = css`
  font-size: 1.35rem;
`;

export const pageHeadingText = css`
  color: var(--text-primary);
  ${leadingTextSize}
  font-weight: normal;
  margin: 1.5rem 0;
  margin-bottom: 0rem;
`;

export const fieldsWrapper = css`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: 0.5rem;
`;

const inputBaseStyles = css`
  margin: 1rem 0;
`;

export const inputWrapper = css`
  ${inputBaseStyles}

  input {
    ${leadingTextSize}
  }
`;

export const inputWrapperWithError = css`
  ${inputBaseStyles}
  input {
    border-bottom-color: var(--color-error);
  }
`;

export const inputLabel = css`
  text-align: left;
  color: var(--text-grey);
`;

export const datePickerWrapper = css`
  li {
    margin: 0;
  }
`;

const baseInputStyles = css`
  width: 100%;
`;

export const datePickerInput = css`
  border: 0;
  appearance: none;
  background: transparent;
  border-bottom: 1px solid var(--text-grey);
  overflow: hidden;
  ${leadingTextSize}

  transition: border 300ms ease;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--text-grey);
  }

  ${baseInputStyles};
  margin: 0;
`;

const selectBoxBaseStyles = css`
  padding: 1px 0rem;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  ${leadingTextSize}

  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url(${iconDropdown}) transparent no-repeat center right !important; /* !important used for overriding all other customisations */
  background: url(${iconDropdown}) transparent no-repeat center right !important; /* Better placement regardless of input width */
  background-size: 1.25rem !important;
  width: 100%;
  border-bottom: 1px solid var(--text-grey);

  &::-ms-expand {
    /* for IE 11 */
    display: none;
  }

  option {
    color: var(--text-primary);
  }
`;

export const selectBox = css`
  ${selectBoxBaseStyles}
`;

export const selectBoxEmpty = css`
  ${selectBoxBaseStyles}
  color: var(--text-grey);
`;

export const errorMsg = css`
  color: var(--color-error);
  font-size: 0.9rem;
`;
