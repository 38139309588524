import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'util/number-helper';
import { assignEventListener } from 'util/event-helper';
import ModalWrapper from 'components/ModalWrapper';
import { CoinsWithDots } from 'components/Icons';
import Amount from 'components/Amount';
import * as Styles from './HandOverStep.styles';

const HandOverStep = (props) => {
  const [t] = useTranslation();
  const {
    customer: { firstName, lastName, phone, balance } = {},
    handOverRedirectHandler,
    resetModalHandler,
    locale,
    isNewCustomer = false,
  } = props;

  const renderBalance = () => {
    if (isNewCustomer) return <div />;
    return (
      <div css={Styles.entryWrapper}>
        <div css={Styles.entryLabel}>{t('balance')}</div>
        <div css={Styles.entryContent}>
          <Amount currency={locale.currencyDenomination} amount={balance} />
        </div>
      </div>
    );
  };

  return (
    <div className="hand-over-step">
      <ModalWrapper wrapperSelector={'.hand-over-step'} resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <div css={Styles.contentContainer}>
            <div css={Styles.coinsIcon}>
              <CoinsWithDots />
            </div>
            <div css={Styles.thankYou}>{t('thank-you')}</div>
            <div css={Styles.waiterText}>{t('your-waiter-will-help-you-proceed-from-here')}</div>

            <div css={Styles.customerDetails}>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('name')}</div>
                <div css={Styles.entryContent}>
                  {firstName} {lastName}
                </div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('phone-number')}</div>
                <div css={Styles.entryContent}>{formatNumber(phone)}</div>
              </div>
              {renderBalance()}
            </div>

            <button
              id="hand-over-button"
              css={Styles.CTA}
              type="button"
              {...assignEventListener(handOverRedirectHandler)}
            >
              {t('hand-over-the-device')}
            </button>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

HandOverStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  handOverRedirectHandler: PropTypes.func.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
  isNewCustomer: PropTypes.bool,
};

export default HandOverStep;
