import { formatNumber } from 'util/number-helper';
import { useState } from 'react';
import useFormField from './useFormField';

const deformatPhoneNum = (num = '') => {
  const stringifiedNum = String(num);
  const deformattedNum = stringifiedNum.replace('+', '').replace(/\s/g, '');
  return deformattedNum;
};

// no space — no alpha — plus in the beginning - only numbers
const isValidPhoneInput = (phoneNumber) => /^[\s()+]*([0-9]*){0,20}$/.test(phoneNumber);

function usePhoneNumField(initialVal = '') {
  const [phone, onChangePhone] = useFormField(deformatPhoneNum(initialVal));
  const [isValid, setValid] = useState(true);

  const formattedPhone = phone.length ? formatNumber(phone) : phone;

  function onChange(e) {
    const val = String(e.target.value);
    const deformattedPhoneNum = deformatPhoneNum(val);
    // eslint-disable-next-line no-restricted-globals
    if (!isValidPhoneInput(deformattedPhoneNum)) return;
    onChangePhone({ target: { value: deformattedPhoneNum } });

    if (!deformattedPhoneNum) {
      setValid(false);
    } else if (deformattedPhoneNum.length < 7) {
      setValid(false);
    } else {
      // no validation failures
      // set to valid only if not valid already (save perf cost)
      // eslint-disable-next-line no-lonely-if
      if (!isValid) setValid(true);
    }
  }

  return [phone, formattedPhone, onChange, isValid];
}

export default usePhoneNumField;
