import PropTypes from 'prop-types';
import useElementAuthorization from 'hooks/useElementAuthorization';

const ProtectedElement = ({ children, allowedPermission }) => {
  const isAccessAuthorized = useElementAuthorization(allowedPermission);

  if (isAccessAuthorized) {
    return children;
  }

  return null;
};

ProtectedElement.propTypes = {
  children: PropTypes.node.isRequired,
  allowedPermission: PropTypes.string.isRequired,
};

export default ProtectedElement;
