import ROUTES from 'constants/routes';
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PartnerContext } from 'contexts/PartnerProvider';
import useRouteAuthorization from 'hooks/useRouteAuthorization';
import AuthorizationMessage from 'components/AuthorizationMessage';
import Subscription from 'components/Subscription';

const PrivateRoute = ({ render, children, isPage, path, allowedPermission, ...rest }) => {
  const { partner } = useContext(PartnerContext);
  const { isAuthenticated = false, isLoyaltyProgramEnabled } = partner;
  const isAccessAuthorized = useRouteAuthorization(allowedPermission, path);

  if (isAuthenticated) {
    if (isAccessAuthorized) {
      if (!isLoyaltyProgramEnabled) {
        return <Subscription />;
      }
      /**
       * isPage specifies whether this route is accessed via a "view=" query parameter
       * rather than a defined route in react-router-dom.
       *
       * If true, the component will render its children directly instead of
       * wrapping them in a <Route> component.
       */
      return isPage ? children : <Route {...rest} render={render} />;
    }
    return <AuthorizationMessage />;
  }
  return <Redirect to={ROUTES.LOGIN} />;
};

PrivateRoute.propTypes = {
  render: PropTypes.func,
  children: PropTypes.node,
  isPage: PropTypes.bool,
  path: PropTypes.string.isRequired,
  allowedPermission: PropTypes.string,
};

export default PrivateRoute;
