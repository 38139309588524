import React from 'react';

const HorizontalDots = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
    <g fill="currentColor" fillRule="evenodd">
      <circle cx="5" cy="12" r="2" />
      <circle cx="12" cy="12" r="2" />
      <circle cx="19" cy="12" r="2" />
    </g>
  </svg>
);

HorizontalDots.propTypes = {};
export default HorizontalDots;
