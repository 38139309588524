import { css } from '@emotion/react';

export const container = css`
  min-height: calc(100vh - (3rem * 2) - (1.5rem * 2));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const contentWrapper = css`
  align-self: flex-start;
  flex: 1;
`;

export const title = css`
  padding: 0.5rem 0;
  font-weight: bold;
  font-size: 1.375rem;
`;

export const helpText = css`
  font-weight: bold;
`;

export const passWrapper = css`
  padding: 2rem 0;
`;

export const CTAWrapper = css`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CTA = css`
  min-height: 3rem;
  min-width: 12.5rem;
`;

export const errorMsg = css`
  font-size: 0.875rem;
  color: var(--color-error);
`;

const baseOverlayStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 5px;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.75);
`;

export const loadingOverlay = css`
  ${baseOverlayStyles}
`;

export const loadingOverlayHidden = css`
  ${baseOverlayStyles}
  display: none;
`;
