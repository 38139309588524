import React from 'react';
import { useTranslation } from 'react-i18next';
import AppName from 'components/AppName';
import AccessMessage from 'components/AccessMessage';
import NavLayout from 'components/NavLayout';
import { openPowerByUmai } from 'util/url';

const AuthorizationMessage = () => {
  const [t] = useTranslation();

  return (
    <NavLayout showAppName={false} title="Subscription">
      <AccessMessage
        header={<AppName type="theme" />}
        title={t('access-denied')}
        description={t('sorry-you-do-not-have-authorization-to-access')}
        subTitle={t('access-message.subtitle')}
        footer={
          <button
            type="button"
            onClick={() => {
              openPowerByUmai();
            }}
          >
            {t('access-message.footer')}
          </button>
        }
      />
    </NavLayout>
  );
};

export default AuthorizationMessage;
