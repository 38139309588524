import { registerLocale } from 'react-datepicker';
// Needs to be updated every time support for a new language gets added to the system
import pt from 'date-fns/locale/pt';
import ru from 'date-fns/locale/ru';
import id from 'date-fns/locale/id';
import da from 'date-fns/locale/da';
import es from 'date-fns/locale/es';

registerLocale('pt', pt);
registerLocale('ru', ru);
registerLocale('id', id);
registerLocale('da', da);
registerLocale('es', es);
