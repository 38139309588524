/* eslint-disable import/no-self-import */
/* eslint-disable no-unused-vars */
import { UnauthorizedError } from 'util/errors';
import { memberInterfaceEndpoints } from './endpoints';
import request from './request';

export const searchPhoneNumber = async ({ venueSlug, phoneNumber, authToken } = {}) => {
  if (!venueSlug) throw new Error('venueSlug invalid');
  if (!phoneNumber) throw new Error('phoneNumber invalid');

  try {
    let url = memberInterfaceEndpoints.fetchPublicAccountGET({
      venueSlug,
      phoneNumber,
    });
    if (authToken) {
      url = memberInterfaceEndpoints.fetchSecureAccountGET({
        venueSlug,
        phoneNumber,
        authToken,
      });
    }
    const response = await request(url);

    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (resData.error && resData.error === 'Authorization token is invalid') {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    } else if (e?.response?.status === 404) return {};
    throw e;
  }
};

export const fetchAvailableCampaigns = async ({ venueSlug, tokenId, authToken } = {}) => {
  if (!venueSlug) throw new Error('venueSlug invalid');
  if (!tokenId) throw new Error('tokenId invalid');

  try {
    let url = memberInterfaceEndpoints.fetchAvailableCampaignsGET({
      venueSlug,
      tokenId,
    });
    if (authToken) {
      url = memberInterfaceEndpoints.fetchSecureAvailableCampaignsGET({
        venueSlug,
        tokenId,
        authToken,
      });
    }

    const response = await request(url);

    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (resData.error && resData.error === 'Authorization token is invalid') {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    } else if (e?.response?.status === 404) return {};
    throw e;
  }
};

export const fetchAccountHistory = async ({ venueSlug, tokenId, page = 1 } = {}) => {
  if (!venueSlug) throw new Error('venueSlug invalid');
  if (!tokenId) throw new Error('tokenId invalid');

  try {
    const url = memberInterfaceEndpoints.fetchAccountHistoryGET({
      venueSlug,
      tokenId,
      page,
    });
    const response = await request(url);

    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (resData.error && resData.error === 'Authorization token is invalid') {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    } else if (e?.response?.status === 404) return {};
    throw e;
  }
};

export const toggleSmsSubscription = async ({ venueSlug, tokenId } = {}) => {
  if (!venueSlug) throw new Error('venueSlug invalid');
  if (!tokenId) throw new Error('tokenId invalid');

  try {
    const url = memberInterfaceEndpoints.toggleSmsSubscriptionGET({
      venueSlug,
      tokenId,
    });
    const response = await request(url);

    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      let resData = null;
      await e.response.json().then((data) => {
        resData = data;
      });
      if (resData.error && resData.error === 'Authorization token is invalid') {
        throw new UnauthorizedError('Authorization token is invalid');
      }
    } else if (e?.response?.status === 404) return {};
    throw e;
  }
};
