import { getVenueSlugFromURL, getPhoneNumberFromURL, getAuthTokenFromURL } from 'util/url';
import { isTypeOfObject } from 'util/helpers';
import {
  getCountryIsoCode,
  getVenueCurrencyAndCountryCode,
  getPhonePrefixByCountryISO,
} from 'util/locale-helper';
import { DEFAULT_LANGUAGE } from 'constants/app-env';
import React, { useEffect, useContext } from 'react';
import {
  searchPhoneNumber as searchPhoneNumberGET,
  fetchAccountHistory as fetchAccountHistoryGET,
  fetchAvailableCampaigns as fetchAvailableCampaignsGET,
  toggleSmsSubscription as toggleSmsSubscriptionGET,
} from 'api/member-interface';
import { fetchVenueSettings as fetchVenueSettingsGET } from 'api/signup';
import { ThemeContext } from 'contexts/ThemeProvider';
import { PartnerContext } from 'contexts/PartnerProvider';
import i18n from 'services/i18n';
import { setVenueTimeZone } from 'services/venue';

export const STEPS = {
  ACCOUNT_DETAILS: 'ACCOUNT_DETAILS',
  ACCOUNT_HISTORY: 'ACCOUNT_HISTORY',
};

const ACTIONS = {
  SET_PHONE: 'SET_PHONE',
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_ACCOUNT_HISTORY: 'SET_ACCOUNT_HISTORY',
  SET_VENUE_AND_TOKEN: 'SET_VENUE_AND_TOKEN',
  SET_AVAILABLE_CAMPAIGNS: 'SET_AVAILABLE_CAMPAIGNS',
  SET_LOYALTY_SMS_SUBSCRIPTION: 'SET_LOYALTY_SMS_SUBSCRIPTION',
  SET_PAGINATION_PAGE: 'SET_PAGINATION_PAGE',
  RESET_HISTORY_DATA: 'RESET_HISTORY_DATA',
  GOTO_STEP: 'GOTO_STEP',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_VENUE_DATA: 'SET_VENUE_DATA',
};

const initialState = {
  step: STEPS.ACCOUNT_DETAILS,
  customer: {
    id: 0,
    isFound: false,
    phone: '',
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    birthday: null,
    balance: 0,
    showSubscriptionToggle: false,
    isSubscribedToLoyaltySMS: false,
    accountTokenId: null,
    availableCampaigns: [],
    transactionsHistory: [],
    totalTransactionCount: 0,
    hasMoreTransactions: true,
    currentTier: {},
    nextTier: {},
    tierPercentage: {},
  },
  venueName: '',
  currentVenueSlug: '',
  secureToken: '',
  locale: {
    currencyDenomination: '',
    phonePrefix: '',
    languages: [],
    defaultLanguage: DEFAULT_LANGUAGE,
  },
  paginationPage: 1,
  hasErrorMessage: false,
  errorMessage: '',
  hasLoaded: false,
  venue: null,
  isBlocked: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_PHONE: {
      const prevState = { ...state };
      const customer = { ...prevState.customer, phone: action.payload };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_CUSTOMER: {
      const prevState = { ...state };
      const customer = {
        ...prevState.customer,
        isFound: true,
        ...action.payload,
      };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_VENUE_AND_TOKEN: {
      const prevState = { ...state };
      return { ...prevState, ...action.payload };
    }
    case ACTIONS.SET_AVAILABLE_CAMPAIGNS: {
      const prevState = { ...state };
      const customer = {
        ...prevState.customer,
        ...action.payload,
      };
      return { ...prevState, customer };
    }
    case ACTIONS.SET_LOYALTY_SMS_SUBSCRIPTION: {
      const prevState = { ...state };
      const customer = {
        ...prevState.customer,
        ...action.payload,
      };
      return { ...prevState, customer };
    }
    case ACTIONS.RESET_HISTORY_DATA: {
      const prevState = { ...state };
      const customer = {
        ...prevState.customer,
        transactionsHistory: [],
        totalTransactionCount: 0,
      };
      return { ...prevState, customer, paginationPage: 1 };
    }
    case ACTIONS.SET_PAGINATION_PAGE: {
      const prevState = { ...state };
      return { ...prevState, ...action.payload };
    }
    case ACTIONS.GOTO_STEP: {
      const prevState = { ...state };
      const { STEP: step, ...rest } = action.payload;
      return { ...prevState, step, ...rest };
    }
    case ACTIONS.SET_ERROR_MESSAGE: {
      const { errorMessage, hasErrorMessage } = action.payload;
      return {
        ...state,
        errorMessage,
        hasErrorMessage,
      };
    }
    case ACTIONS.SET_VENUE_DATA: {
      const prevState = { ...state };
      return { ...prevState, ...action.payload };
    }
    default:
      throw new Error('Invalid action dispatched');
  }
};

const useWrapper = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    step,
    customer,
    venueName,
    secureToken,
    locale,
    paginationPage = 1,
    hasErrorMessage,
    errorMessage,
    isBlocked,
    hasLoaded,
  } = state;
  const { updateThemeColors } = useContext(ThemeContext);
  const { updateLanguages } = useContext(PartnerContext);

  const getAvailableCampaigns = async (options) => {
    let {
      currentVenueSlug: venueSlug,
      customer: { accountTokenId: tokenId },
      secureToken: authToken,
    } = state;

    if (isTypeOfObject(options)) {
      ({ venueSlug, tokenId, authToken } = options);
    }

    const data = await fetchAvailableCampaignsGET({
      venueSlug,
      tokenId,
      authToken,
    });

    const { loyaltyCampaignLogs = [] } = data;

    dispatch({
      type: ACTIONS.SET_AVAILABLE_CAMPAIGNS,
      payload: { availableCampaigns: loyaltyCampaignLogs },
    });
  };

  const phoneNumberSearchHandler = async (phoneNumber, options) => {
    try {
      let { currentVenueSlug: venueSlug, secureToken: authToken } = state;

      if (isTypeOfObject(options)) {
        ({ venueSlug, authToken } = options);
      }

      const data = await searchPhoneNumberGET({
        venueSlug,
        authToken,
        phoneNumber,
      });

      const {
        id,
        phone,
        firstName,
        lastName = '',
        email,
        gender,
        birthday = null,
        balanceAmount,
        // currencyCode,
        loyaltyCampaignSmsSubscription,
        locale: guestLanguage,
        showSubscription,
        tokenId,
        currentTier = {},
        nextTier = {},
        tierPercentage = {},
      } = data;
      // We want to make sure to open loyalty with the guest language when
      // they click on the link from the SMS we send them to view their loyalty info.
      i18n.changeLanguage(guestLanguage);

      if (authToken) {
        // get tokens
        await getAvailableCampaigns({
          venueSlug,
          tokenId,
          authToken,
        });
      }
      dispatch({
        type: ACTIONS.SET_CUSTOMER,
        payload: {
          id,
          phone,
          firstName,
          lastName: lastName || '',
          email,
          gender,
          birthday: birthday || '',
          balance: balanceAmount,
          accountTokenId: tokenId,
          transactionsHistory: [],
          isSubscribedToLoyaltySMS: loyaltyCampaignSmsSubscription,
          showSubscriptionToggle: showSubscription,
          currentTier,
          nextTier,
          tierPercentage,
        },
      });

      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: STEPS.ACCOUNT_DETAILS },
      });
    } catch (e) {
      // error scenario
      console.error('Something blew up while searching for phone number', e);
      dispatch({
        type: ACTIONS.SET_ERROR_MESSAGE,
        payload: {
          hasErrorMessage: true,
          errorMessage: 'Something went wrong while searching for phone number',
        },
      });
    }
  };

  useEffect(() => {
    // in case phone number is included in the URL params, automatically search for the guest
    const venueSlug = getVenueSlugFromURL();
    const phoneNumber = getPhoneNumberFromURL();
    const authToken = getAuthTokenFromURL();

    dispatch({
      type: ACTIONS.SET_VENUE_AND_TOKEN,
      payload: { secureToken: authToken, currentVenueSlug: venueSlug },
    });

    if (!!venueSlug && !!phoneNumber) {
      phoneNumberSearchHandler(phoneNumber, {
        venueSlug,
        authToken,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const venueSlug = getVenueSlugFromURL();
      try {
        const currentVenue = await fetchVenueSettingsGET({ venueSlug });
        const {
          color,
          languages,
          defaultLanguage,
          address,
          currencyCode,
          isBlockedDueToPayment,
          hasActiveSubscriptions,
          name,
        } = currentVenue || {};

        const currencyDenomination = getVenueCurrencyAndCountryCode(currentVenue, currencyCode);
        const phonePrefix = getPhonePrefixByCountryISO(
          getCountryIsoCode(address?.country, currencyCode)
        );

        if (color?.enabled) updateThemeColors(color);
        setVenueTimeZone(currentVenue);
        updateLanguages(languages);
        dispatch({
          type: ACTIONS.SET_VENUE_DATA,
          payload: {
            isBlocked: isBlockedDueToPayment || !hasActiveSubscriptions,
            locale: { currencyDenomination, phonePrefix, languages, defaultLanguage },
            venueName: name,
            hasLoaded: true,
          },
        });
      } catch (err) {
        console.error('Error in fetching venue from slug', venueSlug, err);
        dispatch({
          type: ACTIONS.SET_ERROR_MESSAGE,
          payload: {
            hasErrorMessage: true,
            errorMessage: err.message || '',
          },
        });
      }
    })();
    // eslint-disable-next-line
  }, []);

  const getAccountHistoryHandler = async (page = 1) => {
    try {
      const {
        currentVenueSlug: venueSlug,
        customer: { accountTokenId: tokenId },
      } = state;

      const data = await fetchAccountHistoryGET({
        venueSlug,
        tokenId,
        page,
      });

      const { loyaltyTransactions = [], totalTransactionCount } = data;

      // get previously fetched loyalty transactions and merge them together with the new
      // transactions
      const { transactionsHistory = [] } = customer;
      const hasMoreTransactions =
        transactionsHistory.length + loyaltyTransactions.length < totalTransactionCount;

      dispatch({
        type: ACTIONS.SET_CUSTOMER,
        payload: {
          transactionsHistory: transactionsHistory.concat(loyaltyTransactions),
          totalTransactionCount,
          hasMoreTransactions,
        },
      });

      dispatch({
        type: ACTIONS.GOTO_STEP,
        payload: { STEP: STEPS.ACCOUNT_HISTORY },
      });

      dispatch({
        type: ACTIONS.SET_PAGINATION_PAGE,
        payload: { paginationPage: page },
      });
    } catch (e) {
      console.error(e);

      dispatch({
        type: ACTIONS.SET_CUSTOMER,
        payload: {
          transactionsHistory: [],
          totalTransactionCount: 0,
          hasMoreTransactions: false,
        },
      });
      // error scenario
      console.error('Something blew up while fetching account history');
    }
  };

  const toggleSmsSubscriptionHandler = async () => {
    try {
      const {
        currentVenueSlug: venueSlug,
        customer: { accountTokenId: tokenId },
      } = state;

      const data = await toggleSmsSubscriptionGET({
        venueSlug,
        tokenId,
      });

      const { loyaltyCampaignSmsSubscription = [] } = data;

      dispatch({
        type: ACTIONS.SET_LOYALTY_SMS_SUBSCRIPTION,
        payload: {
          isSubscribedToLoyaltySMS: loyaltyCampaignSmsSubscription,
        },
      });
      dispatch({
        type: ACTIONS.SET_ERROR_MESSAGE,
        payload: {
          hasErrorMessage: false,
          errorMessage: '',
        },
      });
    } catch (e) {
      // error scenario
      console.error('Something blew up while toggling subscription', e);
      dispatch({
        type: ACTIONS.SET_ERROR_MESSAGE,
        payload: {
          hasErrorMessage: true,
          errorMessage: e.message || '',
        },
      });
    }
  };

  const goBackToAccountDetails = () => {
    dispatch({
      type: ACTIONS.RESET_HISTORY_DATA,
      payload: {},
    });
    dispatch({
      type: ACTIONS.GOTO_STEP,
      payload: { STEP: STEPS.ACCOUNT_DETAILS },
    });
  };

  const isUsingSecureLink = (secureToken || '').trim().length > 0;

  return {
    step,
    customer,
    locale,
    venueName,
    paginationPage,
    phoneNumberSearchHandler,
    getAccountHistoryHandler,
    goBackToAccountDetails,
    isUsingSecureLink,
    toggleSmsSubscriptionHandler,
    hasErrorMessage,
    errorMessage,
    isBlocked,
    hasLoaded,
  };
};

export default useWrapper;
