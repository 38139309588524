import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import imageHeader from 'images/loyalty-inteface-header.jpeg';
import { useTranslation } from 'react-i18next';

const GuestSignUpHeader = ({
  pageHeaderStyles = {},
  headerBackButtonStyles = {},
  hasBackButton = false,
  goBackHandler = () => {},
  venueName = '',
}) => {
  const [t] = useTranslation();

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--bg-image', `url(${imageHeader})`);
  }, []);

  return (
    <div css={pageHeaderStyles}>
      {hasBackButton && (
        // eslint-disable-next-line
        <a onClick={goBackHandler} css={headerBackButtonStyles}>
          <span className="go-back">
            &#10229;
            <span className="go-back-text">{t('back')}</span>
          </span>
        </a>
      )}
      {venueName} {t('loyalty')}
    </div>
  );
};

GuestSignUpHeader.propTypes = {
  pageHeaderStyles: PropTypes.object,
  headerBackButtonStyles: PropTypes.object,
  hasBackButton: PropTypes.bool,
  goBackHandler: PropTypes.func,
  venueName: PropTypes.node,
};

export default GuestSignUpHeader;
