import { css } from '@emotion/react';
import { breakpoints } from '@styles/media-queries';

export const linksWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  padding: 1rem 2rem;
  flex: 1;
  border-radius: 15px;
  min-width: 30rem;
  max-height: 30rem;

  ${breakpoints.smallOnly} {
    min-width: 20rem;
  }
`;

export const links = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > a {
    margin: 0.5rem 0;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #ffffff;
    color: var(--color-primary);
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.25px;
    padding: 1.5rem 0rem;
    cursor: pointer;
    min-width: 380px;
    max-height: 70px;

    display: flex;
    align-items: center;
    justify-content: center;

    ${breakpoints.smallOnly} {
      min-width: 15rem;
    }
  }
`;

export const link = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > svg {
    position: absolute;
    left: 1rem;
  }
`;

export const baseIconStyles = css`
  min-width: 15rem;
  width: 100%;
`;

export const addCashbackIcon = css`
  ${baseIconStyles};
  svg {
    .cls-1 {
      fill: #fff;
    }
    .cls-2,
    .cls-4 {
      fill: var(--color-primary);
    }
    .cls-3,
    .cls-4 {
      stroke: none;
    }
  }
`;

export const redeemCreditIcon = css`
  ${baseIconStyles};
  svg {
    .cls-1 {
      fill: #fff;
    }
    .cls-2,
    .cls-4 {
      fill: var(--color-primary);
    }
    .cls-2 {
      stroke: var(--color-primary);
      stroke-miterlimit: 10;
    }
    .cls-3,
    .cls-4 {
      stroke: none;
    }
  }
`;

export const deductCreditIcon = css`
  ${baseIconStyles};
  svg {
    .cls-1 {
      fill: #fff;
    }
    .cls-2,
    .cls-4 {
      fill: var(--color-primary);
    }
    .cls-3,
    .cls-4 {
      stroke: none;
    }
  }
`;

export const viewHistoryIcon = css`
  ${baseIconStyles};
  svg {
    .cls-1 {
      fill: #fff;
    }
    .cls-2,
    .cls-4 {
      fill: var(--color-primary);
    }
    .cls-3,
    .cls-4 {
      stroke: none;
    }
  }
`;
