import React from 'react';

const RemoveIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <clipPath id="a">
        <rect width="16" height="16" fill="none" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path d="M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z" fill="#a4a4a4" />
      <rect
        width="6.429"
        height="1.286"
        rx="0.643"
        transform="translate(6.182 5.272) rotate(45)"
        fill="#fff"
      />
      <rect
        width="1.286"
        height="6.429"
        rx="0.643"
        transform="translate(9.819 5.273) rotate(45)"
        fill="#fff"
      />
    </g>
  </svg>
);

RemoveIcon.propTypes = {};
export default RemoveIcon;
