import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalWrapper from 'components/ModalWrapper';
import AmountPad from 'components/Amount/AmountPad';
import CustomerDetails from 'components/CustomerDetails';
import * as Styles from './AddAmountStep.styles';

const AddAmountStep = (props) => {
  const [t] = useTranslation();
  const {
    customer: {
      firstName,
      lastName,
      phone,
      balance,
      email,
      gender,
      birthday,
      currentTier,
      memberSince,
      renewalDate,
    } = {},
    dataCaptureFlow,
    secondaryFieldsToDisplay = {},
    addAmountHandler,
    resetModalHandler,
    locale,
  } = props;

  const renderCustomer = () => (
    <CustomerDetails
      Styles={Styles}
      customer={{
        firstName,
        lastName,
        phone,
        balance,
        gender,
        email,
        birthday,
        currentTier,
        memberSince,
        renewalDate,
      }}
      dataCaptureFlow={dataCaptureFlow}
      secondaryFieldsToDisplay={secondaryFieldsToDisplay}
      locale={locale}
      amountLabel={t('available-credit')}
      popOverLinks={['claim', 'redeemAmount', 'redeemCampaign', 'deduct']}
    />
  );

  return (
    <div className="add-loyalty-step">
      <ModalWrapper wrapperSelector={'.add-loyalty-step'} resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <div css={Styles.cusContainer}>{renderCustomer()}</div>
          <AmountPad
            Styles={Styles}
            header={t('enter-the-bonus-amount-to-add')}
            currencyDenomination={locale.currencyDenomination}
            isAmountGreaterThanBalance={() => false}
            isAmountCTAEnabled={() => true}
            errMsg=""
            amountHandler={addAmountHandler}
            amountButton={t('add-extra-credit')}
          />
        </div>
      </ModalWrapper>
    </div>
  );
};

AddAmountStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
    birthday: PropTypes.string,
    currentTier: PropTypes.object,
    balance: PropTypes.string,
    memberSince: PropTypes.string,
    renewalDate: PropTypes.string,
  }),
  dataCaptureFlow: PropTypes.string.isRequired,
  secondaryFieldsToDisplay: PropTypes.object,
  addAmountHandler: PropTypes.func.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
};

export default AddAmountStep;
