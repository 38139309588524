import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Pen, RemoveIcon } from 'components/Icons';
import * as Styles from './AddNote.styles';

const AddNote = ({ noteChangeHandler }) => {
  const [t] = useTranslation();
  const [isNoteFieldVisible, setNoteFieldVisibility] = React.useState(false);
  const [note, setTransactionNote] = React.useState('');

  const handleNoteChange = (event) => {
    let newNote = event.target.value || '';
    if (note.trim().length === 0) newNote = newNote.trim();
    setTransactionNote(newNote);
  };

  useEffect(() => {
    noteChangeHandler(note);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  return (
    <div css={Styles.notesWrapper}>
      {/* eslint-disable-next-line */}
      <a css={Styles.notesLink} onClick={() => setNoteFieldVisibility(true)}>
        <Pen />
        {t('add-notes')}
      </a>

      <div css={isNoteFieldVisible ? Styles.visibleNotesFieldWrapper : Styles.notesFieldWrapper}>
        <input value={note} css={Styles.notesField} onChange={handleNoteChange} />
        {note ? (
          // eslint-disable-next-line
          <a onClick={() => setTransactionNote('')} css={Styles.removeNote}>
            <RemoveIcon />
          </a>
        ) : null}
      </div>
    </div>
  );
};

AddNote.propTypes = {
  noteChangeHandler: PropTypes.func,
};

export default AddNote;
