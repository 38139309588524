import { css } from '@emotion/react';
import { breakpoints } from '@styles/media-queries';

const weirdDarkGrey = '#5d6572';
export const wrapper = css`
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${breakpoints.smallOnly} {
    flex-direction: column;
  }
`;

export const insContainer = css`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-bgGrey);
  padding-top: 2rem;
  body.venue-in-payment-warning & {
    padding-top: 1rem;
  }
`;

export const insContent = css`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 3rem 1rem 2rem;
`;

export const restoName = css`
  font-weight: 500;
  font-size: 1.65rem;
  color: ${weirdDarkGrey};
`;

export const title = css`
  font-weight: bold;
  font-size: 2rem;
  color: #000;
`;

export const desc = css`
  padding: 0.5rem 0 1.5rem 0;
  font-size: 1.25rem;
  font-weight: 300;
`;

export const stepDescWrapper = css`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 300;
`;

export const stepIndex = css`
  background-color: var(--color-alternate);
  color: var(--color-primary);
  font-weight: bold;
  border-radius: 50%;
  padding-bottom: 2px;
  min-width: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: bold;
`;

export const stepDesc = css`
  padding: 0 0 0 1rem;
  text-align: left;
  line-height: 1.2;
  font-weight: 300;
`;

export const insFooter = css`
  min-height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
`;

export const backButton = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
  color: var(--color-primary);
  font-size: 20px;
  cursor: pointer;

  svg {
    margin-bottom: 0;
    margin-right: 0.5rem;

    path {
      fill: var(--color-primary);
    }
  }

  body.venue-access-blocked & {
    display: none;
  }
`;

export const bottomRow = css`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 2rem 0;
  width: 100%;
  position: relative;
`;

export const fixedToBottom = css`
  margin-top: auto;
`;

export const poweredWrapper = css``;

// instructions container styles go below
