import { css } from '@emotion/react';
import iconDropdown from 'images/chevron-dropdown.svg';
import iconDropdownDark from 'images/chevron-dropdown-dark.svg';

const venueDropdownBaseStyles = css`
  padding: 0rem 1.5rem;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;

  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url(${iconDropdownDark}) transparent no-repeat center right !important; /* !important used for overriding all other customisations */
  background: url(${iconDropdownDark}) transparent no-repeat center right !important; /* Better placement regardless of input width */
  background-size: 1.25rem !important;

  &:hover {
    box-shadow: 0 1px 0 0 currentColor;
  }
`;

export const venueDropdown = css`
  ${venueDropdownBaseStyles}
`;

export const venueDropdownLight = css`
  ${venueDropdownBaseStyles}

  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url(${iconDropdown}) transparent no-repeat center right !important; /* !important used for overriding all other customisations */
  background: url(${iconDropdown}) transparent no-repeat center right !important; /* Better placement regardless of input width */
  background-size: 1.25rem !important;

  color: #ffffff;
`;

export const venueNamesPopup = css`
  width: 200px;
  font-size: 1rem;
  font-weight: 400;
  max-height: 50vh;
  overflow-y: auto;

  & > * {
    border: 0;
    background-color: #fff;
    width: 100%;
    color: var(--text-primary);
    border-bottom: 1px solid #d9d9d9;
    padding: 0.75rem;
    text-align: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    :first-of-type {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    :last-of-type {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      border-bottom: none;
    }
  }
`;
