import { css } from '@emotion/react';

export const button = css`
  border: none;
  outline: none;
  appearance: none;
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
`;

export const capitalize = css`
  text-transform: capitalize;
`;

export const wrapper = css`
  // to couterfeit modal default padding 2rem
  margin: -2rem;
  min-height: calc(100vh - (3rem * 2));
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2rem;
`;

// customer container styles go below
export const eligibleCampaignsContainer = css`
  // background: hotpink;
  // background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 1.5rem;
  flex: 1;
`;

export const title = css`
  margin: 1.5rem auto 0;
`;

export const smsTextWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  margin: 2rem auto;
  max-width: 80%;
`;

export const smsTextTitle = css`
  font-weight: 600;
`;
export const smsText = css`
  padding: 1rem 0;
`;

export const redeemBtn = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10rem;
  min-height: 2.5rem;
  font-size: 0.875rem;
  ${button}
`;

export const customerDetails = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1.5rem 0;
`;

export const entryWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1.5rem;
`;

export const entryLabel = css`
  color: var(--text-grey);
`;

export const entryContent = css`
  font-weight: bold;
`;

export const successText = css`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const CTA = css`
  background-color: var(--color-primary);
  color: white;
  border: 1px solid var(--color-primary);
  appearance: none;
  font-weight: 500;
  margin: 2rem 0;
  padding: 0 2rem;
  border-radius: 5px;
  height: 2.75rem;
  width: 16rem;
  cursor: pointer;

  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
`;

export const campaignNameAlias = css`
  text-align: center;
  color: #919191;
`;

const baseOverlayStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 5px;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.75);
`;

export const loadingOverlay = css`
  ${baseOverlayStyles}
`;

export const loadingOverlayHidden = css`
  ${baseOverlayStyles}
  display: none;
`;
