import React from 'react';
import ReactLoading from 'react-loading';
import Layout from 'components/Layout';
import styles from './LoadingPage.module.scss';

function LoadingPage() {
  return (
    <div className={styles['page-wrapper']}>
      <Layout alignItemsCenter showBackground hasDarkBackground showFadeAnimation={false}>
        <ReactLoading type={'bubbles'} color={'#ffffff'} width={75} />
      </Layout>
    </div>
  );
}

export default LoadingPage;
