/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import smoothscroll from 'smoothscroll-polyfill';
import AppHelmet from 'components/AppHelmet';
import Layout from 'components/Layout';
import InputAlt from 'components/InputAlt';
import AppName from 'components/AppName';
import Button from 'components/Button';
import useFormField from 'hooks/useFormField';
import { assignEventListener } from 'util/event-helper';
import { isiPadiPodOriPhone } from 'util/device-helper';
import { scrollTo } from 'util/dom-helper';
import useWrapper from './LoginPage.useWrapper';
import * as Styles from './LoginPage.styles';

export function LoginPage(props) {
  const [t] = useTranslation();
  const { authFailed, authFailedMsg, loginHandler, shouldRedirect, redirectURL, loading } = props;
  const [username, onChangeUsername, isUsernameValid, setUsernameValid] = useFormField('');
  const [password, onChangePassword, isPasswordValid, setPasswordValid] = useFormField('');
  const emailInput = React.useRef(null);

  React.useEffect(() => {
    // kick off the polyfill!
    smoothscroll.polyfill();
  }, []);

  const loginPreHandler = () => {
    if (!username && !password) {
      setUsernameValid(false);
      setPasswordValid(false);
    } else if (!username) {
      setUsernameValid(false);
    } else if (!password) {
      setPasswordValid(false);
    } else {
      loginHandler({ username, password });
    }
  };

  const onKeyPressHandler = (event) => {
    if (event.key === 'Enter') {
      loginPreHandler();
    }
  };

  const autoScrollWindow = () => {
    const topOffset = emailInput.current.getBoundingClientRect().top - 30;
    scrollTo(topOffset, 0);
  };

  const scrollWindow = () => {
    if (isiPadiPodOriPhone()) autoScrollWindow();
  };

  if (shouldRedirect) {
    return <Redirect to={redirectURL} />;
  }

  return (
    <div css={Styles.pageWrapper}>
      <Layout showBackground>
        <div css={Styles.container}>
          <div css={Styles.logoWrapper}>
            <AppName />
          </div>

          <div css={Styles.inputWrapper}>
            <InputAlt
              type="email"
              name="username"
              label={t('email')}
              invalid={!isUsernameValid}
              errMsg={t('please-enter-a-valid-email')}
              value={username}
              onChange={onChangeUsername}
              onFocus={scrollWindow}
              ref={emailInput}
              id="email"
            />

            <InputAlt
              type="password"
              name="password"
              label={t('password')}
              invalid={!isPasswordValid}
              errMsg={t('please-enter-a-valid-password')}
              onChange={onChangePassword}
              value={password}
              onKeyPress={onKeyPressHandler}
            />

            {authFailed ? (
              <div css={Styles.errorMsg}>
                <span>{authFailedMsg}</span>
              </div>
            ) : null}

            <Button
              css={Styles.loginBtn}
              type="primary"
              {...assignEventListener(() => loginHandler({ username, password }))}
              disabled={loading}
            >
              {loading ? <ReactLoading type={'bubbles'} color="#ffffff" width={75} /> : t('login')}
            </Button>
          </div>

          <div css={Styles.footerWrapper} />
        </div>
      </Layout>
    </div>
  );
}

LoginPage.propTypes = {
  loginHandler: PropTypes.func.isRequired,
  authFailed: PropTypes.bool.isRequired,
  authFailedMsg: PropTypes.string,
  shouldRedirect: PropTypes.bool,
  redirectURL: PropTypes.string,
  loading: PropTypes.bool,
};

export default () => {
  const props = useWrapper();
  const [t] = useTranslation();
  return (
    <>
      <AppHelmet
        title={`${t('loyalty')} | ${t('login')}`}
        description={`${t('loyalty')} | Login to your app`}
      />
      <LoginPage {...props} />
    </>
  );
};
