import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PartnerContext } from 'contexts/PartnerProvider';
import CloseIcon from 'components/Icons/Close';
import styles from './PaymentProfile.module.scss';

// NOTE: this class name should be the same as in the @mixin is-venue-in-payment-warning
const venueInPaymentWarningClass = 'venue-in-payment-warning';

function PaymentWarning({ handleBillingPage, isVenueInPaymentWarning, warnings }) {
  const { t } = useTranslation();
  const paymentWarningRef = useRef();
  const { setVisiblePaymentWarning } = useContext(PartnerContext);

  const hidePaymentProfile = () => {
    setVisiblePaymentWarning(false);
  };

  useLayoutEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      // this will get called whenever div dimension changes
      entries.forEach(() => {
        document.documentElement.style.setProperty(
          '--payment-warning-height',
          `${paymentWarningRef.current.offsetHeight}px`
        );
      });
    });

    // observer for height changes when the payment warning resizes, so that we can adjust the screen with warning height
    myObserver.observe(paymentWarningRef.current);

    return () => {
      //  stop listening to changes
      myObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const bodyClassList = document.body.classList;

    if (isVenueInPaymentWarning) {
      bodyClassList.add(venueInPaymentWarningClass);
    } else {
      bodyClassList.remove(venueInPaymentWarningClass);
    }

    return () => {
      bodyClassList.remove(venueInPaymentWarningClass);
    };
  }, [isVenueInPaymentWarning]);

  return (
    <div ref={paymentWarningRef} role="alert" className={classnames(styles.paymentWarning, {})}>
      <ul className={styles.warningMessages}>
        {warnings.map((warning, index) => (
          <li
            key={warning}
            className={classnames(styles.message, { [styles.firstMessage]: index === 0 })}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: warning }}
          />
        ))}
      </ul>
      <button type="button" className={styles.payNow} onClick={handleBillingPage}>
        {t('payment-profile.go-to-billing')}
      </button>
      <CloseIcon className={styles.closeIcon} onClick={hidePaymentProfile} />
    </div>
  );
}

PaymentWarning.propTypes = {
  handleBillingPage: PropTypes.func.isRequired,
  isVenueInPaymentWarning: PropTypes.bool.isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string),
};
export default PaymentWarning;
