import { css } from '@emotion/react';
import { breakpoints } from '@styles/media-queries';

export const wrapper = css`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  > div {
    flex: 1;
  }

  padding: 2rem;
  ${breakpoints.smallOnly} {
    padding: 1rem;
  }
`;

export const contentContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const title = css`
  font-size: 1.25rem;
  font-weight: bold;
`;

export const helpText = css`
  font-size: 1rem;
  padding: 1rem 0 1.5rem;
  text-align: center;
`;

const baseCTAStyles = css`
  border: none;
  outline: none;
  appearance: none;
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
  margin: 0.5rem 0;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  cursor: pointer;
`;

export const CTA = css`
  ${baseCTAStyles};
  background-color: var(--color-error);
`;
