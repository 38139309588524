import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AppHelmet from 'components/AppHelmet';
import Layout from 'components/Layout';
import VenueDropdown from 'components/VenueDropdown';
import PoweredByUMAI from 'components/PoweredByUMAI';
import AppName from 'components/AppName';
import HelpPopover from 'components/HelpPopover';
import LanguagePopover from 'components/LanguagePopover';
import ROUTES from 'constants/routes';
import packageJson from '../../../package.json';
import * as Styles from './NavLayout.styles';

const appVersion = `v.${packageJson.version}`;

function NavLayout({
  children,
  title = 'Navigation',
  showAppName = true,
  showVenueDropdown = true,
  showLanguagePopover = true,
  showLogout = true,
}) {
  const [t] = useTranslation();

  return (
    <div css={Styles.pageWrapper}>
      <AppHelmet title={`${t('loyalty')} | ${title}`} description={`${t('loyalty')} | ${title}`} />
      <Layout showBackground hasDarkBackground alignItemsCenter showPaymentProfile>
        <div css={Styles.container}>
          <div css={Styles.topRow}>
            {showAppName ? (
              <div css={Styles.logoWrapper}>
                <AppName />
              </div>
            ) : null}
            {showVenueDropdown ? (
              <div css={Styles.venueDropdownWrapper}>
                <VenueDropdown />
              </div>
            ) : null}
          </div>
          {children}
          <div css={Styles.bottomRow}>
            <HelpPopover bordered />
            {showLanguagePopover ? (
              <div css={Styles.bottomLang}>
                <LanguagePopover bordered type="dark" />
              </div>
            ) : null}
            {showLogout ? (
              <Link css={Styles.logoutWrapper} to={ROUTES.LOGOUT}>
                {t('log-out')}
              </Link>
            ) : null}
          </div>
          <div css={Styles.powerWrapper}>
            <PoweredByUMAI type="light" />
            <div css={Styles.appVersion}>{appVersion}</div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

NavLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  showAppName: PropTypes.bool,
  showVenueDropdown: PropTypes.bool,
  showLanguagePopover: PropTypes.bool,
  showLogout: PropTypes.bool,
};

export default NavLayout;
