import { useContext, useEffect } from 'react';
import { PartnerContext } from 'contexts/PartnerProvider';

function LogoutPage() {
  const { logoutPartner } = useContext(PartnerContext);

  useEffect(() => {
    logoutPartner();

    // eslint-disable-next-line
  }, []);

  return null;
}

export default LogoutPage;
