import React from 'react';
import PropTypes from 'prop-types';
import styles from './AccessMessage.module.scss';

const AccessMessage = ({ title, description, subTitle, header, footer }) => (
  <section className={styles.accessMessage}>
    <h2 className={styles.header}>{header}</h2>
    <h3 className={styles.title}>{title}</h3>
    <div className={styles.description}>{description}</div>
    <h4 className={styles.subTitle}>{subTitle}</h4>
    <footer className={styles.footer}>{footer}</footer>
  </section>
);

AccessMessage.propTypes = {
  header: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  subTitle: PropTypes.node.isRequired,
  footer: PropTypes.node,
};

export default AccessMessage;
