import React from 'react';
import { useTranslation } from 'react-i18next';
import history from 'util/router-history';
import { assignEventListener } from 'util/event-helper';
import { OPERATIONAL_PERMISSIONS } from 'constants/operational-permission';
import { AddCashback, RedeemCredit, DeductCredit, ViewHistory } from 'components/Icons';
import NavLayout from 'components/NavLayout';
import ProtectedElement from 'components/ProtectedElement';
import * as Styles from './NavPage.styles';

function NavPage() {
  const [t] = useTranslation();

  const redirectTo = (path) => {
    history.push(path);
  };

  return (
    <NavLayout>
      <div css={Styles.linksWrapper}>
        <div css={Styles.links}>
          <ProtectedElement allowedPermission={OPERATIONAL_PERMISSIONS.LOYALTY_TRANSACTIONS}>
            <a
              {...assignEventListener(() => redirectTo('/capture/claim'))}
              id="add-cashback-button"
            >
              <div css={[Styles.link, Styles.addCashbackIcon]}>
                <AddCashback />
                <div>{t('add-cashback')}</div>
              </div>
            </a>
            <a
              {...assignEventListener(() => redirectTo('/capture/redeem'))}
              id="redeem-credit-button"
            >
              <div css={[Styles.link, Styles.redeemCreditIcon]}>
                <RedeemCredit />
                <div>{t('redeem-credit')}</div>
              </div>
            </a>
          </ProtectedElement>
          <ProtectedElement
            allowedPermission={OPERATIONAL_PERMISSIONS.LOYALTY_ADDITIONS_DEDUCTIONS}
          >
            <a {...assignEventListener(() => redirectTo('/capture/add'))} id="add-credit-button">
              <div css={[Styles.link, Styles.deductCreditIcon]}>
                <AddCashback />
                <div>{t('add-credit')}</div>
              </div>
            </a>
            <a
              {...assignEventListener(() => redirectTo('/capture/deduct'))}
              id="deduct-credit-button"
            >
              <div css={[Styles.link, Styles.deductCreditIcon]}>
                <DeductCredit />
                <div>{t('deduct-credit')}</div>
              </div>
            </a>
          </ProtectedElement>
          <a {...assignEventListener(() => redirectTo('/history'))} id="view-history-button">
            <div css={[Styles.link, Styles.viewHistoryIcon]}>
              <ViewHistory />
              <div>{t('view-history')}</div>
            </div>
          </a>
        </div>
      </div>
    </NavLayout>
  );
}

export default NavPage;
