import { getDateFormatter, formatDateWithoutYearForWhatsappText } from 'util/date-helper';
import i18n from 'services/i18n';

const formatDateForWhatsappText = getDateFormatter('L');

const roundDecimalNumbers = (number) => {
  const result = parseFloat(number).toFixed(2);
  if (typeof result === 'string') {
    return result.replace(/[^0-9.]/g, '');
  }
  return result;
};

export const messageCreator = ({
  currentVenueName,
  reports: {
    reportToday,
    reportOneDayAgo,
    reportTwoDaysAgo,
    reportThreeDaysAgo,
    reportFourDaysAgo,
    reportFiveDaysAgo,
  },
}) => {
  const venueName = currentVenueName;
  const NEWLINEFEED = `\n`;
  const PERCENT_SYMBOL = '%';

  const reportHistory = (report) => {
    if (report.reportFound)
      return `${roundDecimalNumbers(report.billsCapturedPercentage)}${PERCENT_SYMBOL}`;

    if (report.isClosed) {
      return i18n.t('reports.statuses.closed');
    }
    return i18n.t('report-not-submitted');
  };

  let reportTextContext = '';
  if (reportToday.reportFound) {
    reportTextContext = `*[${
      reportToday.billsCapturedPercentage
    }${PERCENT_SYMBOL}]*${NEWLINEFEED}*(${venueName})*${NEWLINEFEED}*-- ${i18n.t(
      'whats-app-report.report-on'
    )} ${formatDateForWhatsappText(reportToday?.date)} --*${NEWLINEFEED}${NEWLINEFEED}*${i18n.t(
      'whats-app-report.transactions'
    )} ${reportToday.numPOSBills}*${NEWLINEFEED}*${i18n.t('whats-app-report.collected')} ${
      reportToday.numLoyaltyClaims
    }*${NEWLINEFEED}*${i18n.t('whats-app-report.new-inhouse-signups')} ${
      reportToday.newInHouseSignUps
    }*${NEWLINEFEED}*${i18n.t('whats-app-report.new-online-signups')} ${
      reportToday.newOnlineSignUps
    }*${NEWLINEFEED}*${i18n.t('whats-app-report.repeat-visitors')} ${
      reportToday.repeatVisitors
    }*${NEWLINEFEED}${NEWLINEFEED}*${i18n.t(
      'whats-app-report.signup-performance'
    )} ${roundDecimalNumbers(
      reportToday.billsCapturedPercentage
    )}*${PERCENT_SYMBOL}${NEWLINEFEED}*${i18n.t('whats-app-report.healthy-target', {
      percentage: 70,
    })}${PERCENT_SYMBOL}*${NEWLINEFEED}${NEWLINEFEED}*${i18n.t('whats-app-report.sales')} ${
      reportToday.totalValPOSBills
    }*${NEWLINEFEED}*${i18n.t('whats-app-report.collected')} ${roundDecimalNumbers(
      reportToday.totalValLoyaltyClaims
    )}*${NEWLINEFEED}${NEWLINEFEED}*${i18n.t(
      'whats-app-report.revenue-performance'
    )} ${roundDecimalNumbers(
      reportToday.amountCapturedPercentage
    )}*${PERCENT_SYMBOL}${NEWLINEFEED}`;
  }

  const lastFiveDaysTextContent = `*${i18n.t(
    'whats-app-report.history'
  )}*${NEWLINEFEED}${formatDateWithoutYearForWhatsappText(reportToday?.date)}: ${reportHistory(
    reportToday
  )}${NEWLINEFEED}${formatDateWithoutYearForWhatsappText(reportOneDayAgo?.date)}: ${reportHistory(
    reportOneDayAgo
  )}${NEWLINEFEED}${formatDateWithoutYearForWhatsappText(reportTwoDaysAgo?.date)}: ${reportHistory(
    reportTwoDaysAgo
  )}${NEWLINEFEED}${formatDateWithoutYearForWhatsappText(
    reportThreeDaysAgo?.date
  )}: ${reportHistory(reportThreeDaysAgo)}${NEWLINEFEED}${formatDateWithoutYearForWhatsappText(
    reportFourDaysAgo?.date
  )}: ${reportHistory(reportFourDaysAgo)}${NEWLINEFEED}${formatDateWithoutYearForWhatsappText(
    reportFiveDaysAgo?.date
  )}: ${reportHistory(reportFiveDaysAgo)}${NEWLINEFEED}`;

  return `${reportTextContext}${NEWLINEFEED}${lastFiveDaysTextContent}`;
};
