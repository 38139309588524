import { REACT_APP_API_ENDPOINT, REACT_APP_API_ENDPOINT_LOYALTY_V1 } from 'constants/app-env';

const authEndpoints = {
  loginPOST: () => `${REACT_APP_API_ENDPOINT}/oauth/token`,
  tokenGET: () => `${REACT_APP_API_ENDPOINT}/oauth/token/info`,
};

const partnerEndpoints = {
  partnerDetailsGET: () => `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/partners`,
  currentUserGET: () => `${REACT_APP_API_ENDPOINT}/partner/users/current_user_profile`,
};

const venueEndpoints = {
  findCustomerByPhoneGET: ({ currentVenueId, nationalNumber, internationalNumber }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/guests/search?venue_id=${currentVenueId}&phone_number[]=${nationalNumber}&phone_number[]=${internationalNumber}`,
  fetchHistoryGET: (currentVenueId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_transactions?venue_id=${currentVenueId}&per_page=50&page=1`,
  addCustomerPOST: (currentVenueId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/guests?venue_id=${currentVenueId}`,
  updateCustomerPATCH: ({ currentVenueId, customerId }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/guests/${customerId}?venue_id=${currentVenueId}`,
  fetchSettingsGET: (currentVenueId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/venues/${currentVenueId}/loyalty_data_gathering_settings`,
};

const guestEndpoints = {
  fetchCampaignsForGuestGET: ({ guestId, currentVenueId }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/guests/${guestId}/loyalty_campaign_logs?venue_id=${currentVenueId}&is_expired=false`,
  redeemCampaignPATCH: ({ guestId, campaignId }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/guests/${guestId}/loyalty_campaign_logs/${campaignId}/claim`,
};

const claimEndpoints = {
  claimAmountPOST: (currentVenueId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_transactions?venue_id=${currentVenueId}`,
};

const redeemEndpoints = {
  redeemAmountPOST: (currentVenueId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_transactions?venue_id=${currentVenueId}`,
  submitOTPPOST: (transactionId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_transactions/${transactionId}/verify_otp`,
  resendOTPPOST: (transactionId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_transactions/${transactionId}/resend_otp`,
};

const deductEndpoints = {
  deductAmountPOST: (currentVenueId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_transactions?venue_id=${currentVenueId}`,
};

const reportEndpoints = {
  fetchReportsGET: (currentVenueId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_reports?venue_id=${currentVenueId}`,
  createReportPOST: (currentVenueId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_reports?venue_id=${currentVenueId}`,
  closeDayPOST: (currentVenueId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_reports?venue_id=${currentVenueId}`,
  fetchDaysReportsGET: ({ currentVenueId, date }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_reports?venue_id=${currentVenueId}&start_date=${date}&end_date=${date}`,
};

const noteEndpoints = {
  addNotePOST: ({ currentVenueId, transactionId }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_transactions/${transactionId}?venue_id=${currentVenueId}`,
};

const addEndpoints = {
  addAmountPOST: (currentVenueId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_transactions?venue_id=${currentVenueId}`,
};

const memberInterfaceEndpoints = {
  fetchPublicAccountGET: ({ venueSlug, phoneNumber }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/public/guests?slug=${venueSlug}&phone_number=${phoneNumber}`,
  fetchSecureAccountGET: ({ venueSlug, phoneNumber, authToken }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/public/guests?slug=${venueSlug}&phone_number=${phoneNumber}&auth_token=${authToken}`,
  fetchAvailableCampaignsGET: ({ venueSlug, tokenId }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/public/loyalty_campaign_logs?slug=${venueSlug}&token_id=${tokenId}`,
  fetchSecureAvailableCampaignsGET: ({ venueSlug, tokenId, authToken }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/public/loyalty_campaign_logs?slug=${venueSlug}&token_id=${tokenId}&auth_token=${authToken}`,
  fetchAccountHistoryGET: ({ venueSlug, tokenId, page = 1 }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/public/loyalty_transactions?slug=${venueSlug}&token_id=${tokenId}&per_page=50&page=${page}`,
  toggleSmsSubscriptionGET: ({ venueSlug, tokenId }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/public/toggle_subscription?slug=${venueSlug}&token_id=${tokenId}`,
};

const signupEndpoints = {
  searchGuestGET: ({ venueSlug, phoneNumber }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/public/guests/search?slug=${venueSlug}&phone_number=${phoneNumber}`,
  sendProfileLinkGET: ({ venueSlug, tokenId }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/public/guests/get_link?slug=${venueSlug}&token_id=${tokenId}`,
  fetchVenueLoyaltySettingsGET: ({ venueSlug }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/public/venues/${venueSlug}`,
  registerAsMemberPOST: ({ venueSlug }) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/public/guests/sign_up?slug=${venueSlug}`,
};

const validationEndpoints = {
  validatePassword: (transactionId) =>
    `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/loyalty_transactions/${transactionId}/verify_password`,
};

const paymentProfileEndpoints = {
  fetchPaymentProfiles: () => `${REACT_APP_API_ENDPOINT_LOYALTY_V1}/maxio_subscriptions/warnings`,
};

export {
  authEndpoints,
  partnerEndpoints,
  venueEndpoints,
  guestEndpoints,
  claimEndpoints,
  redeemEndpoints,
  deductEndpoints,
  reportEndpoints,
  noteEndpoints,
  addEndpoints,
  memberInterfaceEndpoints,
  signupEndpoints,
  validationEndpoints,
  paymentProfileEndpoints,
};
