/* eslint-disable react/prop-types */
import React from 'react';
import PhoneNumberStep from 'components/PhoneNumberStep';
import { useTranslation, Trans } from 'react-i18next';
import useWrapper, { STEPS } from './Deduct.useWrapper';
import PasswordValidationStep from './components/PasswordValidationStep';
import DeductAmountStep from './components/DeductAmountStep';
import DeductSuccessStep from './components/DeductSuccessStep';
import * as Styles from './Deduct.styles';

const Deduct = (props) => {
  const {
    step,
    partner,
    customer,
    locale,
    resetDataHandler,
    redirectToDefaultPageHandler,
    phoneNumberSearchHandler,
    gotoNextStepFromPhoneNumStep,
    deductAmountHandler,
    submitPasswordHandler,
    deductSuccessRedirectHandler,
    addNoteToTransactionHandler,
    isAuthorized,
    isLoading,
    isPhoneLoading,
    venueSettings: { secondaryFieldsToCapture: secondaryFieldsToDisplay, dataCaptureFlow },
  } = props;

  const [t] = useTranslation();

  // eslint-disable-next-line no-shadow
  const renderStep = (step) => {
    switch (step) {
      case STEPS.MOBILE_NUMBER: {
        return null;
      }
      case STEPS.VALIDATE_PASSWORD: {
        return (
          <PasswordValidationStep
            customer={customer}
            locale={locale}
            resetModalHandler={resetDataHandler}
            title={t('transactions.types.deduction')}
            submitPasswordHandler={submitPasswordHandler}
            isAuthorized={isAuthorized}
            isLoading={isLoading}
          />
        );
      }
      case STEPS.DEDUCT_AMOUNT: {
        return (
          <DeductAmountStep
            customer={customer}
            step={STEPS.CLAIM_LOYALTY}
            deductAmountHandler={deductAmountHandler}
            resetModalHandler={resetDataHandler}
            locale={locale}
            secondaryFieldsToDisplay={secondaryFieldsToDisplay}
            dataCaptureFlow={dataCaptureFlow}
          />
        );
      }
      case STEPS.DEDUCT_SUCCESS: {
        return (
          <DeductSuccessStep
            customer={customer}
            deductSuccessRedirectHandler={deductSuccessRedirectHandler}
            resetModalHandler={redirectToDefaultPageHandler}
            addNoteToTransactionHandler={addNoteToTransactionHandler}
            locale={locale}
          />
        );
      }
      default:
        throw new Error("STEP didn't match");
    }
  };

  return (
    <div css={Styles.container}>
      <PhoneNumberStep
        customer={customer}
        phoneNumberSearchHandler={phoneNumberSearchHandler}
        gotoNextHandler={gotoNextStepFromPhoneNumStep}
        resetDataHandler={resetDataHandler}
        venue={{ name: partner.currentVenueName }}
        locale={locale}
        isPhoneLoading={isPhoneLoading}
        phoneNumberButtonText={t('deduct-credit')}
        transactionText={{
          title: t('deduct-credit-here'),
          desc: t('mistakes-happen-correct-them-here'),
          steps: [
            {
              index: 'a',
              desc: t('enter-guest-phone-number'),
            },
            {
              index: 'b',
              desc: (
                <Trans i18nKey="fill-in-the-amount-to-deduct">
                  Fill in the amount to deduct
                  <br />
                  This is not the same as redeeming
                </Trans>
              ),
            },
            {
              index: 'c',
              desc: t('enter-management-password-for-security-clearance'),
            },
          ],
        }}
      />
      {renderStep(step)}
    </div>
  );
};

Deduct.propTypes = {};

export default () => {
  const props = useWrapper();
  return <Deduct {...props} />;
};
