import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getNumberOfDaysFromTodayFromServerDate } from 'util/date-helper';
import { humanizeCampaignName } from 'util/campaign-helper';
import * as Styles from './CampaignPopup.styles';

const CampaignPopup = ({ campaign, hideCampaignPopup = () => {} }) => {
  const [t] = useTranslation();
  const expiresIn = getNumberOfDaysFromTodayFromServerDate(campaign.expiredAt);
  const { loyaltyCampaignName = '', content = '' } = campaign;

  return (
    <div css={Styles.wrapper}>
      <div css={Styles.container}>
        <h3 css={Styles.campaignName}>{humanizeCampaignName(loyaltyCampaignName)}</h3>
        <p css={Styles.campaignExpiry}>{t('expiring-in-days', { expiresIn })}</p>

        <div css={Styles.campaignSmsContent}>{content}</div>

        <div css={Styles.campaignRedemptionInstructions}>
          {t('to-redeem-this-reward-show-the-venue-the-above-text')}
        </div>

        <div css={Styles.buttonWrapper}>
          {/* eslint-disable-next-line */}
          <a css={Styles.closePopupButton} onClick={hideCampaignPopup}>
            {t('got-it')}
          </a>
        </div>
      </div>
    </div>
  );
};

CampaignPopup.propTypes = {
  campaign: PropTypes.object.isRequired,
  hideCampaignPopup: PropTypes.func.isRequired,
};

export default CampaignPopup;
