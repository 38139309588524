import { getDateFormatter } from 'util/date-helper';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Amount from 'components/Amount';
import imageHeader from 'images/loyalty-inteface-header.jpeg';
import * as Styles from './AccountHistory.styles';

const AccountHistory = (props) => {
  const {
    customer: {
      balance,
      transactionsHistory = [],
      hasMoreTransactions = false,
      totalTransactionCount,
    },
    locale: { currencyDenomination },
    venueName = '',
    goBackToAccountDetails = () => {},
    getAccountHistoryHandler = () => {},
    paginationPage = 1,
  } = props;
  const [t] = useTranslation();
  const TRANSACTION = {
    collection: t('transactions.types.collection'),
    redemption: t('transactions.types.redemption'),
    deduction: t('transactions.types.deduction'),
    addition: t('transactions.types.addition'),
  };

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--bg-image', `url(${imageHeader})`);
  }, []);

  // exclude failed redemptions in history list
  const renderHistory = () => (
    <tbody>
      {transactionsHistory.map((item, index) => {
        const key = `history-${index}`;
        const { createdAt, amount, transactionType } = item;
        return (
          <tr key={key}>
            <td css={Styles.transactionType}>
              <span>{TRANSACTION[transactionType]}</span>
              <span>{t('credit')}</span>
              <span>{item.status === 'failed' ? `(${t('transaction-failed')})` : null}</span>
            </td>
            <td>
              <Amount currency={currencyDenomination} amount={amount} />
            </td>
            <td css={Styles.createdAt}>
              <span>{getDateFormatter('lll')(createdAt)}</span>
            </td>
          </tr>
        );
      })}
    </tbody>
  );

  return (
    <>
      <div css={Styles.pageHeader}>
        {venueName} {t('loyalty')}
      </div>
      <div css={Styles.accountBalanceWrapper}>
        <div css={Styles.accountBalanceLabel}>{t('balance')}</div>
        <div css={Styles.accountBalance}>
          <Amount currency={currencyDenomination} amount={balance} />
        </div>
      </div>
      <div css={Styles.transactionsWrapper}>
        <table css={Styles.transactionsTable}>
          <thead>
            <tr>
              <th>{t('action')}</th>
              <th>{t('amount')}</th>
              <th>{t('date')}</th>
            </tr>
          </thead>
          {renderHistory()}
        </table>
      </div>
      <div css={Styles.paginationIndicator}>
        <div>
          {t(`showing-n-out-of-n-transactions`, {
            current: transactionsHistory.length,
            total: totalTransactionCount,
          })}
        </div>
        {hasMoreTransactions ? (
          // eslint-disable-next-line
          <a onClick={() => getAccountHistoryHandler(paginationPage + 1)}>{t('load-more')}</a>
        ) : null}
      </div>
      {/* eslint-disable-next-line */}
      <div css={Styles.backButtonWrapper}>
        {/* eslint-disable-next-line */}
        <a onClick={goBackToAccountDetails} css={Styles.backButton}>
          {t('back')}
        </a>
      </div>
    </>
  );
};

AccountHistory.propTypes = {
  customer: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  venueName: PropTypes.string.isRequired,
  goBackToAccountDetails: PropTypes.func.isRequired,
  getAccountHistoryHandler: PropTypes.func.isRequired,
  paginationPage: PropTypes.number.isRequired,
};

export default AccountHistory;
