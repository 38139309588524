/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './Popover.styles';

const Popover = ({
  children,
  content,
  visibleOnInit = false,
  triggerWrapperCss = Styles.defaultTriggerWrapperCss,
  position = 'left',
}) => {
  const [isVisible, setVisibility] = React.useState(visibleOnInit);

  const handleClickOutside = (event) => {
    if (isVisible) return setVisibility(false);
    return null;
  };

  React.useEffect(() => {
    const reactModal = document.querySelector('.ReactModal__Content');
    if (reactModal !== null) {
      const modalClasses = reactModal.className || '';
      if (isVisible && modalClasses.indexOf('dimmed') < 0) {
        document.querySelector('.ReactModal__Content').classList.add('dimmed');
      }
      if (!isVisible && modalClasses.indexOf('dimmed') >= 0) {
        document.querySelector('.ReactModal__Content').classList.remove('dimmed');
      }
    }
    document.addEventListener('click', handleClickOutside);
    return function cleanup() {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line
  }, [isVisible]);

  const changeVisibility = (e) => {
    // add stopPropagation to prevent document.addEventListener('click', handleClickOutside) capturing the same click event.
    // https://github.com/facebook/react/issues/24657 (react18)
    e.stopPropagation();
    setVisibility((prevState) => !prevState);
  };

  const renderContent = () => {
    if (isVisible) {
      return (
        <div css={Styles.content} data-position={position}>
          <div>{content}</div>
        </div>
      );
    }
    return null;
  };

  const getArrowStyles = () => {
    if (position === 'top') {
      return Styles.arrowTop;
    }
    if (position === 'right') {
      return Styles.arrowRight;
    }
    if (position === 'bottom') {
      return Styles.arrowBottom;
    }
    return Styles.arrowLeft;
  };

  return (
    <div css={Styles.wrapper}>
      {/* eslint-disable-next-line */}
      <div css={triggerWrapperCss} onClick={changeVisibility}>
        {children}
        {isVisible ? <div css={getArrowStyles()} /> : null}
      </div>
      {renderContent()}
    </div>
  );
};

Popover.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  visibleOnInit: PropTypes.bool,
  triggerWrapperCss: PropTypes.object,
  position: PropTypes.string,
};

export default Popover;
