import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppHelmet from 'components/AppHelmet';
import PoweredByUMAI from 'components/PoweredByUMAI';
import LanguagePopover from 'components/LanguagePopover';
import SuspensionScreen from 'components/SuspensionScreen';
import LoadingPage from 'pages/LoadingPage';
import useWrapper, { STEPS } from './GuestInterface.useWrapper';
import MemberAccount from './components/MemberAccount';
import AccountHistory from './components/AccountHistory';
import * as Styles from './GuestInterface.styles';

const GuestInterface = (props) => {
  const {
    step,
    customer,
    locale,
    venueName,
    isUsingSecureLink = false,
    getAccountHistoryHandler,
    goBackToAccountDetails,
    toggleSmsSubscriptionHandler,
    paginationPage,
    hasErrorMessage,
    errorMessage,
    isBlocked,
    hasLoaded,
  } = props;
  const [t] = useTranslation();

  // eslint-disable-next-line no-shadow
  const renderStep = (step) => {
    switch (step) {
      case STEPS.ACCOUNT_DETAILS: {
        return (
          <MemberAccount
            customer={{ ...customer }}
            locale={locale}
            venueName={venueName}
            isUsingSecureLink={isUsingSecureLink}
            getAccountHistoryHandler={getAccountHistoryHandler}
            toggleSmsSubscriptionHandler={toggleSmsSubscriptionHandler}
          />
        );
      }
      case STEPS.ACCOUNT_HISTORY: {
        return (
          <AccountHistory
            venueName={venueName}
            customer={customer}
            locale={locale}
            isUsingSecureLink={isUsingSecureLink}
            goBackToAccountDetails={goBackToAccountDetails}
            getAccountHistoryHandler={getAccountHistoryHandler}
            paginationPage={paginationPage}
          />
        );
      }
      default:
        throw new Error("STEP didn't match");
    }
  };

  // Wait until venue data has been loaded
  if (!hasLoaded) return <LoadingPage />;
  // Venue is blocked due to payment or has no active subscriptions
  if (isBlocked) {
    return <SuspensionScreen venueName={venueName} languages={locale.languages} />;
  }

  return (
    <>
      <AppHelmet
        title={`${t('loyalty')} | Guest Account`}
        description={`${t('loyalty')} | Guest Account`}
      />
      <div css={Styles.wrapper}>
        <div css={Styles.pageWrapper}>
          <div css={Styles.container}>
            {renderStep(step)}
            {hasErrorMessage ? <div css={Styles.errorMessageWrapper}>{errorMessage}</div> : null}
            <div css={Styles.fixedToBottom}>
              <div css={Styles.bottomRow}>
                <LanguagePopover bordered guestLanguages={locale?.languages} />
                <div css={Styles.poweredByUMAIWrapper}>
                  <PoweredByUMAI />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GuestInterface.propTypes = {
  step: PropTypes.string.isRequired,
  customer: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  venueName: PropTypes.string.isRequired,
  isUsingSecureLink: PropTypes.bool.isRequired,
  getAccountHistoryHandler: PropTypes.func.isRequired,
  goBackToAccountDetails: PropTypes.func.isRequired,
  toggleSmsSubscriptionHandler: PropTypes.func.isRequired,
  paginationPage: PropTypes.number.isRequired,
  hasErrorMessage: PropTypes.bool,
  errorMessage: PropTypes.string,
  isBlocked: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
};

export default () => {
  const props = useWrapper();

  return <GuestInterface {...props} />;
};
