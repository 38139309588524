import React from 'react';

const LogoUMAI = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 378.9 111">
    <g>
      <path
        className="st0"
        d="M83.2,62.2c0,27.7-14,43.7-38.5,43.7c-24.4,0-39-16.4-39-44V-0.4h-6.1v62.5c0,30.8,16.8,49.3,44.9,49.3
		c28,0,44.7-18.6,44.7-49.7V-0.4h-6.1V62.2z"
      />
      <polygon
        className="st0"
        points="167.7,65.9 121.8,-0.2 121.7,-0.4 116.1,-0.4 116.1,109.7 122,109.7 122,10.4 167.2,75.2 167.9,75.2 
		213.1,10.4 213.1,109.7 219.2,109.7 219.2,-0.4 213.6,-0.4 	"
      />
      <rect x="373.2" y="-0.4" className="st0" width="6.1" height="110.1" />
      <polygon
        className="st0"
        points="298.4,-0.4 292.9,-0.4 240.9,109.2 240.7,109.7 247,109.7 262.1,77.6 295.5,6.3 326.7,72.8 329,77.6 
		343.8,109.5 343.9,109.7 350.6,109.7 298.5,-0.2 	"
      />
      <rect x="275.6" y="72.2" className="st0" width="38" height="5.6" />
    </g>
  </svg>
);

export default LogoUMAI;
