import i18n from 'services/i18n';

// Big spender
// 1st visit
// Birthday
// Growth = Frequent visitor
// At risk = Miss you
// Lapsed = Where have you been
// Lost = It’s been a while

const BIG_SPENDER = {
  originalName: 'BIG SPENDER',
  humanizedName: () => i18n.t('campaign.big-spender'),
};

const FIRST_VISIT = {
  originalName: '1ST VISIT',
  humanizedName: () => i18n.t('campaign.first-visit'),
};

const BIRTHDAY = {
  originalName: 'BIRTHDAY',
  humanizedName: () => i18n.t('campaign.birthday'),
};

const GROWTH = {
  originalName: 'GROWTH',
  humanizedName: () => i18n.t('campaign.growth'),
};

const AT_RISK = {
  originalName: 'AT RISK',
  humanizedName: () => i18n.t('campaign.at-risk'),
};
const LAPSED = {
  originalName: 'LAPSED',
  humanizedName: () => i18n.t('campaign.lapsed'),
};
const LOST = {
  originalName: 'LOST',
  humanizedName: () => i18n.t('campaign.lost'),
};

export const humanizeCampaignName = (campaignName) => {
  const originalName = campaignName;

  try {
    campaignName = campaignName.toUpperCase();

    if (campaignName === BIG_SPENDER.originalName) {
      return BIG_SPENDER.humanizedName();
    }
    if (campaignName === FIRST_VISIT.originalName) {
      return FIRST_VISIT.humanizedName();
    }
    if (campaignName === BIRTHDAY.originalName) {
      return BIRTHDAY.humanizedName();
    }
    if (campaignName === GROWTH.originalName) {
      return GROWTH.humanizedName();
    }
    if (campaignName === AT_RISK.originalName) {
      return AT_RISK.humanizedName();
    }
    if (campaignName === LAPSED.originalName) {
      return LAPSED.humanizedName();
    }
    if (campaignName === LOST.originalName) {
      return LOST.humanizedName();
    }
    return originalName;
  } catch (err) {
    return originalName;
  }
};
