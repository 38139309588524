import { device } from '@umai/common';

export const isiPadiPodOriPhone = () => device.ios();

export const isAndroid = () => device.android();

export const setWebView = () => {
  const params = new URL(window?.location?.href)?.searchParams;
  if (window?.localStorage?.setItem && params) {
    window.localStorage.setItem('isWebview', params?.get('isWebview'));
    window.localStorage.setItem('device', params?.get('device'));
  }
};
