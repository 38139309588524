/* eslint-disable react/prop-types,indent  */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import AppHelmet from 'components/AppHelmet';
import HelpPopover from 'components/HelpPopover';
import LanguagePopover from 'components/LanguagePopover';
import Layout from 'components/Layout';
import VenueDropdown from 'components/VenueDropdown';
import useFormField from 'hooks/useFormField';
import InputAlt from 'components/InputAlt';
import InputDatePicker from 'components/InputDatePicker';
import { stringToElementId } from 'util/helpers';
import { getDateFormatter } from 'util/date-helper';
import { assignEventListener } from 'util/event-helper';
import { formatAmountWithCommas } from 'util/number-helper';
import { isValidAmount } from 'util/validators';
import { isiPadiPodOriPhone } from 'util/device-helper';
import ROUTES from 'constants/routes';
import CloseConfirmationModal from './components/CloseConfirmationModal';
import useWrapper from './ReportsPage.useWrapper';
import packageJson from '../../../package.json';
import { DEFAULT_LANGUAGE } from 'constants/app-env';
import * as Styles from './ReportsPage.styles';

const appVersion = `v.${packageJson.version}`;
const isiPadiPodOriPhoneDevice = isiPadiPodOriPhone();

const WORD_MAPPERS = {
  STATUS: {
    reported: 'Reported',
    NOT_REPORTED: 'Not Reported',
    closed: 'Closed',
  },
};

function ReportsPage(props) {
  const {
    showConfirmationModal,
    allReports,
    reports,
    partner,
    createReportHandler,
    showConfirmationModalHandler,
    confirmCloseReportHandler,
    cancelCloseReportHandler,
    copyReportToClipboardHandler,
    resendWhatsappReportHandler,
    isCopiedToClipboard = false,
    copyToClipboardFailed = false,
    isSubmittingReport = false,
  } = props;
  const [t, i18n] = useTranslation();
  const today = moment();
  const [date, setDate] = React.useState(today);
  const [isCopyingToClipboard, setCopyingToClipboard] = React.useState(false);
  const [numBillsInput, onChangeNumBillsInput, isNumBillsInputValid, setNumBillsInputValid] =
    useFormField('');
  const [valBillsInput, onChangeValBillsInput, isValBillsInputValid, setValBillsInputValid] =
    useFormField('');
  const formatDateType = getDateFormatter('LL');

  const handleDateChange = (d) => {
    setCopyingToClipboard(false);
    // eslint-disable-next-line
    if (!!d) {
      setDate(moment(d));
    }
  };

  if (!reports.length) return null;

  const isReportSubmittedOrClosedForTheDay = allReports.some(
    (r) => r.reportDate.date() === date.date() && r.id !== undefined
  );

  const todaysReport = allReports.find(
    (r) => r.reportDate.date() === date.date() && r.id !== undefined
  );

  const reportedAsClosed =
    todaysReport &&
    (todaysReport.status || '').toUpperCase() ===
      ((WORD_MAPPERS.STATUS || {}).closed || '').toUpperCase();

  const isDateInTheFuture = date.startOf('day').isAfter(today.startOf('day'));

  const allowReportSubmit =
    isNumBillsInputValid &&
    isValBillsInputValid &&
    !isReportSubmittedOrClosedForTheDay &&
    !isDateInTheFuture;

  const allowReportClose = !isReportSubmittedOrClosedForTheDay;

  const submitReportHandler = !allowReportSubmit
    ? () => {}
    : () => {
        if (!numBillsInput || !valBillsInput) {
          if (!numBillsInput) {
            setNumBillsInputValid(false);
          }
          if (!valBillsInput) {
            setValBillsInputValid(false);
          }
          return;
        }
        if (isNumBillsInputValid && isValBillsInputValid) {
          createReportHandler({
            date,
            numberOfBills: Number(numBillsInput.replace(/,/g, '')),
            totalValue: Number(valBillsInput.replace(/,/g, '')),
          });
        } else {
          if (!isNumBillsInputValid) {
            setNumBillsInputValid(false);
          }
          if (!isValBillsInputValid) {
            setValBillsInputValid(false);
          }
        }
      };

  const handleBillsChange = (event) => {
    event.target.value = formatAmountWithCommas(event.target.value);
    if (isValidAmount(event.target.value)) {
      onChangeNumBillsInput(event);
    }
  };

  const handleBillsAmountChange = (event) => {
    event.target.value = formatAmountWithCommas(event.target.value);
    if (isValidAmount(event.target.value)) {
      onChangeValBillsInput(event);
    }
  };

  const renderReportButton = () => {
    let buttonText = t('report-to-whatsapp');
    const allowReporting = allowReportSubmit && !isSubmittingReport;

    if (allowReporting) {
      buttonText = t('report-to-whatsapp');
    } else if (allowReportSubmit && isSubmittingReport) {
      buttonText = t('submitting-report');
    }

    return (
      <button
        id={stringToElementId(buttonText)}
        css={allowReportSubmit ? Styles.CTASubmit : Styles.disabledCTASubmit}
        type="button"
        {...assignEventListener(allowReporting ? submitReportHandler : () => {})}
      >
        {buttonText}
      </button>
    );
  };

  const renderCloseButton = () => {
    if (allowReportClose || reportedAsClosed) {
      return (
        <button
          id="report-closed-button"
          css={todaysReport ? Styles.disabledCTAClosed : Styles.CTAClosed}
          type="button"
          {...assignEventListener(!reportedAsClosed ? showConfirmationModalHandler : () => {})}
        >
          {t('closed-on-date', { date: formatDateType(date.format()) })}
          {reportedAsClosed ? <span css={Styles.checkmark}>&nbsp;&#10004;</span> : ''}
        </button>
      );
    }
    return null;
  };

  const copyReportToClipboard = () => {
    setCopyingToClipboard(true);
    copyReportToClipboardHandler(date);
  };

  const resendWhatsappReport = () => {
    resendWhatsappReportHandler(date);
  };

  const renderCopyToClipboardButton = () => {
    let buttonText = '';
    const allowCopy = !isCopyingToClipboard;
    const isCopied = isCopyingToClipboard && isCopiedToClipboard;

    if (isCopyingToClipboard && !isCopiedToClipboard && !copyToClipboardFailed) {
      buttonText = t('copying-please-wait');
    } else if (isCopied) {
      buttonText = t('copied-to-clipboard');
    } else if (isCopyingToClipboard && copyToClipboardFailed) {
      buttonText = t('failed-to-copy-report');
    } else {
      buttonText = t('copy-whatsapp-report');
    }

    if (reportedAsClosed) return null;

    if (!allowReportClose && !allowReportSubmit) {
      return (
        <div>
          <button
            id={stringToElementId(buttonText)}
            type="button"
            css={Styles.CTASubmit}
            {...assignEventListener(allowCopy ? copyReportToClipboard : () => {})}
          >
            {isCopied ? <span>{buttonText}&nbsp;&#10004;</span> : buttonText}
          </button>
          <p css={Styles.copied}>
            {isCopied ? t('open-whatsapp-and-paste-copied-report-to-send-to-group') : ''}
          </p>
        </div>
      );
    }
    return null;
  };

  const renderResendReportButton = () => {
    if (!allowReportClose && !allowReportSubmit && isiPadiPodOriPhoneDevice) {
      return (
        <div>
          <button
            id="resend-whatsapp-button"
            type="button"
            css={Styles.CTASubmit}
            onClick={resendWhatsappReport}
          >
            {t('resend-whatsapp-report')}
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <div css={Styles.pageWrapper}>
      <Layout showPaymentProfile>
        <div css={Styles.container}>
          <div css={Styles.topRow}>
            <VenueDropdown type={'dark'} />
            <div css={Styles.logoutWrapper}>
              <Link to={ROUTES.LOGOUT}>{t('log-out')}</Link>
            </div>
          </div>

          <div css={Styles.title}>
            {partner.currentVenueName} - {t('daily-report')}
          </div>
          <div css={Styles.datePickerWrapper(t('report-date'))}>
            <InputDatePicker
              locale={i18n?.language || DEFAULT_LANGUAGE}
              css={Styles.datePickerInput}
              name="reports-datepicker"
              selected={date.toDate()}
              onChangeRaw={(e) => {
                e.preventDefault();
              }}
              onChange={handleDateChange}
              maxDate={new Date()}
              dateFormat="dd MMMM yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>

          <InputAlt
            css={Styles.input}
            type="text"
            name="numBills"
            value={numBillsInput}
            placeholder={t('total-no-of-pos-bills')}
            invalid={!isNumBillsInputValid}
            onChange={handleBillsChange}
          />

          <InputAlt
            css={Styles.input}
            type="text"
            name="valBills"
            value={valBillsInput}
            placeholder={t('total-value-of-pos-bills')}
            invalid={!isValBillsInputValid}
            onChange={handleBillsAmountChange}
          />

          <div css={Styles.targetText}>
            {t('target-performance-is-at-least-n', { percentage: '70%' })}
          </div>
          <div css={Styles.prevReportsWrapper}>
            <div css={Styles.reportSectionTitle}>{t('previous-reports')}</div>
            {reports.map((r, i) => {
              const wrapperStyles =
                WORD_MAPPERS.STATUS[r.status] === WORD_MAPPERS.STATUS.NOT_REPORTED
                  ? Styles.reportEntryWrapperWarning
                  : Styles.reportEntryWrapperDefault;
              return (
                <div css={wrapperStyles} key={r.id || `0${i}`}>
                  <div css={Styles.reportDate}>{formatDateType(r.reportDate.format())}</div>
                  <div>&nbsp;:&nbsp;</div>
                  <div css={Styles.reportStatus}>{t(`reports.statuses.${r.status}`)}</div>
                </div>
              );
            })}
          </div>

          {renderReportButton()}
          {renderCloseButton()}

          {showConfirmationModal ? (
            <CloseConfirmationModal
              date={date.format()}
              confirmCloseReportHandler={() => confirmCloseReportHandler(date)}
              cancelCloseReportHandler={cancelCloseReportHandler}
            />
          ) : null}

          {!isiPadiPodOriPhoneDevice ? renderCopyToClipboardButton() : null}
          {renderResendReportButton()}
        </div>
        <div css={Styles.fixedToBottom}>
          <div css={Styles.bottomRow}>
            <HelpPopover colored />
            <LanguagePopover colored bordered />
            <div css={Styles.appVersion}>{appVersion}</div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

ReportsPage.propTypes = {};

function ReportsPageWrapper() {
  const props = useWrapper();
  return <ReportsPage {...props} />;
}

// Keep helmet outside wrapper hook
// helmet is slow and buggy when it re-renders after every state change
export default () => {
  const [t] = useTranslation();
  return (
    <>
      <AppHelmet
        title={`${t('loyalty')} | ${t('daily-reports')}`}
        description={`${t('loyalty')} | ${t('daily-reports')}`}
      />
      <ReportsPageWrapper />
    </>
  );
};
