import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalWrapper from 'components/ModalWrapper';
import { CoinsWithMinus } from 'components/Icons';
import AddNote from 'components/AddNote';
import Amount from 'components/Amount';
import { formatNumber } from 'util/number-helper';
import { assignEventListener } from 'util/event-helper';
import * as Styles from './DeductSuccessStep.styles';

const DeductSuccessStep = (props) => {
  const [t] = useTranslation();
  const {
    customer: { firstName, lastName, phone, balance, amountDeducted } = {},
    deductSuccessRedirectHandler,
    resetModalHandler,
    addNoteToTransactionHandler,
    locale,
  } = props;

  const [note, setTransactionNote] = React.useState('');
  const noteChangeHandler = (newNote) => {
    setTransactionNote(newNote);
  };

  const doneButtonHandler = () => {
    if (note.trim().length > 0) addNoteToTransactionHandler(note);
    deductSuccessRedirectHandler();
  };

  return (
    <div className="deduct-success-step">
      <ModalWrapper wrapperSelector={'.deduct-success-step'} resetModalHandler={resetModalHandler}>
        <div css={Styles.wrapper}>
          <div css={Styles.contentContainer}>
            <div css={Styles.creditDeductedIcon}>
              <CoinsWithMinus />
            </div>
            <div css={Styles.successText}>{t('credit-deducted-successfully')}</div>
            <div css={Styles.successSubText}>
              {t('credit-has-been-deducted-from-account', { firstName })}
            </div>

            <div css={Styles.customerDetails}>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('name')}</div>
                <div aria-label="Name" css={Styles.entryContent}>
                  {firstName} {lastName}
                </div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('phone-number')}</div>
                <div aria-label="Phone number" css={Styles.entryContent}>
                  {formatNumber(phone)}
                </div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('deducted')}</div>
                <div aria-label="Deducted amount" css={Styles.entryContent}>
                  <Amount currency={locale.currencyDenomination} amount={amountDeducted} />
                </div>
              </div>
              <div css={Styles.entryWrapper}>
                <div css={Styles.entryLabel}>{t('balance')}</div>
                <div aria-label="Balance" css={Styles.entryContent}>
                  <Amount currency={locale.currencyDenomination} amount={balance} />
                </div>
              </div>
            </div>

            <AddNote noteChangeHandler={noteChangeHandler} />

            <button
              id="done-button"
              css={Styles.CTA}
              type="button"
              {...assignEventListener(doneButtonHandler)}
            >
              {t('done')}
            </button>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

DeductSuccessStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    amountDeducted: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  deductSuccessRedirectHandler: PropTypes.func.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
  addNoteToTransactionHandler: PropTypes.func,
};

export default DeductSuccessStep;
