import { css } from '@emotion/react';

export const svg = css`
  margin-right: 0.75rem;

  .a,
  .c {
    fill: none;
  }
  .b {
    clip-path: url(#a);
  }
  .c {
    stroke: #d65d53;
    stroke-miterlimit: 10;
  }
  .d {
    fill: #d65d53;
  }
  .e {
    stroke: none;
  }
`;
