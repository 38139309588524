import React from 'react';
import PropTypes from 'prop-types';
import { assignEventListener } from 'util/event-helper';
import { MAX_AMOUNT } from 'constants/app-env';
import { stringToElementId } from 'util/helpers';
import Numpad from 'pages/CapturePage/components/Numpad';
import useNumpad from 'hooks/useNumpad';
import Amount from '../index';

const AmountPad = ({
  children,
  Styles,
  header = '',
  currencyDenomination,
  isAmountGreaterThanBalance = () => false,
  isAmountCTAEnabled = () => true,
  errMsg = '',
  amountHandler,
  amountButton = '',
}) => {
  const { num, addDigit, removeDigit } = useNumpad();
  const isGreaterThanBalance = isAmountGreaterThanBalance(num);
  const isCTAEnabled = isAmountCTAEnabled(num, isGreaterThanBalance);
  return (
    <div css={Styles.inputContainer}>
      {children}
      <div css={Styles.inputContent}>
        <div css={Styles.sectionDesc}>{header}</div>
        <div css={Styles.amount}>
          <Amount currency={currencyDenomination} amount={num} isAmountPad />
        </div>
        <div css={Styles.errMsg}>
          {isGreaterThanBalance ? <span>{errMsg}</span> : <div>&nbsp;</div>}
        </div>
        <Numpad
          addDigitHandler={num.length + 1 > MAX_AMOUNT ? () => {} : addDigit}
          removeDigitHandler={removeDigit}
          type="amount"
          isPopup
        />
      </div>
      <div css={Styles.inputFooter}>
        <button
          css={isCTAEnabled ? Styles.CTA : Styles.disabledCTA}
          id={stringToElementId(amountButton)}
          type="button"
          {...assignEventListener(isCTAEnabled ? () => amountHandler(num) : null)}
        >
          {amountButton}
        </button>
      </div>
    </div>
  );
};

AmountPad.propTypes = {
  children: PropTypes.node,
  Styles: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  currencyDenomination: PropTypes.string.isRequired,
  isAmountGreaterThanBalance: PropTypes.func,
  isAmountCTAEnabled: PropTypes.func,
  errMsg: PropTypes.string,
  amountHandler: PropTypes.func.isRequired,
  amountButton: PropTypes.string.isRequired,
};

export default AmountPad;
