import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { Trans, useTranslation } from 'react-i18next';
import useFormField from 'hooks/useFormField';
import ModalWrapper from 'components/ModalWrapper';
import Input from 'components/Input';
import Button from 'components/Button';
import Amount from 'components/Amount';
import { assignEventListener } from 'util/event-helper';
import * as Styles from './PasswordValidationStep.styles';

const PasswordValidationStep = (props) => {
  const [t] = useTranslation();
  const {
    customer: { firstName, amountAdded: amountToAdd } = {},
    submitPasswordHandler,
    resetModalHandler,
    isAuthorized = false,
    isLoading = false,
    locale,
  } = props;
  const [password, onChangePassword] = useFormField('');

  const renderAmount = () => (
    <Amount currency={locale.currencyDenomination} amount={Number(amountToAdd).toFixed(2)} />
  );

  return (
    <div className="add-password-validation-step">
      <ModalWrapper
        wrapperSelector={'.add-password-validation-step'}
        resetModalHandler={resetModalHandler}
      >
        <div css={Styles.container}>
          <div css={Styles.contentWrapper}>
            <div css={Styles.title}>
              <Trans
                i18nKey="adding-amount-credit-to"
                values={{
                  firstName,
                }}
                components={{
                  Amount: renderAmount(),
                }}
              />
            </div>
            <div css={Styles.helpText}>{t('enter-your-password-to-continue')}</div>
            <div css={Styles.passWrapper}>
              <Input
                type="password"
                name="password"
                label={t('password')}
                onChange={onChangePassword}
                autoFocus
              />
              {!isAuthorized ? (
                <div css={Styles.errorMsg}>
                  <span>{t('authentication-failed')}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div css={Styles.CTAWrapper}>
            <Button
              css={Styles.CTA}
              type="primary"
              {...assignEventListener(() => submitPasswordHandler(password))}
            >
              {t('add-credit')}
            </Button>
          </div>
          <div css={isLoading ? Styles.loadingOverlay : Styles.loadingOverlayHidden}>
            <ReactLoading type={'spin'} color={'#169A89'} width={50} />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

PasswordValidationStep.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    amountAdded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  locale: PropTypes.shape({
    currencyDenomination: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  submitPasswordHandler: PropTypes.func.isRequired,
  resetModalHandler: PropTypes.func.isRequired,
};

export default PasswordValidationStep;
