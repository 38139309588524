import { css } from '@emotion/react';

export const filler = css`
  background: #1da598;
  height: 100%;
  border: 2px solid var(--color-primary);
  margin-top: -2px;
  transition: width 0.2s ease-in;
`;

export const progressBar = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2px;
  background: #939393;
  border: 1px solid #939393;
`;

export const dot = css`
position: relative;
  height: 10px;
  width: 10px;
  background-color: var(--color-primary);
  border-radius: 50%;
  margin: -5px -8px;
}`;
