import { formatAmountWithCommas } from 'util/number-helper';
import { DEFAULT_LANGUAGE } from 'constants/app-env';
import { getCurrencyDenominationByCountryISO } from 'util/locale-helper';
import PropTypes from 'prop-types';

const Amount = ({ currency, amount = 0, isAmountPad = false }) => {
  // TODO: move the below commented code once we have venue languages globaly saved and use  localeCountryCode || countryIsoCode
  // const localeCountryCode = languages?.find((l) => l?.language === i18n?.language)?.countryCode;
  const [countryIsoCode, currencyCode] = (String(currency) || 'MY,MYR').split(',');
  try {
    if (isAmountPad) {
      return `${getCurrencyDenominationByCountryISO(countryIsoCode)} ${formatAmountWithCommas(
        amount
      )}`;
    }
    return new Intl.NumberFormat(`${DEFAULT_LANGUAGE}-${countryIsoCode}`, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'symbol',
    }).format(amount);
  } catch (error) {
    return `${getCurrencyDenominationByCountryISO(countryIsoCode)} ${formatAmountWithCommas(
      amount
    )}`;
  }
};

Amount.propTypes = {
  isAmountPad: PropTypes.bool,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Amount;
