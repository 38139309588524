import moment from 'moment-timezone';

export const getMomentDate = (date, dateInFormat = 'YYYY-MM-DD') => moment(date, dateInFormat);

const getDisplayDateAndTimeFromServerTime = (serverTimeStr) => {
  const parsedDate = moment(serverTimeStr);
  const formattedDate = parsedDate.format('ll');
  const formattedTime = parsedDate.format('LT');
  return {
    formattedDate,
    formattedTime,
  };
};

export const getDateFormatter =
  (format = 'YYYY/MM/DD') =>
  (date) =>
    moment(date).format(format);

export const getDisplayDateFromServerTime = (serverTimeStr) => {
  const { formattedDate } = getDisplayDateAndTimeFromServerTime(serverTimeStr);
  return formattedDate;
};

export const getDisplayTimeFromServerTime = (serverTimeStr) => {
  const { formattedTime } = getDisplayDateAndTimeFromServerTime(serverTimeStr);
  return formattedTime;
};

export const formatDateWithoutYearForWhatsappText = (date) =>
  moment(date)
    .format('L')
    // eslint-disable-next-line
    .replace(new RegExp('[^.]?' + moment(date).format('YYYY') + '.?'), '');

export const getLastNDaysInDateType = (N = 4) =>
  Array.from(Array(N).keys()).map((n) => moment().subtract(n + 1, 'day'));

// subbtracting 6 hours bcox we have 6am - 6am day and backend always adds 6 hours to the expiryDate.
export const getNumberOfDaysFromTodayFromServerDate = (serverDateStr) =>
  moment(serverDateStr).subtract(6, 'hours').diff(moment(), 'days');
