import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PaymentProfile from 'components/PaymentProfile';
import appBg from 'images/background.jpg';
import appThumb from 'images/background-thumbnail.jpg';
import * as Styles from './Layout.styles';

const Layout = ({
  children,
  showBackground = false,
  hasDarkBackground = false,
  alignItemsCenter = false,
  showPaymentProfile = false,
  showBackButton = false,
  showFadeAnimation = true,
}) => {
  const placeholderElem = React.useRef(null);
  const smallElem = React.useRef(null);

  useEffect(() => {
    if (showBackground) {
      const placeholder = placeholderElem.current;
      const small = smallElem.current;

      // 1: load small image and show it
      const img = new Image();
      img.src = small.src;
      img.onload = () => {
        small.classList.add('loaded');
      };

      // 2: load large image
      const imgLarge = new Image();
      imgLarge.src = placeholder.dataset.large;
      imgLarge.onload = () => {
        imgLarge.classList.add('loaded');
        small.classList.remove('loaded');
      };
      placeholder.appendChild(imgLarge);
      placeholder.classList.add('loaded');
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div css={Styles.layoutWrapper}>
      <div
        className="placeholder"
        css={[
          Styles.imagePlaceholderWrapper,
          hasDarkBackground ? Styles.imagePlaceholderWrapperDark : '',
          showFadeAnimation ? Styles.imageFadeAnimation : '',
        ]}
        ref={placeholderElem}
        data-large={appBg}
      >
        <img
          alt="placeholder background"
          src={appThumb}
          css={showFadeAnimation ? Styles.thumbnailImage : ''}
          ref={smallElem}
          className="img-small"
        />
      </div>
      <div css={alignItemsCenter ? Styles.contentWrapperCentered : Styles.contentWrapper}>
        <div css={Styles.contentContainer}>
          {children}
          {showPaymentProfile ? <PaymentProfile showBackButton={showBackButton} /> : null}
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showBackground: PropTypes.bool,
  hasDarkBackground: PropTypes.bool,
  alignItemsCenter: PropTypes.bool,
  showPaymentProfile: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showFadeAnimation: PropTypes.bool,
};

export default Layout;
