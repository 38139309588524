import React from 'react';

const RemoveDigit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="66" height="42" viewBox="0 0 66 42">
    <g transform="translate(0 0)" className="layer-1" fill="none" strokeMiterlimit="10">
      <path
        d="M 61 41.5 L 21.21895027160645 41.5 C 19.94980049133301 41.5 18.73353958129883 40.96023941040039 17.88204002380371 40.01911163330078 L 0.6742812991142273 21 L 17.88204002380371 1.980890035629272 C 18.73353958129883 1.039759993553162 19.94980049133301 0.5 21.21895027160645 0.5 L 61 0.5 C 63.4813117980957 0.5 65.5 2.51869010925293 65.5 5 L 65.5 37 C 65.5 39.4813117980957 63.4813117980957 41.5 61 41.5 Z"
        stroke="none"
        className="layer-1-1"
      />
      <path
        d="M 21.21895599365234 1 C 20.09081268310547 1 19.00968933105469 1.479789733886719 18.25281143188477 2.31634521484375 L 1.348548889160156 21 L 18.25282287597656 39.68365478515625 C 19.00968933105469 40.52021026611328 20.09081268310547 41 21.21895599365234 41 L 61 41 C 63.20561218261719 41 65 39.20561218261719 65 37 L 65 5 C 65 2.794387817382813 63.20561218261719 1 61 1 L 21.21895599365234 1 M 21.21895599365234 0 L 61 0 C 63.76142120361328 0 66 2.238578796386719 66 5 L 66 37 C 66 39.76142120361328 63.76142120361328 42 61 42 L 21.21895599365234 42 C 19.80608749389648 42 18.45917892456055 41.40225601196289 17.51127624511719 40.35456848144531 L 0 21 L 17.51127624511719 1.645431518554688 C 18.45917892456055 0.5977439880371094 19.80608749389648 0 21.21895599365234 0 Z"
        stroke="none"
        fill="#07a99b"
        className="layer-1-2"
      />
    </g>
    <path
      d="M9.085,8l6.69-6.69A.767.767,0,0,0,14.69.225L8,6.915,1.31.226A.767.767,0,0,0,.225,1.311L6.915,8,.226,14.69A.767.767,0,1,0,1.31,15.775L8,9.085l6.69,6.69a.767.767,0,0,0,1.084-1.085Z"
      transform="translate(32 12)"
      fill="#07a99b"
      stroke="#07a99b"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      className="layer-2"
    />
  </svg>
);

export default RemoveDigit;
