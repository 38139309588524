import { css } from '@emotion/react';

export const numPad = css`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  padding-top: 0rem;
  margin-bottom: 1rem;

  -ms-touch-action: manipulation;
  touch-action: manipulation;
`;

export const numPadPopup = css`
  padding: 0;
`;

export const numRow = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const baseCol = css`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 7rem;
  height: 6.2rem;
`;

export const numCol = css`
  ${baseCol}
`;

export const numColPopup = css`
  ${baseCol}
  width: 6rem;
  height: 5rem;
`;

export const num = css`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--color-primary);
  border-radius: 50%;

  font-weight: bold;
  font-size: 1.75rem;

  color: var(--color-primary);

  width: 4.5rem;
  height: 4.5rem;

  cursor: pointer;

  -webkit-touch-callout: none;
  user-select: none; /* Disable selection/copy in UIWebView */

  &:active {
    background-color: var(--color-primary);
    color: #fff;
  }
`;

export const numPopup = css`
  width: 4rem;
  height: 4rem;
`;

export const numDummy = css`
  ${numPopup};
  border: none;
`;

export const specialChar = css`
  padding-bottom: 5px;
`;

export const deleteBtnWrapper = css`
  border: none;
  padding-bottom: 0;
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  align-items: center;

  svg {
    width: 100% !important;
    height: auto !important;
    cursor: pointer;

    .layer-1-2 {
      fill: var(--color-primary);
    }
    .layer-2 {
      fill: var(--color-primary);
      stroke: var(--color-primary);
    }
  }

  &:active {
    background-color: transparent;
    color: var(--color-primary);

    svg {
      -webkit-touch-callout: none;
      user-select: none; /* Disable selection/copy in UIWebView */

      .layer-1,
      .layer-1-2 {
        fill: var(--color-primary);
      }

      .layer-2 {
        stroke: white;
      }
    }
  }
`;

export const deleteBtnWrapperPopup = css`
  border: none;
  width: 4rem;
  height: 4rem;
`;
