import detectTouchEvents from 'detect-touch-events';

export const assignEventListener = (callback) => {
  const eventListener = {};
  if (detectTouchEvents.hasSupport && detectTouchEvents.browserSupportsApi) {
    eventListener.onTouchStart = callback;
  } else {
    eventListener.onClick = callback;
  }
  return eventListener;
};
