import { css } from '@emotion/react';

export const wrapper = css`
  position: relative;
`;

export const defaultTriggerWrapperCss = css`
  position: relative;
`;

export const content = css`
  z-index: 100;
  position: absolute;
  left: 110%;
  background: #fff;
  box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.21);
  min-width: 400px;
  padding: 1rem 1.25rem;
  top: -37px;
  border-radius: 3px;
  min-height: 80px;
  font-size: 16px;

  &[data-position='bottom'] {
    left: auto;
    right: 0;
    min-width: 200px;
    top: 130%;
    padding: 0.5rem;
  }
`;

export const arrowLeft = css`
  position: absolute;
  right: -2px;
  display: block;
  z-index: 200;

  &:before,
  &:after {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
  }

  &:before {
    left: -10px;
    border-right-color: #ddd;
    border-width: 10px;
    border-left-width: 0;
  }

  &:after {
    left: -9px;
    border-right-color: #fff;
    border-width: 10px;
    border-left-width: 0;
  }
`;

export const arrowTop = css``;

export const arrowRight = css``;

export const arrowBottom = css`
  position: absolute;
  display: block;
  z-index: 200;
  left: 50%;

  &:before,
  &:after {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
  }

  &:before {
    top: 0;
    border-bottom-color: #ddd;
    border-width: 10px;
    border-top-width: 0;
  }

  &:after {
    top: 1px;
    border-bottom-color: #fff;
    border-width: 10px;
    border-top-width: 0;
  }
`;
