import { css } from '@emotion/react';

const leadingTextSize = css`
  font-size: 1.35rem;
  font-weight: 500;
`;

export const pageHeader = css`
  min-height: 90px;
  max-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-white);
  font-size: 1.6rem;
  background-image: var(--bg-image);
  font-weight: 500;
`;

export const phoneNumber = css`
  color: var(--text-primary);
  ${leadingTextSize}
  margin: 1rem 0;
`;

export const accountBalanceWrapper = css`
  margin-bottom: 1rem;
`;

export const accountBalanceLabel = css`
  color: var(--color-darkGrey);
  font-size: 1rem;
  margin-bottom: -0.25rem;
`;

export const accountBalance = css`
  color: var(--color-primary);
  font-size: 1.6rem;
  font-weight: 500;
`;

export const accountDetailsWrapper = css``;

export const accountDetailsRow = css`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: 1rem;
`;

export const accountDetailsColumn = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 50px;

  &.text-right {
    align-items: flex-end;
  }

  &.gender {
    text-transform: capitalize;
  }

  span:first-of-type {
    color: var(--color-darkGrey);
    font-size: 1rem;
    margin-bottom: -0.25rem;
  }

  span:last-of-type {
    color: var(--text-primary);
    ${leadingTextSize}
  }
`;

export const historyButton = css`
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid var(--text-primary);
  background: transparent;
  color: var(--text-primary);
  font-weight: 500;
  padding: 0.75rem 1rem;
  display: inline-block;
  // max-width: 200px;
  margin: 0 auto;
  margin: 1rem;
  transition: all 0.3s;

  &:hover,
  &:focus {
    color: white;
    background: var(--text-primary);
  }
`;

export const availableRewards = css`
  margin: 1rem 0;
`;

export const availableRewardsLabel = css`
  color: var(--text-primary);
  ${leadingTextSize}
`;

export const currentTier = css`
  color: var(--color-darkGrey);
  .name {
    color: var(--text-primary);
    ${leadingTextSize}
  }

  div:first-of-type {
    font-size: 1.1rem;
  }

  div:last-of-type {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

export const tierProgress = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 1rem;

  .label {
    color: var(--color-darkGrey);
    font-size: 1.1rem;
  }

  .progress {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .icon {
      background: var(--color-primary) 0% 0% no-repeat;
      opacity: 1;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: auto;
      padding: 3px;
    }
  }

  .progressText {
    color: var(--color-darkGrey);
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

export const noAvailableCampaigns = css`
  color: var(--color-darkGrey);
  margin: 1rem 0;
`;

export const campaignsWrapper = css`
  padding: 0 1rem;
  margin: 1rem 0;
`;

export const campaign = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  border-radius: 8px;
  box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.21);
  padding: 0.75rem 0.8rem;
  cursor: pointer;
`;

export const campaignDetailsWrapper = css`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const campaignName = css`
  color: var(--text-primary);
  ${leadingTextSize}
`;

export const campaignExpiryDate = css`
  color: var(--color-darkGrey);
  font-size: 1rem;
`;

export const campaignDetailsToggleWrapper = css``;

export const campaignDetailsToggle = css`
  cursor: pointer;
  background: var(--color-primary);
  color: var(--text-white);
  padding: 0.35rem 1rem;
  border-radius: 5px;
  display: flex;
`;

export const smsSubscriptionToggleWrapper = css`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

export const smsSubscriptionToggleLabel = css`
  color: var(--text-primary);
  ${leadingTextSize}
`;
