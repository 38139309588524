import { css } from '@emotion/react';

export const inputWrapper = css`
  display: flex;
  flex-direction: column;
`;

export const label = css`
  text-align: left;
  color: var(--text-grey);
`;

export const labelRequired = css`
  ${label}
`;

export const labelOptional = css`
  ${label}
`;

export const base = css`
  border: 0;
  appearance: none;
  margin: 0 0 1rem;
  background: none;
  border-bottom: 1px solid var(--text-grey);

  &:focus {
    outline: none;
  }
`;

export const text = css`
  ${base}
`;

export const password = css`
  ${base}
`;

export const errorMsg = css`
  color: var(--color-error);
  margin-top: -10px;
  font-size: 0.9rem;
`;
