import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useInterval from 'hooks/useInterval';
import { Resend } from 'components/Icons';
import InputAlt from 'components/InputAlt';
import GuestSignUpHeader from 'components/GuestSignUpHeader';
import { cleanPhoneNumber, formatNumber } from 'util/number-helper';
import * as Styles from './SendProfileLinkStep.styles';

const TIME_IN_SECONDS = 60 * 5 - 1; // 5 minutes equivalent in milleseconds - 1 second delay in interval
// const TIME_IN_SECONDS = 5; // 5 minutes equivalent in milleseconds - 1 second delay in interval
const TIME_IN_MINUTES = '05:00'; // 5 minutes equivalent in milleseconds

const SendProfileLinkStep = (props) => {
  const {
    hasErrorMessage = false,
    errorMessage = '',
    phoneNumber = '',
    venueSetSettings: { venueName },
    hasSentProfileLink = false,
    isSendProfileButtonEnabled = false,
    sendProfileLinkHandler = () => {},
    setResendProfileButtonFlag = () => {},
    goBackToPhoneNumberStep = () => {},
  } = props;
  const [t] = useTranslation();
  const [timer, setTimer] = React.useState(TIME_IN_SECONDS);
  const [countdown, setCountdown] = React.useState(TIME_IN_MINUTES);

  useInterval(() => {
    let minutes = parseInt(timer / 60, 10);
    let seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    setCountdown(`${minutes}:${seconds}`);
    setTimer(timer - 1);

    if (timer < 0) {
      setResendProfileButtonFlag(true);
      // reset countdown timer
      setCountdown(TIME_IN_MINUTES);
      setTimer(TIME_IN_SECONDS);
    }
  }, 1000);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (isSendProfileButtonEnabled) sendProfileLinkHandler();
  };

  const contentIfLinkHasBeenSent = () => (
    <>
      <h4 css={Styles.pageHeadingText}>{t(`we-sent-you-a-link-by-text-message`)}</h4>
      <h4 css={Styles.pageSubheadingText}>
        {t(
          'check-your-sms-messages-all-you-have-to-do-is-click-the-link-and-you-get-an-overview-of-your-loyalty-account'
        )}
      </h4>
    </>
  );

  const contentIfLinkIsNotYetSent = () => (
    <>
      <h4 css={Styles.pageHeadingText}>
        {t(`you-ve-already-signed-up-for-venues-loyalty-program-before`, { venueName })}
      </h4>
      <h4 css={Styles.pageSubheadingText}>
        {t(`click-the-button-below-and-we-send-you-a-unique link-via-sms-where-you-can-check`)}
      </h4>
    </>
  );

  const countDownTimer = () => {
    if (hasSentProfileLink) {
      return (
        <div css={Styles.timer}>
          <Resend />
          {countdown}
        </div>
      );
    }
    return null;
  };

  const cleanNumber = cleanPhoneNumber(phoneNumber);
  const formattedNumber = formatNumber(cleanNumber) || '';

  const renderErrorMessages = () =>
    hasErrorMessage ? <div css={Styles.errorMessageWrapper}>{errorMessage}</div> : null;

  return (
    <>
      <GuestSignUpHeader
        pageHeaderStyles={Styles.pageHeader}
        hasBackButton
        venueName={venueName}
        headerBackButtonStyles={Styles.headerBackButton}
        goBackHandler={goBackToPhoneNumberStep}
      />
      {hasSentProfileLink ? contentIfLinkHasBeenSent() : contentIfLinkIsNotYetSent()}
      {renderErrorMessages()}
      <div css={Styles.formWrapper}>
        <form onSubmit={handleFormSubmit}>
          <div css={Styles.fieldWrapper}>
            <span className="field-label">{t('your-phone-number')}</span>
            <InputAlt
              type="text"
              name="phone"
              label={t('enter-phone-number')}
              invalid={false}
              errMsg={t('please-enter-a-valid-phone-number')}
              value={formattedNumber}
              onChange={() => {}}
              showPlusSign
              readOnly
            />
          </div>

          <button
            type="submit"
            css={isSendProfileButtonEnabled ? Styles.submitButton : Styles.disabledSubmitButton}
          >
            {t('send-me-my-link')}
          </button>
          {countDownTimer()}
        </form>
      </div>
    </>
  );
};

SendProfileLinkStep.propTypes = {
  hasErrorMessage: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  venueSetSettings: PropTypes.object.isRequired,
  hasSentProfileLink: PropTypes.bool.isRequired,
  isSendProfileButtonEnabled: PropTypes.bool.isRequired,
  sendProfileLinkHandler: PropTypes.func.isRequired,
  setResendProfileButtonFlag: PropTypes.func.isRequired,
  goBackToPhoneNumberStep: PropTypes.func.isRequired,
};

export default SendProfileLinkStep;
