import React from 'react';
import { Global, css } from '@emotion/react';

const InitTheme = () => (
  <Global
    styles={(theme) => css`
      :root {
        --color-primary: ${theme.colors.primary};
        --color-alternate: ${theme.colors.alternate};
        --color-error: ${theme.colors.error};
        --color-darkGrey: ${theme.colors.darkGrey};
        --color-grey: ${theme.colors.grey};
        --color-bg: ${theme.colors.bg};
        --color-bgGrey: ${theme.colors.bgGrey};
        --color-popupBgGrey: ${theme.colors.popupBgGrey};

        --text-primary: ${theme.text.primary};
        --text-alternate: ${theme.text.alternate};
        --text-grey: ${theme.text.grey};
        --text-white: #ffffff;

        --border-primary: ${theme.border.pink};
        --border-alternate: ${theme.border.alternate};

        --dimensions-headerHeight: ${theme.dimensions.headerHeight};
        --dimensions-footerHeight: ${theme.dimensions.footerHeight};
      }
    `}
  />
);

export default InitTheme;
